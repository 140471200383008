<template>
  <UserManagement v-if="hasPermission('application:manage')" />
  <UserProfile v-else />
</template>

<script lang="ts">
import {Component, Vue} from "vue-property-decorator";
import UserManagement from "@/views/UserManagement.vue";
import UserProfile from "@/views/UserProfile.vue";
import {Getter} from "vuex-class";
@Component({
  components: {UserProfile, UserManagement}
})
export default class Users extends Vue {
  @Getter private hasPermission!: any;
}
</script>

<style scoped>

</style>