<template>
  <div class="fill-height overflow-x-hidden" :class="$vuetify.breakpoint.smAndDown ? 'background-mob' : 'background-img'">
    <v-row class="relative z99 fill-height">
      <v-col cols="12" class="absolute centered">
        <v-row justify="start" class="relative">
          <v-col cols="auto" class="absolute">
            <v-img :src="landscapeLogoSrc" contain :max-width="$vuetify.breakpoint.smAndDown ? 200 : 350" class="py-2 mx-2 float-right"/>
          </v-col>
        </v-row>
        <v-stepper v-model="activeStep" flat class="fill-height" id="login-stepper" @change="error = ''">
          <v-stepper-items class="fill-height">
            <v-stepper-content :step="LoginSteps.EmailPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <EmailPrompt @check:email="username = $event; checkUser(username);" :loading="loading" :error="error" @clear:error="error = ''" :query-username="username"/>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="LoginSteps.PasswordPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <PasswordPrompt
                      :activated="activated"
                      :error="error"
                      :query-code="queryCode"
                      @clear:error="error = ''"
                      @go-to:email="activeStep = LoginSteps.EmailPrompt"
                      @check:password="password = $event; login()"
                      @go-to:forgot-password="activeStep = LoginSteps.ForgotPassword"
                      @go-to:resend-registration="activeStep = LoginSteps.ResendRegistration"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="LoginSteps.NewPasswordPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <PasswordPrompt
                      :error="error"
                      @clear:error="error = ''"
                      @go-to:email="activeStep = LoginSteps.EmailPrompt"
                      @check:password="password = $event; login()"
                      :loading="loading"
                      :activated="activated"
                      :is-new="isNew"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="LoginSteps.ForgotPassword" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ResendPrompt :username="username" @go-to:email="activeStep = LoginSteps.EmailPrompt"/>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="LoginSteps.ResendRegistration" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ResendPrompt :username="username" :activating="true" @go-to:email="activeStep = LoginSteps.EmailPrompt"/>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="LoginSteps.Loading" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <v-row justify="center" align="center">
                    <v-col cols="12" md="5" class="pa-4">
                      <v-card color="tertiary" class="px-4 py-6">
                        <v-row no-gutters align="center" justify="center">
                          <v-col cols="12" class="text-center">
                            <span class="white--text text-h5 font-weight-bold">Logging you in..</span>
                          </v-col>
                          <v-col cols="auto" class="mt-6">
                            <v-progress-circular indeterminate color="white"></v-progress-circular>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action } from 'vuex-class';
import EmailPrompt from "@/views/login/EmailPrompt.vue";
import PasswordPrompt from "@/views/login/PasswordPrompt.vue";
import LoginService from "@/services/login.service";
import ResendPrompt from "@/views/login/ResendPrompt.vue";
import { LoginResponse } from "../../types";

enum LoginSteps {
  EmailPrompt,
  PasswordPrompt,
  NewPasswordPrompt,
  ForgotPassword,
  ResendRegistration,
  Loading
}

@Component({
  components: {ResendPrompt, PasswordPrompt, EmailPrompt}
})
export default class LoginFlow extends Vue {
  private LoginSteps = LoginSteps;
  private activeStep = LoginSteps.EmailPrompt;
  private loginData: LoginResponse = {};
  private loading = false;
  private activated = false;
  private activating = false;
  private challenged = false;
  private isNew = false;
  private username = '';
  private password = '';
  private queryCode = '';
  private error = '';

  @Action private userLogin!: (jwt: string) => Promise<void>;

  private mounted() {
    if (this.$route.query.username) {
      this.username = this.$route.query.username.toString();
      if (this.$route.query.code) {
        this.queryCode = this.$route.query.code.toString();
      }

    }
  }

  private async checkUser() {
    this.loading = true;
    this.challenged = false;
    try {
      const userStatus = await LoginService.getUserStatus(this.username);
      switch (userStatus.status) {
        case 'FORCE_CHANGE_PASSWORD':
          this.activated = false;
          this.isNew = true;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
        case 'UNCONFIRMED':
          this.activated = false;
          this.isNew = false;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
        case 'OK':
        default:
          this.activated = true;
          this.isNew = false;
          this.activeStep = LoginSteps.PasswordPrompt;
          break;
      }
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

  private async login() {
    this.loading = true
    const oldStep: LoginSteps = this.activeStep;
    this.activeStep = LoginSteps.Loading;
    try {
      if (this.challenged) {
        await this.respondToAuthChallenge(this.loginData);
        return;
      }
      const loginResponse = this.activated ? 
        await LoginService.login({username: this.username, password: this.password}) :
        await LoginService.register({username: this.username, code: this.password}); 
      if (loginResponse.challenge === 'NEW_PASSWORD_REQUIRED') {
        this.challenged = true;
        this.activated = false;
        this.isNew = true;
        this.loginData = loginResponse;
        this.activeStep = LoginSteps.NewPasswordPrompt;
        return;
      }
      await this.loginSuccess(loginResponse.token!);
    } catch (err) {
      this.error = (err as Error).message;
      this.activeStep = oldStep;
    } finally {
      this.loading = false;
    }
  }

  private async respondToAuthChallenge(loginResponse: LoginResponse): Promise<void> {
    const oldStep: LoginSteps = this.activeStep;
    this.activeStep = LoginSteps.Loading;
    try {
      const response = await LoginService.respond({
        username: this.username,
        newPassword: this.password,
        challenge: 'NEW_PASSWORD_REQUIRED',
        session: loginResponse.session!,
        requiredAttributes: loginResponse.requiredAttributes || []
      });
      if (response.challenge) {
        throw new Error(`Unexpected challenge response encountered ${response.challenge}`);
      }
      await this.loginSuccess(response.token!);
    } catch (err) {
      this.error = (err as Error).message;
      this.challenged = false;
      this.activeStep = LoginSteps.PasswordPrompt;
    }
  }

  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }

  private async loginSuccess(JWT: string) {
    await this.userLogin(JWT);
  }
}
</script>

<style>
  #login-stepper {
    background-color: transparent;
  }
  #login-stepper .v-stepper__wrapper {
    height: 100% !important;
  }
  .centered {
    height: 100%;
  }
  .background-img {
    background-color: #fafafb;
    background-image: url("~@/assets/login_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .background-mob {
    background-color: #fafafb;
    background-image: url("~@/assets/login_bg_mobile.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .z99 {
    z-index: 99;
  }
  .center {
    transform: translateX(calc(100vw - 75%));
  }

</style>