<template>
  <div>
    <v-card color="transparent" flat>
      <v-row>
        <v-col cols="12">

          <v-tabs
              v-model="tab"
              background-color="transparent"
              color="primary"
              grow
              @change="$emit('set:range', { range: range, span: timespan[tab]}); rangePosition = 0;"
          >
            <v-tab
                v-for="item in ['day', 'week', 'month']"
                :key="item"
            >
              <span :class="$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-1'" class="text-capitalize">{{ item }}</span>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row class="mt-4" justify="center" align="center">
        <v-col cols="1" class="d-flex justify-center">
          <v-btn icon @click="rangePosition = rangePosition - 1;" :small="$vuetify.breakpoint.smAndDown">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto" class="text-center">
          <span class="font-weight-bold" :class="$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-1'">
            {{ range.from.toLocaleDateString('en-GB') }} - {{ range.to.toLocaleDateString('en-GB') }}
          </span>
        </v-col>
        <v-col cols="1" class="d-flex justify-center">
          <v-btn icon @click="rangePosition = rangePosition + 1" :disabled="disableForward" :small="$vuetify.breakpoint.smAndDown">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DateRangeSelector extends Vue {
  private tab = 1;
  private rangePosition = 0;
  private timespan: {[key: number]: string} = {
    0: 'day',
    1: 'week',
    2: 'month'
  }

  private modifyDate(rangeType: number, range: number, date = new Date()) {
    switch (rangeType) {
      case 0:
        return new Date(date.setDate(date.getDate() + range));
      case 1:
        return new Date(date.setDate(date.getDate() + (range * 7)));
      case 2:
        return new Date(date.setMonth(date.getMonth() + range));
      default:
        return new Date();
    }
  }

  private get disableForward() {
    if (this.tab !== 2) {
      return new Date(this.range.to).getDate() === new Date().getDate();
    } else {
      return new Date(this.range.to).getMonth() === new Date().getMonth() && new Date(this.range.to).getFullYear() === new Date().getFullYear();
    }
  }

  private get range() {
    const dayAndMonth = 1;
    const week = 7;

    if (this.rangePosition === 0) {
      const fromDay = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - dayAndMonth))
      const fromWeek = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - week))
      const fromMonth = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setMonth(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getMonth() - dayAndMonth))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    } else if (this.rangePosition < 0) {
      const fromDay = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - dayAndMonth))
      const fromWeek = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setDate(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getDate() - week))
      const fromMonth = new Date(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).setMonth(new Date(this.modifyDate(this.tab, this.rangePosition, new Date())).getMonth() - dayAndMonth))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    } else {
      const fromDay = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setDate(new Date().getDate() - dayAndMonth)))
      const fromWeek = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setDate(new Date().getDate() - week)))
      const fromMonth = this.modifyDate(this.tab, this.rangePosition, new Date(new Date().setMonth(new Date().getMonth() - dayAndMonth)))
      const range = {
        from: this.tab === 1 ? fromWeek : this.tab === 2 ? fromMonth : fromDay,
        to: this.modifyDate(this.tab, this.rangePosition, new Date())
      }
      this.$emit('change:range', { range: range, span: this.timespan[this.tab]});
      return range;
    }
  }
}
</script>

<style>
.v-tab {
  min-width: 50px  !important;
}
.v-tab.v-tab, .v-tab:before {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
}
</style>