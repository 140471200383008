<template>
  <div class="overflow-x-hidden">
    <div>
      <v-stepper v-model="activeStep" flat class="no-transition background">
        <v-stepper-items>
          <v-stepper-content :step="SiteWizardSteps.GetStarted">
            <v-row no-gutters class="content-container" align="center">
              <v-col cols="12" class="wrapper flex-grow-1 d-flex align-center justify-center">
                <v-row class="my-16" justify="center">
                  <v-col cols="12">
                    <v-img :src="require('@/assets/site_wizard.png')" contain max-height="300" />
                  </v-col>
                  <v-col cols="8" class="mt-10 d-flex justify-center">
                    <v-sheet max-width="300" color="transparent">
                      <div class="font-weight-bold text-h5 text-center">Let's get started by finding your site</div>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row no-gutters class="mb-16">
                  <v-col cols="12" class="d-flex justify-center">
                    <ActionButton width="220" height="50" @click="stepForward" text="Get started" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-content :step="SiteWizardSteps.SiteSelection" class="pb-0 ">
            <v-row no-gutters class="content-container" align="center">
              <v-col cols="12" class="wrapper">
                <v-row no-gutters justify="center">
                  <v-col cols="12" md="6" v-if="!showSiteCreation">
                    <v-row justify="center" no-gutters align="center">
                      <v-col cols="auto">
                        <p class="font-weight-bold text-h5 text-center">Let's find your site</p>
                        <p class="font-weight-regular text-body-1 text-center">Select a site, or create a new one.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" v-else>
                    <v-row justify="center" no-gutters align="center">
                      <v-col cols="auto">
                        <p class="font-weight-bold text-h5 text-center">Create a site</p>
                        <p class="font-weight-regular text-body-1 text-center">Search for your site, or select a location on the map.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" class="px-6" v-if="!showSiteCreation && sites && sites.length">
                  <v-col cols="12" md="6">
                    <v-row justify="center">
                      <v-col cols="12" sm="10" class="py-0">
                        <v-radio-group v-model="selectedSite" class="my-0">
                          <v-card v-for="s of sites" :key="s.id" outlined class="py-2 px-2 my-2" @click="selectSite(s)" color="tertiary">
                            <v-row dense justify="center" align="center">
                              <v-col cols="2" class="d-flex align-center justify-start">
                                <v-radio
                                    color="white"
                                    class="pa-2"
                                    :value="s.id"
                                    :ripple="false"
                                ></v-radio>
                              </v-col>
                              <v-col cols="8">
                                <span class="font-weight-bold"> {{ s.name }}</span>
                              </v-col>
                              <v-col cols="auto">
                                <v-icon>mdi-map-marker-outline</v-icon>
                              </v-col>
                            </v-row>
                          </v-card>

                          <v-card outlined class="py-2 px-2 my-2" @click="selectSite(null)" color="tertiary">
                            <v-row dense justify="center" align="center">
                              <v-col cols="2" class="d-flex align-center justify-start">
                                <v-radio
                                    value="new"
                                    class="pa-2"
                                ></v-radio>
                              </v-col>
                              <v-col cols="8">
                                <span class="font-weight-bold"> Create a new site </span>
                              </v-col>
                              <v-col cols="auto">
                                <v-sheet width="24" height="24" color="transparent"></v-sheet>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="showSiteCreation || !sites" align="end" class="mb-8">
                  <v-col cols="12">
                    <v-row justify="center">
                      <v-col cols="12" sm="10" class="py-0">
                        <google-map :site="site" @select:site="setSiteDetails($event)"></google-map>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row no-gutters class="mb-16" justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton v-if="activeStep === SiteWizardSteps.SiteSelection && showSiteCreation" :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" icon="mdi-arrow-left" @click="showSiteCreation = false; selectedSite = null; site = { address: '' };" text="Go back"></ActionButton>
                    <ActionButton v-else :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="showSiteCreation = false; selectedSite = null; site = { address: '' }; stepBack();" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="checkSiteExists" :disabled="!site || (!(site.coords && !!site.coords.length) && !site.address)" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="SiteWizardSteps.ConfirmSite">
            <v-row no-gutters class="content-container" align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row>
                  <v-col cols="12">
                    <p class="font-weight-bold text-h5 text-center">Is this your address?</p>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" md="10">
                    <v-card flat class="pa-4" color="tertiary">
                      <v-icon color="secondary" class="float-right" large>mdi-map-marker</v-icon>
                      <p class="text-subtitle-1 font-weight-regular mb-0" v-for="part of siteDetails" :key="part">{{ part }}</p>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row no-gutters class="mb-16" justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepBack" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepForward" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


          </v-stepper-content>

          <v-stepper-content :step="SiteWizardSteps.NameSite" class="">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="font-weight-bold text-h5 text-center">Give your site a name</p>
                    <p class="font-weight-regular text-body-1 text-center mx-4">This could be the building, premises or business name.</p>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" md="10">
                    <TextField :editable="false" label="Site name" @update:value="setSiteName" :value="site.name"></TextField>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="10">
                    <v-alert flat color="primary" outlined type="info" class="mb-0 border-alert px-0">
                      <p class="font-weight-regular font-italic text-body-2">You can always change your site name later, in the management section of <span v-if="!$embedApplication">your Hub</span> <span v-else>the application</span>.</p>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row no-gutters class="mb-16" justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepBack" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="saveAndStepForward" :disabled="!site.name || loadingSaveSite" :loading="loadingSaveSite"  text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import TextField from "@/components/TextField.vue";
import {Action, Getter} from "vuex-class";
import {Site} from "@/types";
import Select from "@/components/Select.vue";
import SiteService from "@/services/site.service";
import EventBus from "@/services/eventbus.service";


enum SiteWizardSteps {
  GetStarted = 1,
  SiteSelection = 2,
  ConfirmSite = 3,
  NameSite = 4,
}

@Component({
  components: {Select, TextField, GoogleMap, ActionButton}
})
export default class SiteWizard extends Vue {
  @Getter private accountId!: number;
  @Action private showToastError!: any;
  @Action private goHome!: any;
  @Prop() private sites!: Site[];
  @Prop() private siteStep!: number;
  private showSiteCreation = false;
  private loadingSaveSite = false;
  private SiteWizardSteps = SiteWizardSteps;
  private activeStep: SiteWizardSteps = SiteWizardSteps.GetStarted;
  private site: Partial<Site> = { address: '', coords: [] };
  private selectedSite: any = null;

  @Watch('siteStep', {immediate: true})
  private onStepChange() {
    if (this.siteStep > 0) {
      this.activeStep = this.siteStep;
    }
  }

  private mounted() {
    if (!this.sites.length) {
      this.showSiteCreation = true;
    }
  }

  private stepForward() {

    this.activeStep++;

    if (this.activeStep === SiteWizardSteps.SiteSelection) {
      this.selectedSite = null;
    }
  }

  private stepBack() {
    if (this.activeStep === SiteWizardSteps.NameSite) {
      this.site.name = undefined;
    }
    if (this.activeStep === SiteWizardSteps.SiteSelection) {
      this.site.address = undefined;
    }
    this.activeStep--;
  }

  private selectSite(selectedSite: Site | null) {
    const site = this.sites.find((s: Site) => selectedSite && s.id === selectedSite.id);
    if (site) {
      this.selectedSite = site.id;
      this.site = site;
    } else {
      this.site = { address: '' }
      this.showSiteCreation = true;
    }
  }

  private checkSiteExists() {
    this.site.id ? this.saveAndStepForward() : this.stepForward();
  }

  private setSiteDetails(site: { address: string, coords: number[] }) {
    this.site = {};
    Vue.set(this.site, 'address', site.address);
    Vue.set(this.site, 'coords', site.coords);
    this.$emit('new-site', true)
  }

  private setSiteName(name: string) {
    Vue.set(this.site, 'name', name);
  }

  private async saveAndStepForward() {
    this.loadingSaveSite = true;
    if (this.site.id) {
      this.$emit('complete:site-wizard', this.site)
    } else {
      try {
        if (this.site.name && this.site.coords) {
          const newSite = await SiteService.createSite(this.accountId, {name: this.site.name, coords: this.site.coords, is_site: true });

          Vue.set(this, 'site', newSite)
          EventBus.$emit('showSnackbar', {text: 'Site created', icon: 'mdi-content-save'});
        }
        this.$emit('complete:site-wizard', this.site)
      } catch (e) {
        const err = e as Error;
        this.showToastError(err.message)
      }
    }
    this.loadingSaveSite = false;
  }

  private get siteDetails() {
    return this.site && this.site.address ? this.site.address.split(',') : '';
  }

}
</script>

<style scoped>
#outer-sm {
  height: calc(100vh - 104px);
}
#outer-md {
  height: calc(100vh - 16px);
}
.absolute {
  position: absolute;
}
.centerpiece {
  height: 220px;
  width: 220px;
}
.add {
  margin-top: 110px;
  margin-left: 110px;
}
</style>