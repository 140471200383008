<template>
  <v-app>
    <Layout v-if="$route.path.startsWith('/') && !$route.path.endsWith('login') && !$route.path.endsWith('reset')" />
    <LoginFlow v-if="$route.path.endsWith('login')" />
    <ActivationFlow v-if="$route.path.endsWith('reset')" />

    <NotificationSnackbar :open="snackbarOpened" :icon="snackbarIcon" :text="snackbarText" />
    <v-main app>
      <div class="kb-dialog">
        <v-dialog fullscreen v-model="showKB" transition="dialog-bottom-transition">
          <v-card color="background">
            <v-toolbar light color="background" elevation="0" tile>
              <v-tabs>
                <v-tab @click.stop>
                  Knowledge base
                </v-tab>
              </v-tabs>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="toggleKB(false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <KnowledgeBase/>
          </v-card>
        </v-dialog>
      </div>
      <div class="fab">
        <v-btn fab elevation="2" color="primary" small @click="toggleKB(true)"><v-icon>mdi-help</v-icon></v-btn>
      </div>
      <div class="error-toast">
        <ErrorToast v-for="(message, key) in toastErrors" :key="key" :ref="key" heading="Sorry, an error occurred:" :body="message" @update:showToastBoolean="checkRemoveNotification($event, key)" />
      </div>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Layout from "@/views/Layout.vue";
import LoginFlow from "@/views/login/LoginFlow.vue";
import ActivationFlow from "@/views/login/ResetFlow.vue";
import {Action, Getter, Mutation} from "vuex-class";
import EventBus from "@/services/eventbus.service";
import NotificationSnackbar from "@/components/NotificationSnackbar.vue";
import store from "./store";
import ErrorToast from "@/components/ErrorToast.vue";
import ApplicationService from "@/services/application.service";
import KnowledgeBase from "@/views/KnowledgeBase.vue";

@Component({
  components: {KnowledgeBase, ErrorToast, NotificationSnackbar, ActivationFlow, LoginFlow, Layout}
})
export default class App extends Vue {
  @Getter private toastErrors!: {[id: string]: string};
  @Getter private showKB!: boolean;
  @Mutation private toggleKB: any;
  @Mutation private removeToastErrorMessage: any;
  private showKBDialog = false;
  private snackbarOpened = false;
  private snackbarText = '';
  private snackbarIcon = '';

  private created () {
    EventBus.$on('showSnackbar', this.showSnackbar);
    EventBus.$on('closeSnackbar', () => { this.snackbarOpened = false; });
  }
  private destroyed() {
    EventBus.$off('showSnackbar', () => { this.snackbarOpened = false; });
  }
  private checkRemoveNotification(show: boolean, key: string): void {
    if (!show) {
      this.removeToastErrorMessage(key);
    }
  }
  private showSnackbar(details: any) {
    this.snackbarOpened = true;
    this.snackbarText = details.text;
    this.snackbarIcon = details.icon;
    setTimeout(() => {
      this.snackbarOpened = false;
    }, 3000);
  }
}
</script>

<style lang="scss">
body, #app {
  background: #343434
}
.v-application a.router-link-active {
  color: #f2243f;
  font-weight: bold;
  text-decoration: none;
}
.v-application a.router-link-active:hover {
  color: rgba(242, 36, 63, 0.5);
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.error-toast {
  position: fixed;
  top: 0;
  right: 8px;
}
.fab {
  position: fixed;
  bottom: 12px;
  right: 12px;
}
</style>