<template>
  <v-card height="100%" flat color="transparent" v-if="appLoading">
    <v-row align="center" justify="center" style="height: 100%">
      <v-col cols="6" lg="3" sm="6">
        <v-progress-linear indeterminate></v-progress-linear>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {Getter} from "vuex-class";

@Component
export default class Loader extends Vue {
  @Getter private appLoading!: boolean;
}
</script>

<style scoped>

</style>