<template>
  <div v-if="hasPermission('application:manage')" :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container" v-if="!appLoading">
      <v-row no-gutters class="content-container">
        <v-col cols="12">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="4" md="5" class="mt-14">
                <v-row class="mb-4" v-if="!showUserDetails">
                  <v-col cols="12">
                    <v-card color="primary">
                      <ActionButton height="40" block class="white--text" text="Add a new user" @click="$router.push({name: 'create-user'})"/>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="users.length">
                  <v-col cols="12">
                    <div v-for="user of users" :key="user.id">
                      <UserCard :user="user" @clicked:user="userDetails = $event; updateDetails++" :color="user.email === userDetails.email ? 'rgba(50,55,75,0.12)' : 'tertiary'"></UserCard>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave v-if="users.length">
              <v-col cols="12" lg="8" md="7">
                <UserDetails :sites="sites" :roles="roles" :selected-user="userDetails" @refresh:user="getUsers" :key="updateDetails" @remove:user="getUsers"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
          <v-row v-else-if="users.length && $vuetify.breakpoint.smAndDown" class="mt-4">
            <v-col cols="12" v-if="!showUserDetails">
              <v-card color="primary">
                <ActionButton height="40" block class="white--text" text="Add a new user" @click="$router.push({name: 'create-user'})"/>
              </v-card>
            </v-col>
            <v-slide-x-reverse-transition hide-on-leave v-if="!showUserDetails">
              <v-col cols="12" md="12" lg="5">
                <div v-for="user of users" :key="user.id">
                  <UserCard :sites="sites" :user="user" @clicked:user="userDetails = $event; showUserDetails = true;"></UserCard>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="8" md="12">
                <UserDetails :sites="sites" :roles="roles" v-if="showUserDetails" :selected-user="userDetails" @refresh:user="getUsers" @click:back="showUserDetails = false" @remove:user="showUserDetails = false; getUsers()"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import UserCard from "@/components/UserCard.vue";
import UserDetails from "@/views/UserDetails.vue";
import {Role, Site, User} from "@/types";
import {Getter, Mutation} from "vuex-class";
import UserService from "@/services/user.service";
import ActionButton from "@/components/ActionButton.vue";
import Loader from "@/components/Loader.vue";
import SiteService from "@/services/site.service";
@Component({
  components: {Loader, ActionButton, UserDetails, UserCard}
})
export default class UserManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private showUserDetails = false;
  private userFab = false;
  private users: User[] = [];
  private roles: Role[] = []
  private userDetails: User = {} as User;
  private sites: Site[] = [];
  private updateDetails = 0;

  private async mounted() {
    this.toggleLoading(true);

    try {
      await this.getUsers();
      this.roles = await UserService.getRoles();
      this.sites = await SiteService.getSites(this.accountId, 1);
    } finally {
      this.toggleLoading(false);
    }
  }

  private async getUsers(userId: number | null = null) {
    this.users = await UserService.getUsers();
    if (userId) {
      const user = this.users.find((u) => u.id === userId);
      if (user) {
        this.userDetails = user;
      }
    } else {
      this.userDetails = this.users[0];
      ++this.updateDetails;
    }
  }

}
</script>

<style scoped>

.full-height {
  height: calc(100vh - 96px);
}
</style>