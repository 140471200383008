<template>
  <div>
    <v-card class="mb-12" color="tertiary">
      <v-row justify="space-between" class="px-4 py-2" no-gutters :class="{'pb-8': !site.gateway && !filteredRooms(site).length}">
        <v-col cols="auto">
          <v-row no-gutters>
            <v-col cols="auto" align-self="center">
              <v-icon size="22" color="primary" class="ml-1 mr-2">mdi-map-marker-outline</v-icon>
            </v-col>
            <v-col cols="auto">
              <div class="text-h5 font-weight-bold">{{ site.name }}</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">

          <v-dialog
              v-if="hasPermission('application:manage')"
              v-model="dialog"
              persistent
              max-width="320"
              class="rounded-lg"

          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs"><v-icon color="primary">mdi-pencil</v-icon></v-btn>
            </template>
            <v-card class="rounded-lg" color="tertiary">
              <v-form @submit.prevent="saveNames" v-model="valid">
                <v-card-text class="mt-2">
                  <v-row>
                    <v-col cols="12">
                      <v-card-title class="text-h6 font-weight-bold text--primary pt-0 pl-0">
                        Edit site name
                      </v-card-title>
                      <TextField label="Site name" hide-details :rules="[v => !!v && v.length > 1 || false]" :value="site.name" @update:value="names.site = $event" @change="setValue(site.id, names.site, true)" append-icon="mdi-map-marker"/>
                    </v-col>
                    <v-col cols="12">
                      <v-card-title class="text-h6 font-weight-bold text--primary pt-0 pl-0">
                        Delete site
                      </v-card-title>
                      <div class="text-center">
                        <ActionButton :disabled="siteHasSensors || siteHasGateway" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Delete this site" @click="deleteDialog = true"></ActionButton>
                        <p v-if="siteHasSensors" class="secondary--text mt-4">This site has sensors installed, and so cannot be deleted.</p>
                        <p v-else-if="siteHasGateway" class="secondary--text mt-4">This site has a gateway installed, and so cannot be deleted.</p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-none"
                      color="secondary"
                      text
                      @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      type="submit"
                      class="text-none"
                      color="primary"
                      text
                      :loading="saveLoading"
                      :disabled="saveLoading || !valid"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog
              v-if="hasPermission('application:manage')"
              v-model="deleteDialog"
              persistent
              max-width="420"
              class="rounded-lg"

          >
            <v-card class="rounded-lg" color="tertiary">
              <v-card-title class="text-h6 font-weight-bold text--primary">
                Delete site
              </v-card-title>
              <v-card-text>
                <p>Are you sure you want to delete <strong>{{ site.name }}?</strong></p>
                <p>Any historic data associated with this site or its monitoring points will no longer be available.</p>
                <p class="text-center secondary--text font-weight-bold">This action cannot be undone.</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="text-none"
                    color="primary"
                    plain
                    @click="deleteDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    type="submit"
                    class="text-none"
                    color="secondary"
                    plain
                    :loading="deleteLoading"
                    :disabled="deleteLoading"
                    @click="deleteSite(site.id)"
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row justify="center" align="center" no-gutters class="mb-2" v-if="!!site.gateway">
        <v-col cols="12">
          <v-card class="px-4 mt-4 mb-4 d-flex align-center" flat color="transparent" height="42"  :class="{'disabled-card':  !(site.gateway.status.name === 'active')}">
            <v-row no-gutters
                   @click="clickGateway(site)"
                   :style="!!site.gateway ? 'cursor: pointer' : 'cursor: default'"
                   class="px-2 mb-4"
                   :class="{'hover-row': !!site.gateway,'active-row': gatewaySelected && !!site.gateway && site.gateway.id === activeGatewayId}">
              <v-col cols="11">
                <v-row no-gutters>
                  <v-col cols="1" class="d-flex align-center justify-center pa-4 ml-3">
                    <v-badge
                        left
                        overlap
                        offset-x="10"
                        offset-y="10"
                        :color="!!site.gateway && site.gateway.status.name === 'active' ? 'success' : 'grey'"
                    >
                      <template v-slot:badge>
                        <v-icon size="14" color="white" style="margin-top: -1px">{{ site.gateway.status.name === 'active' ? 'mdi-wifi' : 'mdi-wifi-off' }}</v-icon>
                      </template>
                      <v-avatar size="38" tile>
                        <v-img :src="gatewayThumbnail(site.gateway)" :class="!!site.gateway && site.gateway.status.name === 'active' ? 'abc' : 'disabled-img'" />
                      </v-avatar>
                    </v-badge>
                  </v-col>
                  <v-col class="d-flex align-center">
                    <v-row class="pa-1 pl-7" no-gutters>
                      <v-col cols="12">
                        <div class="text-subtitle-2 font-weight-bold" v-if="site.gateway.name">
                          <span class="font-weight-bold text-caption" v-if="$vuetify.breakpoint.xs">{{ site.gateway.name }}</span>
                          <span class="font-weight-bold text-body-2" v-else>{{ site.gateway.name }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="1" class="d-flex align-center justify-end" v-if="!!site.gateway">
                <v-icon right>mdi-chevron-right</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-for="room of filteredRooms(site)" :key="room.id" no-gutters class="px-4">
        <v-col cols="12">
          <v-row
              no-gutters
              align="center"
          >
            <v-col cols="12">
              <v-row no-gutters align="center" justify="start">
                <v-col cols="10" class="d-flex align-center">
                  <span class="text-h6 ml-4 mb-2 mt-n2 font-weight-bold text-truncate">{{ room.name }}</span>
                </v-col>

                <v-col cols="12">
                  <v-row v-for="(asset, index) of room.assets" :key="asset.id" no-gutters>
                    <v-col cols="12" v-if="asset.monitoringPoints.some((mp) => mp.device.id)">
                      <v-row no-gutters>
                        <v-col cols="12" v-if="index !== 0 && !asset.monitoringPoints.some((mp) => mp.device.id)">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" v-for="(monitoringPoint, m) of getPositionList(asset.id, site, room)" :key="monitoringPoint.id">
                          <v-row
                              style="cursor: pointer"
                              @click="clickDevice(site, asset, monitoringPoint, room)"
                              no-gutters
                              class="mb-2 hover-row px-2"
                              :class="{'active-row': monitoringPoint.device.id === activeDeviceId, 'mb-4': m === getPositionList(asset.id, site, room).length - 1}"
                          >
                            <v-col cols="12">
                              <v-row justify="space-between" no-gutters align="center">
                                <v-col cols="11" class="d-flex align-center">
                                  <v-row no-gutters class="flex-nowrap">
                                    <v-col cols="1" class="d-flex align-center justify-center pa-2 pl-7">
                                      <v-badge
                                          class="mt-2"
                                          left
                                          offset-x="10"
                                          offset-y="10"
                                          :color="getStatusIndicator(monitoringPoint.device ? monitoringPoint.device : null).status"
                                      >
                                        <template v-slot:badge>
                                          <v-icon size="14" color="white" style="margin-top: -2px">{{ getStatusIndicator(monitoringPoint.device ? monitoringPoint.device : null).icon }}</v-icon>
                                        </template>
                                        <v-avatar size="38" tile>
                                          <v-img :src="$imageUrl(monitoringPoint.device.deviceType.image)" />
                                        </v-avatar>
                                      </v-badge>
                                    </v-col>
                                    <v-col cols="11" class="d-flex align-center">
                                      <v-row class="pa-1 pl-7" no-gutters>
                                        <v-col cols="12">
                                          <div class="text-body-1">
                                            <div :style="width"><span class="font-weight-bold">{{ asset.name }}</span> › {{ monitoringPoint.name }}</div>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="1" class="d-flex align-center justify-end">
                                  <v-icon right>mdi-chevron-right</v-icon>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn
          v-if="hasPermission('application:manage')"
          color="secondary"
          fab
          dark
          small
          absolute
          bottom
          right
          @click="$router.push({ name: 'setup', params: { siteId: site.id.toString() } })"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Asset, Device, Gateway, MonitoringPoint, Room, Site} from "@/types";
import TextField from "@/components/TextField.vue";
import SiteService from "@/services/site.service";
import {Action, Getter} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
@Component({
  components: {ActionButton, TextField}
})
export default class SiteDeviceCard extends Vue {
  @Getter private hasPermission!: any;
  @Action private showToastError!: any;
  @Prop() private site!: Site;
  @Prop() private activeDeviceId!: number;
  @Prop() private activeGatewayId!: number;
  private deleteDialog = false;
  private deleteLoading = false;
  private dialog = false;
  private gatewaySelected = false;
  private valid = false;
  private saveLoading = false;
  private isDirty: {[key: string]: { name: string, isSite: boolean }} = {}
  private names: {[key: string]: string} = {}


  private clickDevice(site: Site, asset: Asset, monitoringPoint: MonitoringPoint, room?: Room) {
    const siteDetails = {
      device: monitoringPoint.device ? monitoringPoint.device : null,
      monitoringPoint: monitoringPoint,
      asset: asset,
      site: site,
      room: room
    };
    this.gatewaySelected = false;
    this.$emit('clicked:device', siteDetails);
  }

  private getPositionList(assetId: number, site?: Site, room?: Room) {
    return room?.assets.find((a: Asset) => a.id === assetId)?.monitoringPoints.filter((mp) => mp.device.id);
  }

  private getStatusIndicator(device: Device) {
    if (!device.status_id) {
      return { status: 'warning', icon: 'mdi-exclamation-thick' };
    }
    const colors: {[key: number]: { [key:string]: string }} = {
      2: { status: 'success', icon: 'mdi-check'},
      3: { status: 'success', icon: 'mdi-check' },
      4: { status: 'error', icon: 'mdi-exclamation-thick' }
    };

    return colors[device.status_id];
  }

  private setValue(id: string, name: string, isSite: boolean) {
    Vue.set(this.isDirty, id, {name, isSite});
  }

  private clickGateway(site: Site) {
    if (site.gateway) {
      console.log(site)
      this.gatewaySelected = true;
      this.$emit('clicked:gateway', site)
    }
  }

  private async saveNames() {
    this.saveLoading = true;
    try {
      for (const id in this.isDirty) {
        if (this.isDirty[id]) {
          await SiteService.updateSite(Number(id), {
            name: this.isDirty[id].name,
            is_site: this.isDirty[id].isSite
          })
        }
      }
      this.dialog = false;
      this.isDirty = {};
      this.$emit('refresh:sites')
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }

  private gatewayThumbnail(gateway: {[key: string]: any}) {
    if (gateway && (gateway.gateway_type.slug === 'lairdrg1xxbg4' || gateway.gateway_type.slug === 'lairdrg1xx5cjm6')) {
      return require('@/assets/laird_gateway_thumbnail.png')
    } else {
      return require('@/assets/gateway_thumbnail.png')
    }
  }

  private async deleteSite(siteId: number) {
    this.deleteLoading = true;
    try {
      await SiteService.deleteSite(siteId);
      this.$emit('refresh:sites')
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.deleteLoading = false;
      this.deleteDialog = false;
    }
  }

  private getAssetType(type: string) {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[type];
  }

  private filteredRooms(site: Site) {
    return site.rooms?.filter((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => !!mp.device.id)));
  }

  private get siteHasSensors() {
    return !!this.site && !!this.site.rooms && !!this.site.rooms.length && this.site.rooms.some((r: Room) => !!r.assets && r.assets.some((a) => a.monitoringPoints.some((mp) => !!mp.device)));
  }

  private get siteHasGateway() {
    return !!this.site && !!this.site.gateway
  }

  private get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'max-width: 38vw';
      case 'sm': return 'max-width: 60vw';
      case 'md': return 'max-width: 24vw';
      default: return 'max-width: 100vw';
    }
  }

}
</script>

<style >
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .disabled-card * {
    color: rgba(255, 255, 255, 0.50) !important;
  }

  .disabled-img {
    opacity: 0.5;
  }
</style>