<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />

    <div class="px-6" v-if="filteredSites.length && !appLoading">
      <!--This is for the desktop view, to display device/asset details on the same page-->
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <v-slide-x-reverse-transition hide-on-leave>
          <v-col cols="12" lg="4" md="5" class="mt-7">
            <SiteCard :sites="filteredSites" @clicked:monitoring-point="siteDetails = $event; assetDetails = {}; showDeviceMetrics = true; showAlertDetails = false" @clicked:asset="assetDetails = $event; siteDetails = {}" @clicked:alerts="alertDetails = $event; showAlertDetails = true; showDeviceMetrics = false"></SiteCard>
          </v-col>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave>
          <v-col cols="12" lg="8" md="7" :class="{'mt-7': $vuetify.breakpoint.mdAndUp}">
            <div class="sticky-column">
              <AlertDetails v-if="showAlertDetails && !showDeviceMetrics && alertDetails.alerts && alertDetails.alerts.length" :site="alertDetails.site" :alerts="alertDetails.alerts" @show:metrics="showMetricsForAlert" :loading-metrics="loadingMetrics"/>
              <DeviceMetrics v-if="showDeviceMetrics && !showAlertDetails && siteDetails.site && siteDetails.site.id" :selected-site="siteDetails" :key="siteDetails.device.id"/>
            </div>
          </v-col>

        </v-slide-x-reverse-transition>
      </v-row>
      <!--This is for the mobile view, to display device/asset details on another page-->
      <v-row v-else :class="showSidePanel ? 'mt-4' : ''">
        <v-slide-x-reverse-transition hide-on-leave v-if="!showSidePanel">
          <v-col cols="12" lg="4" md="5" class="mt-6">
            <SiteCard :sites="filteredSites" @clicked:monitoring-point="siteDetails = $event; showSidePanel = true; assetDetails = {}; showDeviceMetrics = true;" @clicked:asset="assetDetails = $event; showSidePanel = true; siteDetails = {}" @clicked:alerts="showSidePanel = true; alertDetails = $event; showAlertDetails = true;"></SiteCard>
            <v-btn block class="text-none white--text" color="secondary" @click="$router.push({name: 'records'})">Download your data</v-btn>
          </v-col>
        </v-slide-x-reverse-transition>
        <v-slide-x-reverse-transition hide-on-leave v-if="showSidePanel">
          <v-col cols="12" lg="8" md="7" :class="{'mt-14': $vuetify.breakpoint.mdAndUp}">
            <AlertDetails v-if="showAlertDetails && alertDetails.alerts && alertDetails.alerts.length" :site="alertDetails.site" :alerts="alertDetails.alerts" @click:back="showSidePanel = false; showAlertDetails = false;" @show:metrics="showMetricsForAlert($event); showSidePanel = true;" :loading-metrics="loadingMetrics"/>
            <DeviceMetrics @click:back="showSidePanel = false; showDeviceMetrics = false;" v-if="siteDetails.site && siteDetails.site.id && siteDetails.device && siteDetails.device.id && showDeviceMetrics && !showAlertDetails" :selected-site="siteDetails" :key="siteDetails.device.id"/>
          </v-col>
        </v-slide-x-reverse-transition>
      </v-row>
    </div>
    <div v-else-if="!appLoading" class="fill-height">
      <v-row justify="center" align="center" class="fill-height" no-gutters>
        <v-col cols="12">
          <v-row class="no-gutters" justify="center">
            <v-img :src="require('@/assets/goto_wizard.png')" contain max-height="280" />
            <v-col cols="12" class="mt-10">
              <p class="font-weight-bold text-h5 text-center">Head to the setup wizard to get started</p>
            </v-col>
            <v-col cols="auto" class="mt-10">
              <ActionButton text="Launch setup wizard" width="220" height="50" @click="$router.push({ name: 'setup' })"></ActionButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import {Asset, Device, MonitoringPoint, Room, Site} from "../types";
import Loader from "@/components/Loader.vue";
import SiteCard from "@/components/SiteCard.vue";
import DeviceMetrics from "@/views/DeviceMetrics.vue";
import AssetDetails from "@/views/AssetDetails.vue";
import SiteService from "@/services/site.service";
import AlertDetails from "@/views/AlertDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
@Component({
  components: {ActionButton, AlertDetails, AssetDetails, DeviceMetrics, SiteCard, Loader}
})
export default class Home extends Vue {
  @Action private showToastError!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private sites: Site[] = [];
  private siteDetails: { [key: string]: Site | Asset | MonitoringPoint | Room } = {};
  private assetDetails: { [key: string]: Site | Asset  } = {};
  private showDeviceMetrics = false;
  private showSidePanel = false;
  private loadingMetrics = false;

  private showAlertDetails = false;
  private alertDetails: {[key: string]: Site | {[key: string]: Date | string | number}} = {};


  private async mounted() {
    this.toggleLoading(true);
    try {
      this.sites = await SiteService.getSites(this.accountId, undefined, undefined, true);
    } finally {
      this.toggleLoading(false);
    }
  }

  private async showMetricsForAlert(event: { monitoringPointId: number, siteId: number, roomId?: number }) {
    this.loadingMetrics = true;
    try {
      let sites: Site[];
      let room;
      let asset;
      let monitoringPoint: MonitoringPoint | undefined;

      sites = await SiteService.getSites(this.accountId, 4, event.siteId)
      room = sites[0].rooms?.find((r: Room) => r.id === event.roomId);
      room?.assets.forEach((a) => {
        const found = a.monitoringPoints.find((m) => m.id === event.monitoringPointId)
        if (found) {
          asset = a;
          monitoringPoint = found;
        }
      })
      if (!asset || !monitoringPoint) {
        return;
      }
      Vue.set(this, 'siteDetails', {
        device: monitoringPoint && monitoringPoint.device ? monitoringPoint.device : null,
        monitoringPoint: monitoringPoint,
        asset: asset,
        site: sites[0],
        room: room ? room : undefined
      })

      this.showDeviceMetrics = true;
      this.showAlertDetails = false
    } catch (e) {
      const err = e as Error;
      this.showToastError(`Failed to retrieve site: ${err.message}`)
    } finally {
      this.loadingMetrics = false;
    }
  }

  private get filteredSites(): Site[] {
    return this.sites.filter((s: Site) => s.rooms && s.rooms.length && s.rooms.some((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => !!mp.device.id))))
  }
}
</script>

<style>
.full-height {
  height: 100vh;
}
.without-app-bar, .with-app-bar {
  height: calc(100vh - 64px)
}
.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before, .theme--dark.v-btn:hover::before {
  opacity: 0;
}
</style>