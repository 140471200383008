<template>
  <div class="overflow-x-hidden ">
    <div>
      <v-stepper v-model="activeStep" flat class="no-transition background">
        <v-stepper-items>
          <v-stepper-content :step="DeviceWizardSteps.FindDevice" class="pb-0">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row no-gutters>
                  <v-col cols="12">
                    <p class="font-weight-bold text-h5 text-center">Finding your sensor</p>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="!!devices && devices.length">
                  <v-col cols="12" class="py-0">
                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0">Select the sensor from the list below</p>
                  </v-col>
                  <v-col cols="12" md="8" sm="10" class="text-center my-2">
                    <v-dialog max-width="600" v-model="euiDialog[0]" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-row no-gutters justify="center" align="center">
                          <v-col cols="auto">
                            <v-icon color="secondary">mdi-help-circle</v-icon>
                          </v-col>
                          <v-col cols="auto">
                            <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 text-center mx-4 secondary--text d-flex align-center" @click="zoomImg['eui'] = true">What is your DevEUI? </a>
                          </v-col>
                        </v-row>
                      </template>
                      <v-card color="white">
                        <v-img :src="sensorDevEui" alt="Sensor DevEUI" />
                      </v-card>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" lg="8" md="10" sm="10" class="py-0">
                    <v-radio-group v-model="selectedDevice" class="my-0">
                      <v-card v-for="d of devices" :key="d.eui" outlined class="py-2 px-2 my-2" @click="selectDevice(d)" color="tertiary">
                        <v-row dense justify="center" align="center">
                          <v-col cols="12">
                            <v-row dense>
                              <v-col cols="12" class="text-center">
                                <span class="font-weight-bold">{{ d.displayName }}</span>
                              </v-col>
                              <v-col cols="12" class="text-center">
                                <span class="font-weight-bold pr-2">DevEUI:</span> <span>{{ d.eui }}</span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0" v-if="devices.length">Or scan the QR code on your sensor</p>
                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0" v-else>
                      We need to identify the serial number of your sensor, known as the <span class="font-weight-bold">DevEUI</span>. This is printed on the label on the back of the device and can be read from a QR code or entered manually.
                    </p>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center" class="mb-10">
                  <v-col cols="auto">
                    <ActionButton text="QR code" icon="mdi-qrcode" width="220" height="50" @click="setToScanAndStepForward" :loading="checkingPermission" :disabled="checkingPermission"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton text="Key In" icon="mdi-keyboard" width="220" height="50" @click="activeStep = DeviceWizardSteps.ScanBarcode; selectedMode = InputModes.KeyIn; eui = null;"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify="center">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="$route.params.siteId ? $router.push({name : 'devices'}) : $emit('go-to:site-wizard')"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="DeviceWizardSteps.DeviceFound" class="">
            <v-row class="content-container" no-gutters align="center" v-if="!!device">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12" v-if="!foundWithBarcode">
                    <p class="font-weight-bold text-h5 text-center">We found {{ devices.length > 1 ? 'this' : 'one' }} sensor</p>
                    <p class="font-weight-regular text-body-1 text-center">Confirm that this is the correct sensor</p>
                  </v-col>
                  <v-col cols="12" v-else>
                    <p class="font-weight-bold text-h5 text-center">You added the following device to your account inventory</p>
                    <p class="font-weight-regular text-body-1 text-center">Confirm that this is the sensor you would like to place</p>
                  </v-col>
                  <v-col cols="10" class="d-flex justify-center">
                    <div>
                      <v-img class="mb-10" :src="device.device_version ? $imageUrl(device.device_version.image) : ''" contain width="175"></v-img>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <p class="font-weight-bold text-body-1 text-center">{{ device && device.device_version ? device.device_version.name : '' }}</p>
                    <p class="font-weight-bold text-body-1 text-center">DevEUI: <span class="text-body-1 font-weight-regular"> {{selectedDevice}}</span></p>
                  </v-col>
                  <v-col cols="12" md="8" sm="10" class="text-center mb-4 mt-n4">
                    <v-dialog max-width="600" v-model="euiDialog[1]" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-row no-gutters justify="center" align="center">
                          <v-col cols="auto">
                            <v-icon color="secondary">mdi-help-circle</v-icon>
                          </v-col>
                          <v-col cols="auto">
                            <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 text-center mx-4 secondary--text d-flex align-center" @click="zoomImg['eui'] = true">What is your DevEUI? </a>
                          </v-col>
                        </v-row>
                      </template>
                      <v-card color="white">
                        <v-img :src="sensorDevEui" alt="Sensor DevEUI" />
                      </v-card>
                    </v-dialog>

                  </v-col>
                </v-row>
                <v-row justify="center" align="center" v-if="DeviceWizardSteps.DeviceFound && !foundWithBarcode">
                  <v-col cols="12">
                    <p class="font-weight-bold text-body-1 text-center mx-4">Looking for a different sensor?</p>
                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0">Scan the QR code or input the DevEUI manually.</p>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton text="Scan QR code" icon="mdi-qrcode" width="220" height="50" @click="setToScanAndStepForward" :loading="checkingPermission" :disabled="checkingPermission"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton text="Key In" icon="mdi-keyboard" width="220" height="50" @click="activeStep = DeviceWizardSteps.ScanBarcode; selectedMode = InputModes.KeyIn; eui = null;"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16 mt-8" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="goBackOrExit"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepForward" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.SelectRoom">

            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-row justify="center" no-gutters align="center">
                      <v-col cols="auto">
                        <p class="font-weight-bold text-h5 text-center" v-if="rooms.length && !showRoomCreation">Select a room</p>
                        <p class="font-weight-bold text-h5 text-center" v-else>Create a room</p>
                        <p class="text-body-1 text-center" v-if="rooms.length && !showRoomCreation">Choose a room from the list below, or create a new one.</p>
                        <p class="text-body-1 text-center">This is where your refrigerator, freezer or ambient room temperature sensor will be located.</p>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!rooms.length || showRoomCreation">
                    <v-row no-gutters justify="center">
                      <v-col cols="12">
                        <p class="font-weight-regular text-body-1 text-center">
                          Provide an identifier for this room. <br> This is usually a friendly name, such as <span class="font-weight-bold">Kitchen</span> or <span class="font-weight-bold">Store room</span>.
                        </p>
                      </v-col>
                      <v-col cols="12" md="6" sm="10">
                        <TextField :editable="false" label="Name" @update:value="roomName = $event" :value="roomName" hide-details></TextField>
                      </v-col>
                      <v-col cols="12" md="10" class="pt-0" v-if="rooms.length">
                        <v-row justify="center" class="py-4 mt-6">
                          <v-col cols="auto">
                            <a @click="showRoomCreation = false; selectedRoom = null; room = null; roomName = null;" style="color: #f2243f">Cancel room creation</a>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="6" md="10" sm="10" class="py-0" v-else>
                    <v-radio-group v-model="selectedRoom" class="my-0">
                      <v-card v-for="r of rooms" :key="r.id" outlined class="py-2 px-2 my-2" @click="selectRoom(r)" color="tertiary">
                        <v-row dense justify="center" align="center">
                          <v-col cols="3" class="d-flex align-center justify-center">
                            <v-radio
                                color="white"
                                class="pa-2"
                                :value="r.id"
                                :ripple="false"
                            ></v-radio>
                          </v-col>
                          <v-col cols="9">
                            <span class="font-weight-bold"> {{ r.name }}</span>
                          </v-col>
                          <!-- TODO: Indicate that users could lose historical data if they remove a room, hide for now -->
                          <!--<v-col cols="2" v-if="!r.assets.length || r.assets && r.assets.some((a) => !a.monitoringPoints || !a.monitoringPoints.length || !a.monitoringPoints.some((mp) => !!mp.device.id))">
                            <v-btn icon color="secondary" small @click.stop="deleteEmptyRoom(r.id)"><v-icon small>mdi-delete</v-icon></v-btn>
                          </v-col>-->
                        </v-row>
                      </v-card>

                      <v-card outlined class="py-2 px-2 my-2" @click="room = null; showRoomCreation = true" color="tertiary">
                        <v-row dense justify="center" align="center">
                          <v-col cols="3" class="d-flex align-center justify-center">
                            <v-radio
                                color="white"
                                value="new"
                                class="pa-2"
                            ></v-radio>
                          </v-col>
                          <v-col cols="9">
                            <span class="font-weight-bold"> Create a new room </span>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="goBackFromRoomSelection"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="saveRoomAndStepForward"  text="Next step" icon-right="mdi-arrow-right" :disabled="roomDisabled" :loading="loadingRoom"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.SelectAsset">

            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12">
                    <v-row justify="center" no-gutters align="center">
                      <v-col cols="auto">
                        <p class="font-weight-bold text-h5 text-center">Select a unit type {{ room ? 'for' : ''}}</p>
                        <p class="font-weight-bold text-h4 text-center mb-0">{{ room ? room.name : ''}}</p>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="mb-6">
                    <v-row justify="center" no-gutters>
                      <v-col cols="10" md="4" v-for="type of assetTypes" :key="type.assetType" class="align-self-md-stretch mt-4">
                        <v-card class="mx-2" height="100%" min-height="85px" :ripple="false"  :color="type.assetType === selectedAssetType ? 'rgba(255,255,255,0.5)' : 'tertiary'" @click="selectedAssetType = type.assetType; showAssetCreation = false; asset = null; selectedAsset = null; newAsset = { manufacturer: null, name: null }; getAssets(type.assetType, selectedRoom);">
                          <v-row justify="center" no-gutters align="center" class="fill-height">
                            <v-col cols="8" md="12" class="pa-4">
                              <span class="font-weight-bold text-capitalize text-md-center text-body-1 text-wrap" v-if="type.assetType !== 'room'">{{ type.assetType }}</span>
                              <span class="font-weight-bold text-capitalize text-md-center text-body-1 text-wrap" v-else>Ambient room temperature</span>
                            </v-col>
                            <v-col cols="4" md="12">
                              <v-row justify="center" align="center">
                                <v-col cols="auto" align-self="center" class="mb-md-2">
                                  <v-icon color="primary" :size="$vuetify.breakpoint.smAndDown ? 42 : 100">{{ type.icon }}</v-icon>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row justify="center" class="px-6" v-if="!showAssetCreation">
                  <v-col cols="12" md="6">
                    <v-row justify="center">
                      <v-col cols="12" class="py-0">
                        <v-radio-group v-model="selectedAsset" class="my-0">
                        <!--  TODO: Check which kind of unit/asset is assigned and disable only if same type is selected  -->
                          <v-card v-for="a of assets" :key="a.id" outlined class="py-2 px-2 my-2" @click="selectAsset(a)" :disabled="a.monitoringPoints.some((mp) => !!mp.device.id)" color="tertiary">
                            <v-row dense justify="center" align="center">
                              <v-col cols="3" class="d-flex align-center justify-center">
                                <v-radio
                                    color="white"
                                    class="pa-2"
                                    :value="a.id"
                                    :ripple="false"
                                ></v-radio>
                              </v-col>
                              <v-col cols="6">
                                <span class="font-weight-bold"> {{ a.name }}</span>
                              </v-col>
                              <v-col cols="3" class="d-flex justify-center">
                                <span class="text--disabled" v-if="a.monitoringPoints.some((mp) => !!mp.device.id)"> (in use)</span>
                              </v-col>
                            </v-row>
                          </v-card>

                          <v-card outlined class="py-2 px-2 my-2" @click="asset = null; showAssetCreation = true" v-if="!!selectedAssetType" color="tertiary">
                            <v-row dense justify="center" align="center">
                              <v-col cols="3" class="d-flex align-center justify-center">
                                <v-radio
                                    color="white"
                                    value="new"
                                    class="pa-2"
                                ></v-radio>
                              </v-col>
                              <v-col cols="9">
                                <span class="font-weight-bold"> Create a new {{ selectedAssetType !== 'room' ? selectedAssetType : 'room temperature location'}} </span>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="showAssetCreation" class="pt-0">
                  <v-col cols="12">
                    <p class="font-weight-regular text-body-1 mb-0 text-center" v-if="selectedAssetType !== 'room'">
                      Provide some identifying information for your {{ selectedAssetType === 'refrigerator' ? 'refrigerator' : 'freezer' }} unit
                    </p>
                    <p class="font-weight-regular text-body-1 mb-0 text-center" v-else>
                      Provide a name for the location in the room where you will monitor the ambient temperature.
                    </p>
                  </v-col>
                  <v-col cols="12" md="6" sm="10">
                    <TextField :editable="false" label="Name" @update:value="assetName = $event" hint="This could be a number or a name"></TextField>
                  </v-col>
                  <v-col cols="12" md="6" sm="10" v-if="selectedAssetType !== 'room'">
                    <v-combobox label="Manufacturer"
                                style="border-radius: 8px"
                                v-model="assetManufacturer"
                                :items="supplierList"
                                item-text="name"
                                persistent-hint
                                persistent-placeholder
                                outlined
                                hint="Select a manufacturer or enter a custom value"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="auto" class="pb-2">
                    <v-alert flat color="primary" outlined type="info" class="mb-0 border-alert pa-0">
                      <p class="font-weight-regular font-italic text-body-2" v-if="selectedAssetType !== 'room'">The name of your unit can be changed later, in the management section of <span v-if="!$embedApplication">your Hub</span> <span v-else>the application</span>.</p>
                      <p class="font-weight-regular font-italic text-body-2" v-else>The name of the location for your ambient temperature sensor can be changed later, in the management section of <span v-if="!$embedApplication">your Hub</span> <span v-else>the application</span>.</p>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="10" class="pt-0">
                    <v-row justify="center" class="pb-4">
                      <v-col cols="auto">
                        <a @click="showAssetCreation = false; selectedAsset = null; asset = null; newAsset.name = null; newAsset.manufacturer = null;" style="color: #f2243f">Cancel {{ selectedAssetType !== 'room' ? selectedAssetType : '' }} creation</a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="goBackFromAssetSelection"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="saveLocationAndStepForward"  text="Next step" icon-right="mdi-arrow-right" :disabled="disabled" :loading="loadingAsset"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.DeviceInformation">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper px-3">
                <v-row dense justify="center">
                  <v-col cols="12">

                    <v-row justify="center" no-gutters align="center">
                      <v-col cols="auto">
                        <p class="font-weight-bold text-h5 text-center">Configure your {{ selectedAssetType !== 'room' ? selectedAssetType : 'ambient room temperature' }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="my-2" justify="center">
                  <v-col cols="12">
                    <v-form v-model="validTemps" ref="tempForm">
                      <v-row justify="center">
                        <v-col cols="12" md="6">
                          <v-text-field
                              class="custom-field"
                              outlined
                              persistent-hint
                              persistent-placeholder
                              :rules="[(v => !!v || 'This value is required'), (v => v < Number(configuration.maxTemp) || `Must be lower than maximum threshold`)]"
                              label="Minimum temperature threshold"
                              type="number"
                              hint="Temperatures below this threshold will generate an alert"
                              v-model="configuration.minTemp">
                            <template v-slot:append>
                              <v-row no-gutters align="center">
                                <v-col class="mt-1">
                                  °C
                                </v-col>
                              </v-row>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                              class="custom-field"
                              outlined
                              persistent-hint
                              persistent-placeholder
                              :rules="[(v => !!v || 'This value is required'), (v => ((v > Number(configuration.minTemp))) || 'Must be greater than minimum threshold')]"
                              label="Maximum temperature threshold"
                              type="number"
                              hint="Temperatures above this threshold will generate an alert"
                              v-model="configuration.maxTemp">
                            <template v-slot:append>
                              <v-row no-gutters align="center">
                                <v-col class="mt-1">
                                  °C
                                </v-col>
                              </v-row>
                            </template>
                          </v-text-field>

                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12">
                    <v-alert flat color="primary" outlined type="info" class="mb-0 border-alert px-0">
                      <p v-if="selectedAssetType === 'refrigerator'" class="mb-0  font-italic text-body-2">
                        The Food Standards Agency (UK)  advises to keep chilled foods between 1- 4°C (or below 5°C) to ensure it remains outside the “danger zone” 8°C to 63°C. The lower the temperature you set your unit, the more energy it will use. Meat and fish fridges operate between -1°C to +1°C
                      </p>
                      <p v-else-if="selectedAssetType === 'freezer'" class="mb-0  font-italic text-body-2">
                        The Food Standard Agency (UK) advises that freezers should be held at -18°C. The lower you set the temperature of the freezer, the more energy the unit will consume. Some products are held at higher temperatures, such as ice cream, which can be kept at around -12°C.                      </p>
                      <p v-else class="mb-0 font-italic text-caption">
                        Commercial refrigeration will work to maximum ambient temperatures, (usually either 32°C, 38°C or 43°C) this is dependent on the make and model of the unit. Likewise, they usually have a minimum working ambient temperature of around 18°C however some will operate as low as 10°C. If you operate a unit outside these temperatures, it is likely to stop working. If you can return the ambient room temperature back to within the parameters, the unit is likely to start functioning again without the need for an engineer call outs. Call outs for engineers that attend a site where the room temperature is outside its working ambient temperature would usually not be covered by the manufacturer and would therefore be deemed chargeable.
                      </p>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" md="5" class="d-flex justify-center">
                    <v-row no-gutters justify="center">
                      <v-col cols="12">
                        <span class="text-caption ml-4">Alert threshold</span>
                      </v-col>
                      <v-col cols="auto">
                        <v-chip-group mandatory active-class="secondary" v-model="selectedAlertThreshold">
                          <v-chip
                              v-for="duration in alertThresholds"
                              :key="duration.value"
                              :value="duration.value"
                          >
                            {{ duration.text }}
                          </v-chip>
                        </v-chip-group>
                      </v-col>
                      <v-col cols="12">
                        <span class="text-caption ml-4">This is the delay before an alert is sent</span>
                      </v-col>

                      <v-col cols="12" class="text-center mt-6 px-4">
                        <p class="font-weight-bold text-body-1 mb-2">Need help configuring?</p>
                        <ActionButton @click="toggleKB(true)" class="mb-16" color="secondary" text="Go to knowledge base" width="220" height="50"></ActionButton>
                      </v-col>
                    </v-row>


                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="$emit('reload:site', true); activeStep = DeviceWizardSteps.SelectAsset; getAssets(selectedAssetType, selectedRoom)"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepForward" :loading="loadingMonitoringPoint" :disabled="monitoringPointDisabled" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.PlaceDevice">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12" md="10">
                    <p class="font-weight-bold text-h5 text-center">Place your sensor</p>
                    <p class="font-weight-regular text-h6 text-center">Follow the steps below to find out how and where to place your sensor </p>
                  </v-col>
                  <v-col cols="12">
                    <v-stepper flat v-model="placementSteps" class="background">
                      <v-stepper-items>
                        <v-stepper-content class="pa-0 ma-0" v-for="step in instructions" :step="step.step" :key="step.img">
                          <v-row justify="center">
                            <v-col cols="auto">
                              <v-card flat outlined max-width="400" max-height="400" min-height="300" min-width="300">
                                <v-img :src="step.img" max-width="400" max-height="400">
                                  <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                      <v-progress-circular
                                          indeterminate
                                          color="primary"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row no-gutters justify="center" align="center" class="mb-4">
                            <v-col cols="2" class="d-flex justify-center">
                              <v-btn icon @click="placementSteps--" v-if="placementSteps !== 0">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="auto" class="text-center">
                              {{ `Step ${step.step + 1}` }}
                            </v-col>
                            <v-col cols="2" class="d-flex justify-center">
                              <v-btn icon @click="placementSteps++" v-if="instructions.length - 1 !== placementSteps">
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <p class="px-4 text-center">{{ step.text }}</p>
                            </v-col>
                          </v-row>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters>
                  <v-col class="d-flex justify-center">
                    <ActionButton width="220" height="50" @click="mountDeviceAndStepForward" :loading="loadingMounting" :disabled="loadingMounting"  text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.TestConnection">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row class="mx-4 no-gutters" justify="center" align="center">
                  <v-col cols="12" class="centerpiece align-self-end" v-if="testingConnection">
                    <p class="font-weight-bold text-h5 text-center">Testing connection</p>
                    <v-row justify="center">
                      <v-col cols="6">
                        <v-progress-linear indeterminate></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!testingConnection && deviceConnected">
                    <p class="font-weight-bold text-h5 text-center">Success!</p>
                    <p class="font-weight-bold text-h5 text-center">The sensor is successfully communicating.</p>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon size="200" color="success">mdi-check-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!testingConnection && !deviceConnected">
                    <p class="font-weight-bold text-h5 text-center">Failed to determine sensor connection status</p>
                    <p class="font-weight-regular text-h6 text-center">Visit our knowledge base for troubleshooting help</p>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon size="200" color="secondary">mdi-help-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center mt-6" v-if="!testingConnection && !deviceConnected">
                    <ActionButton width="220" height="50" color="secondary" @click="toggleKB(true)" text="Go to knowledge base"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-4" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="12" class="d-flex justify-center" v-if="!testingConnection && deviceConnected">
                    <ActionButton width="220" height="50" @click="$emit('add:device')" text="Add another sensor"></ActionButton>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center" v-if="!testingConnection && !deviceConnected">
                    <ActionButton width="220" height="50" @click="testConnection" text="Re-test connection" icon="mdi-reload"></ActionButton>
                  </v-col>
                </v-row>
                <v-row v-if="!testingConnection">
                  <v-col cols="12" class="d-flex justify-center">
                    <router-link :to="{ name: 'devices' }" @click="$emit('reload:site', false)">Exit setup</router-link>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="DeviceWizardSteps.ScanBarcode">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row class="d-flex flex-column justify-space-around">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <p class="font-weight-bold text-h5 text-center mb-0" v-if="selectedMode === InputModes.QrScan">Scan the QR code on your sensor</p>
                        <p class="font-weight-bold text-h5 text-center mb-0" v-else>Key in the DevEUI for your sensor</p>
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn-toggle v-model="selectedMode" rounded mandatory>
                          <v-btn class="text-none" width="110">
                            <v-icon left>mdi-qrcode</v-icon>
                            <span>QR code</span>
                          </v-btn>
                          <v-btn class="text-none" width="110">
                            <v-icon left>mdi-keyboard</v-icon>
                            <span>Key In</span>
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row justify="center" align="center" v-if="loadingCamera" class="fill-height">
                      <v-col cols="6" lg="3" sm="6" class="flex-grow-1">
                        <v-card height="100%" flat color="transparent" min-height="200" class="d-flex align-center">
                          <v-progress-linear indeterminate></v-progress-linear>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12" md="8" class="d-flex justify-center" v-if="selectedMode === InputModes.QrScan">

                        <v-card max-height="480" flat v-if="activeStep === DeviceWizardSteps.ScanBarcode">
                          <qrcode-stream @decode="onDecode" @init="loadCamera"></qrcode-stream>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6" class="d-flex justify-center" v-else>
                        <v-row justify="center">
                          <v-col cols="12" class="mt-4">
                            <v-form v-model="validEui" @submit.prevent>
                              <v-text-field outlined prepend-inner-icon="mdi-keyboard" v-model="eui" :loading="loadingScanResult" :disabled="loadingScanResult" :rules="devEuiRule"></v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center" class="mt-0">
                  <v-col cols="12" class="text-center">
                    <span class="text-body-1" v-if="selectedMode === InputModes.QrScan">There may be multiple QR codes on your device. Use the one highlighted below.</span>
                    <span class="text-body-1" v-else>The DevEUI can be found on the sensor label, and will appear similar to the image below.</span>
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center justify-center">
                    <v-card v-if="selectedMode === InputModes.QrScan">
                      <v-img :src="sensorQrCode" alt="Sensor QR code" contain max-width="395"/>
                    </v-card>
                    <v-card v-else>
                      <v-img :src="sensorDevEui" alt="Sensor QR code" contain max-width="390"/>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" v-if="selectedMode === InputModes.KeyIn">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="devices.length === 1 ? activeStep = DeviceWizardSteps.DeviceFound : activeStep = DeviceWizardSteps.FindDevice;"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="searchDevices" :disabled="searchDisabled" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" class="mt-6" v-else>
                <v-row class="mb-16" no-gutters>
                  <v-col class="d-flex justify-center">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="devices.length === 1 ? activeStep = DeviceWizardSteps.DeviceFound : activeStep = DeviceWizardSteps.FindDevice;"  icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="DeviceWizardSteps.ConfirmAssign">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row class="d-flex flex-column justify-space-around">
                  <v-col cols="12" v-if="!!device && !device.project_id">
                    <p class="font-weight-bold text-h5 text-center">A device was identified</p>
                    <p class="text-body-1 text-center">Please confirm that you would like to add the device to your account</p>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon size="200" color="success">mdi-check-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-else-if="!!device && device.project_id">
                    <p class="font-weight-bold text-h5 text-center">That device already belongs to an account</p>
                    <p class="font-weight-bold text-body-1 text-center">Please check the DevEUI and try again.</p>
                  </v-col>
                  <v-col cols="12" v-else-if="!device">
                    <p class="font-weight-bold text-h5 text-center">We couldn't identify a device with those details</p>
                    <p class="font-weight-bold text-body-1 text-center">Please check the DevEUI and try again.</p>

                    <v-alert flat color="transparent" class="mb-6 border-alert text-center primary--text">
                      <span class="text-body-1">Need help? <br/> Visit our knowledge base, so we can get you up and running.</span>

                      <v-row justify="center" class="mt-6">
                        <v-col cols="auto">
                          <ActionButton @click="toggleKB(true)" width="220" height="50" color="secondary" text="Go to knowledge base"/>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="activeStep = DeviceWizardSteps.ScanBarcode;" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220"  height="50" @click="assignDevice()" text="Next step" icon-right="mdi-arrow-right" :disabled="assigning" :loading="assigning"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import {Action, Getter, Mutation} from "vuex-class";
import {ApplicationAttribute, Asset, Device, MonitoringPoint, Room, Site} from "@/types";
import SiteService from "@/services/site.service";
import DeviceService from "@/services/device.service";
import EventBus from "@/services/eventbus.service";
import {throttle} from "@/classes/utils";
import validation from "@/classes/validation";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

enum InputModes {
  QrScan = 0,
  KeyIn = 1
}
enum DeviceWizardSteps {
  FindDevice = 1,
  DeviceFound = 2,
  SelectAsset = 3,
  DeviceInformation = 4,
  PlaceDevice = 5,
  TestConnection = 6,
  ScanBarcode = 7,
  ConfirmAssign = 8,
  SelectRoom = 9
}

@Component({
  components: {Select, TextField, ActionButton, QrcodeStream, QrcodeDropZone, QrcodeCapture}
})
export default class DeviceWizard extends Vue {
  @Getter private accountId!: number;
  @Getter private applicationAttributes!: ApplicationAttribute[];
  @Action private showToastError!: any;
  @Action private goHome!: any;
  @Prop() private savedDevices!: Device[];
  @Prop() private sites!: Site[];
  @Prop() private site!: Site;
  @Mutation private toggleKB: any;

  private DeviceWizardSteps = DeviceWizardSteps;
  private activeStep: DeviceWizardSteps = DeviceWizardSteps.FindDevice;
  private instructions: Array<{[key: string]: string | number}> = [
    { step: 0, img: require('@/assets/setup/sensor/battery.png'), text: `Insert two Lithium AA batteries into the sensor` },
    { step: 1, img: require('@/assets/setup/sensor/place.png'), text: 'Install the sensor in a suitable location, ensuring it is away from outlet grills, and securely fitted.' }
  ];
  private placementSteps = 0;

  /*Barcode*/
  private InputModes = InputModes;
  private eui: string | null = null;
  private validEui = false;
  private loadingCamera = false;
  private selectedMode = InputModes.QrScan;
  private onDecode = throttle((result: string) => { this.displayResult(result) }, 15000);
  private searching = false;
  private assigning = false;
  private loadingScanResult = false;
  private checkingPermission = false;
  /*End barcode*/

  private foundWithBarcode = false;
  private selectedDevice: string | undefined = '';
  private selectedAsset: number | null = null;

  private loadingAsset = false;
  private testingConnection = false;
  private deviceConnected = false;
  private selectedAlertThreshold = 15;

  private rooms: Room[] = [];
  private room: Room | null = null;
  private createdRoom: Room | null = null;
  private selectedRoom: number | null = null;
  private showRoomCreation = false;
  private loadingRoom = false;
  private roomName: string | null = '';

  private assets: Asset[] | undefined = [];
  private newAsset: { [key:string]: string | null } = { name: '', manufacturer: ''};
  private asset: Asset | null = null;
  private createdAsset: Asset | null = null;
  private selectedAssetType = '';
  private showAssetCreation = false;
  private assetTypes: Array<{ assetType: string; icon: string; }> = [
    { assetType: 'refrigerator', icon: 'mdi-fridge-outline' },
    { assetType: 'freezer', icon: 'mdi-snowflake' },
    { assetType: 'room', icon: 'mdi-floor-plan' },
  ];
  private euiDialog: { [key: number]: boolean } = {};
  private zoomImg = {
    eui: true,
  };
  private loadingMonitoringPoint = false;
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private configuration: any = {};
  private createdMonitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private validTemps = false;

  private loadingMounting = false;
  private devices: Device[] = [];
  private device: Device = {} as Device;
  private alertThresholds: Array<{ text: string, value: number }> = [
    { text: '0 min', value: 0 },
    { text: '15 min', value: 15 },
    { text: '30 min', value: 30 },
    { text: '1 hr', value: 60 },
    { text: '2 hr', value: 120 }
  ]
  private suppliers: string[] = [
    'Polar',
    'Nisbets Essentials',
    'Williams',
    'Foster',
    'Gamko',
    'True',
    'Adande',
    'Zoin',
    'Gram',
    'Blizzard',
    'Autonumis',
    'Kubus/Designline (drop in units)',
    'Lincat',
    'IMC',
    'Autonumis',
    'Mondial Elite',
    'Smeg',
    'Hoshizaki',
    'Infrico',
    'Crystal',
    'Artica',
  ]

  @Watch('configuration.minTemp')
  @Watch('configuration.maxTemp')
  private async onConfigChange() {
    await this.$nextTick();
    if (this.$refs && this.$refs.tempForm) {
      (this.$refs.tempForm as any).validate();
    }
  }

  private async mounted() {
    const applicationManufacturers = this.applicationAttributes.find((attrs) => attrs.id === 'manufacturers');
    if (applicationManufacturers && applicationManufacturers.value) {
      const supplierList = applicationManufacturers.value.split(',');
      this.suppliers = supplierList.map((s) => s.trim());
    }

    if (this.savedDevices.length) {
      this.devices = this.savedDevices;
    } else {
      await this.saveDevices()
    }

    if (this.activeStep === DeviceWizardSteps.FindDevice) {
      if (this.devices.length === 1) {
        this.activeStep = DeviceWizardSteps.DeviceFound;
        this.selectedDevice = this.devices[0].eui;
        this.device = this.devices[0];
      }
    }
  }

  private async saveDevices(device?: Device) {
    if (device) {
      this.devices.push(device);
    }
    this.devices = await DeviceService.getDevices();
    this.$emit('save:devices', this.devices)
  }

  private async getRooms() {
    if (this.site.rooms && this.site.rooms.length) {
      Vue.set(this, 'rooms', this.site.rooms);
    } else {
      this.showRoomCreation = true;
    }
  }

  private async getAssets(type: string, selectedRoom?: number) {
    this.selectedAssetType = type;
    if (selectedRoom) {
      const room = this.site.rooms?.find((r) => r.id === selectedRoom);
      if (room) {
        this.assets = room.assets.filter((c: Asset) => {
          if (c.assetType === type) {
            return c;
          }
        })

        if (!room.assets.filter((asset: Asset) => asset.assetType === type).length) {
          this.asset = null;
          this.showAssetCreation = true;
        }
      }
    }
  }

  private async selectRoom(r: Room) {
    this.selectedRoom = r.id;
    if (r.name) {
      this.room = r;
    }
    this.activeStep = DeviceWizardSteps.SelectAsset;
  }

  private async selectAsset(a: Asset) {
    this.selectedAsset = a.id;
    if (a.name) {
      this.asset = a;
    }
  }

  private stepForward() {
    if (this.activeStep === DeviceWizardSteps.TestConnection && this.deviceConnected) {
      this.$emit('complete:device-wizard')
    }

    if (this.activeStep === DeviceWizardSteps.DeviceFound) {
      this.getRooms();
      this.activeStep = DeviceWizardSteps.SelectRoom;
      return;
    }

    this.activeStep++;
    if (this.activeStep === DeviceWizardSteps.TestConnection) {
      this.testConnection();
    }
  }

  private selectDevice(d: Device) {
    this.device = d;
    this.selectedDevice = d.eui;
    this.stepForward();
  }

  private async deleteEmptyRoom(roomId: number) {
    try {
      await SiteService.deleteSite(roomId);
      this.rooms = this.rooms.filter((r) => r.id !== roomId)
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      if (!this.rooms.length) {
        this.showRoomCreation = true;
      }
    }
  }

  private async saveRoomAndStepForward() {
    try {
      if (!this.room?.id) {
        this.loadingRoom = true;
        this.createdRoom = await DeviceService.createRoom({
          name: this.roomName,
          siteId: this.site.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
        })
        Vue.set(this, 'room', this.createdRoom)
        const site = await SiteService.getSites(this.accountId, 4, this.site.id)
        const room = site[0].rooms?.find((r) => r.id === this.createdRoom?.id);
        if (room) {
          this.rooms.push(room);
        }
        EventBus.$emit('showSnackbar', {text: 'Room created', icon: 'mdi-content-save'});
      }
      this.activeStep = DeviceWizardSteps.SelectAsset;
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.loadingRoom = false;
    }
  }

  private async saveLocationAndStepForward() {
    try {
      if (!this.asset?.id) {
        this.loadingAsset = true;
        this.createdAsset = await DeviceService.createAssetLocation({
          name: this.assetName,
          manufacturer: this.assetManufacturer,
          roomId: this.createdRoom?.id || this.room?.id || undefined,
          siteId: this.site.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
          assetType: this.selectedAssetType
        })
        EventBus.$emit('showSnackbar', {text: 'Location created', icon: 'mdi-content-save'});
      }
      this.stepForward();
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.loadingAsset = false;
    }
  }

  private async mountDeviceAndStepForward() {
    this.loadingMounting = true;
    try {
      if (!this.monitoringPoint?.id) {
        this.loadingMonitoringPoint = true;
        this.createdMonitoringPoint = await DeviceService.createMonitoringPoint({
          name: 'Temperature', // TODO: Name based on sensor type
          assetId: this.createdAsset ? this.createdAsset.id : this.asset?.id,
          lat: this.site.coords[0],
          lng: this.site.coords[1],
          configuration: {
            minTemp: Number(this.configuration.minTemp),
            maxTemp: Number(this.configuration.maxTemp),
            alertThreshold: this.selectedAlertThreshold
          }
        })
        await DeviceService.mountDevice(this.createdMonitoringPoint.id, this.device.id)
        EventBus.$emit('showSnackbar', {text: 'Sensor mounted', icon: 'mdi-content-save'});
      }
      this.stepForward();
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
      throw e;
    } finally {
      this.loadingMounting = false;

    }
  }

  private async testConnection() {
    this.testingConnection = true;
    try {
      const messages = await DeviceService.getDeviceActivity(this.device.device_version.slug, this.device.chip.identifier)
      if (messages && messages.count > 0) {
        this.deviceConnected = true;
      }
    } finally {
      this.testingConnection = false;
    }
  }

  private goBackFromAssetSelection() {
    this.showAssetCreation = false;
    this.selectedAsset = null;
    this.asset = null;
    this.newAsset.name = null;
    this.newAsset.manufacturer = null;
    this.showRoomCreation = false;
    this.selectedRoom = null;
    this.room = null;
    this.roomName = null;
    this.activeStep = DeviceWizardSteps.SelectRoom;
  }

  private goBackFromRoomSelection() {
    if (this.showRoomCreation) {
      if (this.rooms.length) {
        this.showRoomCreation = false;
        this.roomName = '';
        return;
      }
      this.roomName = '';
      this.activeStep = DeviceWizardSteps.DeviceFound;
      return;
    }
    this.room = null;
    this.$emit('reload:site', true)
    this.activeStep = DeviceWizardSteps.DeviceFound
  }

  private goBackOrExit() {
    if (this.$route.params.siteId && this.devices.length <= 1){
      this.$router.push({name : 'devices'})
      return;
    }
    this.saveDevices();
    this.$emit('go-to:site-wizard');
  }

  private getMonitoringPointNameHint(type: string) {
    switch (type) {
      case 'refrigerator': return `This is a friendly name used to identify the location of the sensor in your refrigerator, e.g., 'Rear left'.`;
      case 'freezer': return `This is a friendly name used to identify the location of the sensor in your freezer, e.g., 'Rear left'.`;
      case 'room': return `This is a friendly name used to identify the location of your ambient temperature sensor.`;
    }
  }


  private selectInputMode(mode: number) {
    this.selectedMode = mode;
    if (mode === InputModes.QrScan) {
      this.loadingCamera = true;
    }
  }

  private decodeBarcodeResult(fullResult: string) {
    let result;
    if (fullResult.includes('SN=')) {
      result = {device_id: fullResult.split('SN=')[1]};
    } else if (fullResult.includes(',')) {
      const networkIdArr = fullResult.split(',');
      result = {network_id: networkIdArr[networkIdArr.length - 1], device_id: networkIdArr[1]};
    } else {
      result = {network_id: fullResult};
    }
    return result;
  }

  private async displayResult(fullResult: string) {
    this.selectedMode = InputModes.KeyIn;
    this.loadingScanResult = true;
    try {
      // const barcodeResult = await DeviceService.scanDevice(fullResult);
      const barcodeResult = this.decodeBarcodeResult(fullResult);
      if (barcodeResult) {
        this.eui = barcodeResult.network_id || barcodeResult.device_id || null;
      }
    } catch (e) {
      this.showToastError(`The QR scan failed, try entering the DevEUI manually if the problem persists.`);
      throw e;
    } finally {
      this.loadingScanResult = false;
    }
  }

  private async searchDevices() {
    this.searching = true
    if (!this.eui) {
      throw new Error('Missing EUI property')
    }
    const existingDevice = this.devices.find((d) => d.eui.toLowerCase() === this.eui?.toLowerCase() || this.eui?.toLowerCase() === d.device_id.toLowerCase());
    if (existingDevice) {
      Vue.set(this, 'device', existingDevice);
      this.foundWithBarcode = false;
      this.searching = false;
      this.eui = this.selectedDevice = existingDevice.eui;
      this.activeStep = DeviceWizardSteps.DeviceFound;
      return;
    }
    try {
      const devices = (await DeviceService.findDispatchedDevice(this.eui!.toLowerCase()));
      this.device = devices.length > 1 ? null : devices[0];
      if (this.device) {
        this.selectedDevice = this.eui = this.device.eui;
      }
    } finally {
      this.activeStep = DeviceWizardSteps.ConfirmAssign;
      this.searching = false;
    }
  }

  private async assignDevice() {
    this.assigning = true;
    try {
      await DeviceService.addDeviceToAccount(this.eui!)
      this.foundWithBarcode = true;
    } catch (e) {
      this.showToastError(`Something went wrong adding the device to your account, visit the knowledge base for more help.`)
      throw e;
    } finally {
      this.assigning = false;
      this.activeStep = DeviceWizardSteps.DeviceFound;
    }
  }

  private async loadCamera(promise: Promise<any>) {
    this.loadingCamera = true;

    try {
      await promise;
    } catch (error) {
      const err = error as Error;
      if (err.name === 'NotAllowedError') {
        this.showToastError("You need to grant camera access permission");
      } else if (err.name === 'NotFoundError') {
        this.showToastError("No camera on this device");
      } else if (err.name === 'NotSupportedError') {
        this.showToastError("Secure context required (HTTPS, localhost)");
      } else if (err.name === 'NotReadableError') {
        this.showToastError("Is the camera already in use?");
      } else if (err.name === 'OverconstrainedError') {
        this.showToastError("Installed cameras are not suitable");
      } else if (err.name === 'StreamApiNotSupportedError') {
        this.showToastError("Stream API is not supported in this browser");
      } else if (err.name === 'InsecureContextError') {
        this.showToastError('Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.');
      } else {
        this.showToastError(`Camera error (${err.name})`);
      }
    } finally {
      this.loadingCamera = false;
    }
  }

  private onError(e: any) {
    this.showToastError(e.message)
    throw e;
  }

  private async setToScanAndStepForward() {
    this.eui = null
    this.activeStep = DeviceWizardSteps.ScanBarcode;
    this.selectedMode = InputModes.QrScan;
  }

  private get searchDisabled() {
    return !(!this.searching && !!this.eui && !this.loadingCamera && !this.loadingScanResult);
  }

  private get sensorDevEui(): string {
    return require('@/assets/setup/sensor/dev_eui.png')
  }

  private get sensorQrCode(): string {
    return require('@/assets/setup/sensor/qr_code.png')
  }

  private get windowHeight() {
    return window.innerHeight;
  }

  private set assetName(name: string) {
    Vue.set(this.newAsset, 'name', name)
  }

  private get assetName() {
    if (this.newAsset.name) {
      return this.newAsset.name;
    } else {
      return '';
    }
  }

  private set assetManufacturer(manufacturer: string) {
    Vue.set(this.newAsset, 'manufacturer', manufacturer)
  }

  private get assetManufacturer() {
    if (this.newAsset.manufacturer) {
      return this.newAsset.manufacturer;
    } else {
      return '';
    }
  }

  private get monitoringPointDisabled() {
    if (this.loadingMonitoringPoint) {
      return true;
    }
    return !(this.monitoringPoint && this.configuration.minTemp && this.configuration.maxTemp) || !this.validTemps;
  }

  private get roomDisabled() {
    if (this.loadingRoom) {
      return true;
    }
    if (this.showRoomCreation) {
      return !this.roomName;
    } else {
      return !this.selectedRoom;
    }
  }

  private get disabled() {
    if (this.loadingAsset) {
      return true;
    }
    if ((this.selectedAssetType === 'freezer' || this.selectedAssetType === 'refrigerator')) {
      if (this.showAssetCreation) {
        return !this.newAsset.name || !this.newAsset.manufacturer;
      } else {
        return !this.asset;
      }
    } else if (this.selectedAssetType === 'room' && !this.asset || !this.newAsset.name || !this.newAsset.manufacturer) {
      if (this.showAssetCreation) {
        return !this.newAsset.name;
      } else {
        return !this.asset;
      }
    } else return !this.selectedAssetType;
  }

  private get supplierList() {
    return this.suppliers.sort()
  }

  private get devEuiRule() {
    return [((v: string): boolean | string => validation.isEUI64(v) || 'DevEUI is invalid')];
  }
}
</script>

<style lang="scss">
.border-alert {
  border: none !important;
  border-radius: 8px !important;
}
#outer-sm {
  height: calc(100vh - 104px);
}
#outer-md {
  height: calc(100vh - 52px);
}
.centerpiece {
  height: 220px;
  width: 220px;
}
.add {
  margin-top: 110px;
  margin-left: 110px;
}
.full-height {
  height: 75vh;
}
.active-asset-card::before {
  opacity: 0.08;
}
.fake-label {
  position: absolute;
  background-color: #ffffff;
  top: -12px;
  z-index: 99;
  margin-left: 12px;
  padding: 0 2px;
}
.fake-label span {
  color: rgba(0,0,0,0.55) !important;
}
.border-alert-button {
  border: thin solid #ffffff !important;
}
</style>
