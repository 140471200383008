import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c(VSelect,{staticClass:"custom-field",class:_vm.customClass,attrs:{"height":_vm.height,"items":_vm.items,"item-text":"name","outlined":"","label":_vm.label,"hint":_vm.hint,"persistent-hint":"","persistent-placeholder":"","disabled":_vm.isDisabled,"clearable":_vm.clearable,"return-object":_vm.returnObject,"hide-details":_vm.hideDetails,"placeholder":_vm.placeholder,"menu-props":{ contentClass: 'menu-background' }},on:{"input":function($event){_vm.selectedValue = $event;},"click:clear":function($event){return _vm.$emit('click:clear')}},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function({item}){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1),(_vm.editable)?_c(VCol,{attrs:{"cols":"3","md":"4"}},[_c(VRow,{attrs:{"align":"start"}},[_c(VCol,{staticClass:"d-flex align-center mt-n8",attrs:{"cols":"auto"}},[(_vm.isDisabled)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isDisabled = !_vm.isDisabled}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1):_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isDisabled = !_vm.isDisabled}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1),_c(VBtn,{attrs:{"icon":"","disabled":_vm.isDisabled},on:{"click":function($event){_vm.input = _vm.value; _vm.isDisabled = true;}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }