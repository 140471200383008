<template>
  <div>
    <v-card v-for="site of sites" :key="site.id" class="mb-8" color="tertiary">
      <v-card-title class="error py-0" v-if="redAlerts[site.id]" style="cursor: pointer" @click="$emit('clicked:alerts', {alerts: redAlerts[site.id], site: site})">
        <v-row justify="space-between" no-gutters>
          <v-col cols="auto" class="d-flex align-center">
            <v-icon color="white" left small>mdi-alert</v-icon>
            <span class="text-caption white--text">Open alerts, click for details.</span>
          </v-col>
          <v-col cols="auto"><v-sheet height="32" color="transparent"></v-sheet></v-col>
        </v-row>
      </v-card-title>
      <v-row justify="start" class="px-4 py-2" no-gutters>
        <v-col cols="auto" align-self="center">
          <v-icon size="22" color="primary" class="ml-xl-2 ml-1 mr-2">mdi-map-marker-outline</v-icon>
        </v-col>
        <v-col cols="auto">
          <div class="d-flex align-center">
            <div class="text-h5 font-weight-bold text-truncate">{{ site.name }}</div>
          </div>
        </v-col>
      </v-row>
      <v-row v-for="room of filteredRooms(site)" :key="room.id" no-gutters class="px-4">
        <v-col cols="12">
          <v-row
              no-gutters
              align="center"
          >
            <v-col cols="12">
              <v-row no-gutters align="center" justify="start">
                <v-col cols="10" class="d-flex align-center">
                  <span class="text-h6 font-weight-bold text-truncate">{{ room.name }}</span>
                </v-col>

                <v-col cols="12">
                  <v-row v-for="(asset) of room.assets" :key="asset.id" no-gutters>
                    <v-col cols="12" v-if="asset.monitoringPoints && asset.monitoringPoints.length && asset.monitoringPoints.some((mp) => mp.device.id)">
                      <v-row no-gutters>
                        <v-col cols="12" v-for="(monitoringPoint, m) of getPositionList(asset.id, site, room)" :key="monitoringPoint.id">
                          <v-row
                              style="cursor: pointer"
                              @click="clickDevice(site, asset, monitoringPoint, room)"
                              no-gutters
                              align="center"
                              class="mb-2 hover-row px-2"
                              :class="{'active-row': monitoringPoint.device.id === activeDevice, 'mb-4': m === getPositionList(asset.id, site, room).length - 1}"
                          >
                            <v-col cols="12">
                              <v-row no-gutters align="center" justify="space-between">
                                <v-col cols="10" class="d-flex align-center">
                                  <v-row no-gutters>
                                    <v-col cols="2" md="auto" class="d-flex align-center justify-center pl-2">
                                      <v-badge left offset-x="10" offset-y="10" :color="getStatusIndicator(monitoringPoint, site).status">
                                        <template v-slot:badge>
                                          <v-icon color="white" size="14" style="margin-top: -3px">{{ getStatusIndicator(monitoringPoint, site).icon}}</v-icon>
                                        </template>
                                        <v-icon color="primary" class="mb-n1">{{ getAssetType(asset.assetType) }}</v-icon>
                                      </v-badge>
                                    </v-col>
                                    <v-col cols="8" class="d-flex py-2">
                                      <div class="text-body-1 ml-2 mt-1 text-break"><span class="font-weight-bold">{{ asset.name }} ›</span> {{ monitoringPoint.name }}</div>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="2" class="d-flex align-center justify-end">
                                  <span class="text-subtitle-1 font-weight-bold" v-if="monitoringPoint.device && monitoringPoint.device.lastEvent && $vuetify.breakpoint.mdAndUp">{{ monitoringPoint.device.lastEvent.temperature }}°C</span>
                                  <span class="text-caption font-weight-bold" v-if="monitoringPoint.device && monitoringPoint.device.lastEvent && $vuetify.breakpoint.smAndDown">{{ monitoringPoint.device.lastEvent.temperature }}°C</span>
                                  <v-icon right>mdi-chevron-right</v-icon>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>
            </v-col>
          </v-row>
        </v-col>


      </v-row>
      <v-row class="px-4 mt-0" justify="space-between">
        <v-col cols="auto">
        <span class="text-body-2 font-weight-regular">
          Current weather conditions
        </span>
        </v-col>
        <v-col cols="auto" class="d-flex align-center" v-if="site.temperature">
          <v-icon small>mdi-{{ site.weatherIcon }}</v-icon>
          <span class="text-body-2 font-weight-regular pl-2">{{ site.temperature ? `${site.temperature.toFixed()}°C` : '' }}</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Asset, Device, DeviceEvent, MonitoringPoint, Room, Site} from "../types";
import {Getter} from "vuex-class";
import SiteService from "@/services/site.service";

@Component
export default class SiteCard extends Vue {
  @Getter private accountId!: number;
  @Prop() private sites!: Site[];
  private activeDevice: number | null = 0;
  private redAlerts: { [key: number]: Array<{ timestamp: Date, alert_type: string, location_id: number }> } = {};

  private mounted() {
    this.getRedAlerts();
  }

  private clickDevice(site: Site, asset: Asset, monitoringPoint: MonitoringPoint, room: Room) {
    this.activeDevice = monitoringPoint.device ? monitoringPoint.device.id : null;
    const siteDetails = { device: monitoringPoint.device ? monitoringPoint.device : null, monitoringPoint: monitoringPoint, asset: asset, site: site, room: room }
    this.$emit('clicked:monitoring-point', siteDetails);
  }

  private goToDeviceManagement(site: Site) {
    this.$router.push({name: 'devices', params: { siteId: site.id.toString() }})
  }

  private getPositionList(assetId: number, site?: Site, room?: Room) {
    return room?.assets.find((a: Asset) => a.id === assetId)?.monitoringPoints.filter((mp) => mp.device.id);
  }

  private getStatusIndicator(monitoringPoint: MonitoringPoint, site: Site) {
    if (!monitoringPoint.device.status_id) {
      return { status: 'warning', icon: 'mdi-alert' };
    }
    if (monitoringPoint.device && monitoringPoint.device.lastEvent && monitoringPoint.device.lastEvent.battery === '0-20%') {
      return { status: 'warning', icon: 'mdi-battery-alert-variant' }
    }
    if (this.redAlerts[site.id] && this.redAlerts[site.id].length && this.redAlerts[site.id].map((a) => a.location_id).includes(monitoringPoint.id)) {
      return { status: 'error', icon: `mdi-exclamation-thick` }
    }
    const colors: {[key: number]: { [key:string]: string }} = {
      2: { status: 'success', icon: `mdi-check` },
      3: { status: 'success', icon: `mdi-check` },
      4: { status: 'error', icon: `mdi-exclamation-thick` }
    };
    return colors[monitoringPoint.device.status_id];
  }

  private getAssetType(type: string) {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[type];
  }

  private async getRedAlerts() {
    for (const site of this.sites.filter((s: Site) => s.rooms?.length)) {
      if (site.rooms) {
        for (const room of site.rooms) {
          const monitoringPointIds: number[] = [];
          for (const asset of room.assets) {
            asset.monitoringPoints.forEach((mp) => monitoringPointIds.push(mp.id));
          }
          let alerts = await SiteService.getAlertsForSite(site.id, monitoringPointIds);
          if (alerts.length) {
            Vue.set(this.redAlerts, site.id, alerts);
          }
        }
      }
    }
  }

  private filteredRooms(site: Site) {
    return site.rooms?.filter((r: Room) => r.assets.some((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length && a.monitoringPoints.some((mp) => mp.device.id)));
  }
}
</script>

<style>
.theme--dark.v-btn--active:hover::before, .theme--dark.v-btn--active::before, .theme--dark.v-btn:hover::before {
  opacity: 0;
}

.hover-row:hover {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.08);
}
.active-row {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.08);
}

.disabled-card * {
  color: rgba(255, 255, 255, 0.50) !important;
}
</style>