<template>
  <div class="fill-height">
    <div id="nav" v-if="$route.matched.some((r) => r.path !== '/404')">
      <v-navigation-drawer
          v-if="!$embedApplication"
          color="tertiary"
          class="elevation-0"
          v-model="drawer"
          app
          :permanent="$vuetify.breakpoint.lgAndUp"
      >
        <v-row class="flex-column justify-space-between" style="height: 100vh">
          <v-col>
            <v-card flat color="transparent">
              <v-card-title v-if="$vuetify.breakpoint.mdAndUp">
                <v-img :src="landscapeLogoSrc" contain max-width="200" height="64" class="mt-n2" @click="$router.push({name: 'home'})"/>
              </v-card-title>
              <v-list
                  :disabled="!accountId"
                  dense
                  nav
                  class="custom-list"
                  :class="{'mt-4': $vuetify.breakpoint.smAndDown}"
              >
                <v-list-item-group color="transparent" active-class="dimmed-primary">
                  <v-list-item link to="/" exact-path v-if="hasPermission('application:read')" :disabled="!accountId">
                    <v-list-item-icon>
                      <v-icon right :disabled="!accountId">mdi-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group color="transparent" active-class="dimmed-primary">
                  <v-list-item link to="/devices" exact-path v-if="hasPermission('application:read')" :disabled="!accountId">
                    <v-list-item-icon>
                      <v-icon right :disabled="!accountId">mdi-chip</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Device management</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group color="transparent" active-class="dimmed-primary">
                  <v-list-item link to="/users" exact-path v-if="hasPermission('application:read')" :disabled="!accountId">
                    <v-list-item-icon>
                      <v-icon right :disabled="!accountId" v-if="hasPermission('application:manage')">mdi-account-group</v-icon>
                      <v-icon right :disabled="!accountId" v-else>mdi-account</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title v-if="hasPermission('application:manage')">User management</v-list-item-title>
                      <v-list-item-title v-else>User profile</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group color="transparent" active-class="dimmed-primary">
                  <v-list-item link to="/records" exact-path v-if="hasPermission('application:read')" :disabled="!accountId">
                    <v-list-item-icon>
                      <v-icon :disabled="!accountId" right>mdi-file-table-box</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Record management</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider class="mx-4" v-if="hasPermission('administration:manage')"></v-divider>
              <v-list
                  dense
                  nav
                  class="custom-list"
                  v-model="activeSubMenu"
                  v-if="hasPermission('administration:manage')"
              >
                <v-list-item @click="clearAccountId" :disabled="!accountId" :ripple="false">
                  <v-list-item-icon>
                    <v-icon :disabled="!accountId" right :color="!!accountId ? 'secondary' : 'primary'">mdi-arrow-left</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :class="{'secondary--text': !!accountId}">Exit account</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item-group>
                  <v-list-item to="/admin/accounts">
                    <v-list-item-icon>
                      <v-icon right>mdi-store</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Customer accounts</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group>
                  <v-list-item to="/admin/users">
                    <v-list-item-icon>
                      <v-icon right>mdi-account-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Super users</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card flat color="transparent">
              <v-card flat color="transparent" class="pa-2 ma-4 text-center" v-if="accountCode && accountCode.length">
                <v-row align="end">
                  <v-col cols="auto" class="pb-0 d-flex align-end">
                    <v-icon right>mdi-pound</v-icon>
                  </v-col>
                  <v-col cols="auto" class="pb-0 d-flex align-end">
                    <span class="text--secondary font-weight-bold">Account Code</span>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <v-list-item-title class="text--secondary">{{accountCode}}</v-list-item-title>
                  </v-col>
                </v-row>
              </v-card>
              <v-list

                  dense
                  class="custom-list px-2"
                  v-model="activeBottomMenu"
              >
                <v-list-item-group color="primary">
                  <v-list-item @click="userLogout" :ripple="false">
                    <v-list-item-icon>
                      <v-icon right>mdi-logout-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Log out</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :to="{name: 'knowledgebase'}">
                    <v-list-item-icon>
                      <v-icon right>mdi-help-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Help</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :ripple="false" :to="{name: 'privacy'}">
                    <v-list-item-icon>
                      <v-icon right>mdi-shield-lock</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>Privacy Policy</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto" class="pa-0 d-flex justify-center" v-if="false">
            <v-switch class="mt-0" @change="setDebugMode({mode: 'new_hierarchy', value: $event})"></v-switch>
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <v-app-bar height="64" app flat color="#ffffff" dense v-if="$vuetify.breakpoint.mdAndDown && !$embedApplication" fixed>
        <v-row no-gutters justify="space-between" align="center" class="my-2">

          <v-col cols="auto">
            <v-icon color="primary" @click.stop="drawer = !drawer"  :size="$vuetify.breakpoint.smAndDown ? 32 : 52">mdi-menu</v-icon>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <router-link to="/"><v-img :src="landscapeLogoSrc" contain max-width="180" height="64" /></router-link>
          </v-col>

          <v-col cols="auto" v-if="$vuetify.breakpoint.smAndUp" >
            <v-sheet :width="$vuetify.breakpoint.smAndDown ? 32 : 52" height="52" color="transparent"></v-sheet>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-app-bar height="64" fixed app flat v-if="$embedApplication">
        <v-tabs background-color="background" color="primary" v-model="activeTab" class="menu-tabs">
          <v-tab to="/">Home</v-tab>
          <v-tab to="/devices"><span v-if="$vuetify.breakpoint.mdAndUp">Device management</span><span v-else>Devices</span></v-tab>
          <v-tab to="/records"><span v-if="$vuetify.breakpoint.mdAndUp">Record management</span><span v-else>Records</span></v-tab>
        </v-tabs>
      </v-app-bar>
    </div>
    <v-main class="fill-height">

      <router-view :key="$route.path" :sites="sites"></router-view>
    </v-main>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import {Action, Getter, Mutation} from "vuex-class";
import SiteService from "@/services/site.service";
import {Asset, MonitoringPoint, Site} from "@/types";
import store from "@/store";
@Component({
  components: {Loader}
})
export default class Layout extends Vue {
  @Action private userLogout!: any;
  @Getter private appLoading!: boolean;
  @Getter private hasPermission!: any;
  @Getter private accountId!: number;
  @Getter private accountCode!: string;
  @Mutation private setDebugMode!: any;
  @Mutation private toggleLoading!: any;
  private activeBottomMenu = 0;
  private activeSubMenu = 0;
  private activeTab = 0;
  private sites: Site[] = [];
  private drawer = false;
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }

  private clearAccountId() {
    store.commit('setAccountId', {accountId: null, accountCode: null});
    store.commit('setAccountManaged', false);
    if (this.$route.name !== 'customer-accounts') {
      this.$router.replace({name: 'customer-accounts'})
    }
  }
}

</script>

<style>

.dimmed-primary {
  background-color: rgba(50, 55, 75, 0.10);
}
.dimmed-primary .v-list-item__content .v-list-item__title,  .dimmed-primary .v-list-item__icon {
  color: #32374b;
}
.custom-list .v-list-item {
  border-radius: 8px !important;
}
.custom-list .theme--light.v-list-item:hover::before {
  border-radius: 8px !important;
}
.custom-list .theme--light.v-list-item--active:hover::before, .custom-list .theme--light.v-list-item--active::before {
  border-radius: 8px !important;
}
.fixed-tabs-bar  {
  position: fixed;
  top: 0;
  z-index: 99;
}
.menu-tabs .v-tab.v-tab, .v-tab:before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.height {
  height: calc(100vh - 64px);
}
</style>