<template>
  <div class="overflow-x-hidden" id="main">
    <div class="pa-4">
      <v-row class="content-container" no-gutters align="center">
        <v-col cols="12" md="6" class="wrapper">
          <v-row>
            <v-col cols="12">
              <p class="font-weight-bold text-h5 text-center">Create a super user</p>
              <p class="font-weight-regular text-body-1 text-center">This user will have access to all customer accounts</p>
            </v-col>
          </v-row>
          <v-form v-model="valid">
            <v-row class="my-2" justify="center">
              <v-col cols="12" md="6">
                <TextField label="First name" @update:value="setValue('firstName', $event)" :rules="rules.first_name"></TextField>
              </v-col>
              <v-col cols="12" md="6">
                <TextField label="Last name" @update:value="setValue('lastName', $event)" :rules="rules.last_name"></TextField>
              </v-col>
              <v-col cols="12">
                <TextField label="Email" type="email" @update:value="setValue('email', $event)" :rules="rules.email"></TextField>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="6">
              <v-alert
                  dense
                  class="text-left"
                  type="error"
                  v-if="error"
                  color="error"
              >
                <span class="white--text">{{ error }}</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mb-16" no-gutters justify="center">
            <v-col cols="auto">
              <ActionButton width="220" height="50" :disabled="!valid || saving" :loading="saving" text="Create user" @click="saveSuperUser"></ActionButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Getter} from "vuex-class";
import AdminService from "@/services/admin.service";

@Component({
  components: {TextField, ActionButton}
})
export default class SuperUserWizard extends Vue {
  @Getter private accountId!: number;
  private userDetails: { firstName: string; lastName: string; email: string; } = {
    firstName: '',
    lastName: '',
    email: '',
  };
  private valid = false;
  private saving = false;
  private error = '';
  private rules = {
    first_name: [
      ((v: string) => !!v || 'First name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    last_name: [
      ((v: string) => !!v || 'Last name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    email: [
      ((v: string) => !!v || 'Email is required'),
      ((v: string) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'),
    ]
  };

  private async saveSuperUser() {
    this.saving = true;

    const payload = {
      first_name: this.userDetails.firstName,
      last_name: this.userDetails.lastName,
      email: (this.userDetails.email).toLowerCase()
    }
    try {
      await AdminService.addSuperUser(payload);
      await this.$router.replace({ name: 'super-users' });
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.saving = false;
    }
  }

  private setValue(obj: string, value: string | number) {
    Vue.set(this.userDetails, obj, value ? value : null);
  }

}
</script>

<style scoped>

</style>