<template>
  <div class="fill-height overflow-x-hidden" :class="$vuetify.breakpoint.smAndDown ? 'background-mob' : 'background'">
    <v-row class="relative z99 fill-height">
      <v-col cols="12" class="absolute centered">
        <v-row justify="start" class="relative">
          <v-col cols="auto" class="absolute">
            <v-img :src="landscapeLogoSrc" contain :max-width="$vuetify.breakpoint.smAndDown ? 200 : 350" class="py-2 mx-2 float-right"/>
          </v-col>
        </v-row>
        <v-stepper v-model="activeStep" flat class="fill-height background" id="login-stepper">
          <v-stepper-items class="fill-height">
            <v-stepper-content :step="ResetSteps.EmailPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <EmailPrompt
                      @check:email="username = $event; checkUser($route.query.username ? $route.query.username : username);"
                      :query-username="$route.query.username ? $route.query.username : username"
                      :error="error"
                      @clear:error="error = ''"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="ResetSteps.ActivationPrompt" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ActivationPrompt
                      :info="info"
                      :username="$route.query.username ? $route.query.username : username"
                      :query-code="$route.query.code ? $route.query.code : null"
                      @success="activeStep = ResetSteps.Success"
                      @go-to:resend="activeStep = ResetSteps.Resend"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="ResetSteps.Resend" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <ResendPrompt @go-to:email="activeStep = ResetSteps.EmailPrompt" :username="$route.query.username ? $route.query.username : username" :activating="false"/>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content :step="ResetSteps.Success" class="fill-height">
              <v-row class="fill-height" justify="center" align="center" no-gutters>
                <v-col cols="12" md="6">
                  <v-row justify="center" align="center">
                    <v-col cols="12" md="5" class="pa-4">
                      <v-card color="tertiary" class="px-4 py-6">
                        <v-row no-gutters align="center" justify="center">
                          <v-col cols="12" class="text-center">
                            <span class="white--text text-h5 font-weight-bold">Your password has been changed.</span>
                          </v-col>
                          <v-col cols="12" class="my-6 text-center">
                            <span class="white--text text-body-1">Please log in to proceed.</span>
                          </v-col>
                          <v-col class="d-flex justify-center">
                            <ActionButton width="95" @click="$router.push({name: 'login', query: {username: username}})" text="Log in" color="secondary"></ActionButton>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import EmailPrompt from "@/views/login/EmailPrompt.vue";
import ActivationPrompt from "@/views/login/ActivationPrompt.vue";
import LoginService from "@/services/login.service";
import ActionButton from "@/components/ActionButton.vue";
import ResendPrompt from "@/views/login/ResendPrompt.vue";

enum ResetSteps {
  EmailPrompt,
  ActivationPrompt,
  Success,
  Resend
}

@Component({
  components: {ResendPrompt, ActionButton, ActivationPrompt, EmailPrompt}
})
export default class ResetFlow extends Vue {
  private username = '';
  private error = '';
  private ResetSteps = ResetSteps;
  private activeStep = ResetSteps.EmailPrompt;
  private info = '';

  private mounted() {
    if (this.$route.params.step === 'ActivationPrompt') {
      this.activeStep = ResetSteps.ActivationPrompt;
      this.info = this.$route.params.info;
    }
  }

  private async checkUser() {
    try {
      const userStatus = await LoginService.getUserStatus(this.username);
      this.activeStep = ResetSteps.ActivationPrompt;
    } catch (err) {
      this.error = (err as Error).message;
    }
  }
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }
}
</script>

<style scoped>
#login-stepper {
  background-color: transparent;
}
#login-stepper .v-stepper__wrapper {
  height: 100% !important;
}
.background {
  background-color: #fafafb;
  background-image: url("~@/assets/login_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.background-mob {
  background-color: #fafafb;
  background-image: url("~@/assets/login_bg_mobile.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>