<template>
  <div>
    <div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mb-2">
          <v-col cols="12">
            <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card :loading="loading" color="tertiary" :class="{'mt-14': $vuetify.breakpoint.mdAndUp}">
        <v-row v-if="loading" justify="center">
          <v-col cols="auto">
            <div class="text-body-2 font-weight-light py-2">Loading user...</div>
          </v-col>
        </v-row>
        <v-row dense class="py-2 px-4" v-if="!loading">
          <v-col cols="12">
            <v-row>
              <v-col>
                <div class="text-h5 font-weight-bold" v-if="!showEditName">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                <div v-else>
                  <v-form v-model="validName" class="d-flex align-center">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <v-text-field hide-details outlined class="input-height custom-field" v-model="userDetails.firstName" placeholder="First name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field hide-details outlined class="input-height custom-field" v-model="userDetails.lastName" placeholder="Last name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-col>
              <v-col cols="auto">
                <v-btn icon color="primary" @click="showEditName = true;" v-if="!showEditName"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon color="primary" @click="showEditName = false; resetName()" v-else><v-icon>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-slide-y-transition>

            <v-col cols="12">
              <div class="text-body-1 font-weight-bold">{{ selectedUser.email }}</div>
            </v-col>
          </v-slide-y-transition>
          <v-col cols="12" class="mt-10">
            <v-row align="center" class="mx-0" dense>
              <v-col cols="12">
                <div class="text-h6 font-weight-bold">
                  Remove user
                </div>
              </v-col>
              <v-col cols="12" class="pb-6 d-flex justify-center justify-md-start">
                <ActionButton :disabled="user && user.id === selectedUser.id" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Remove this user" @click="deleteDialog = true"></ActionButton>
                <v-dialog
                    v-model="deleteDialog"
                    persistent
                    max-width="420"
                    class="rounded-lg"

                >
                  <v-card class="rounded-lg" color="tertiary">
                    <v-card-title class="text-h6 font-weight-bold text--primary">
                      Remove user
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to remove this user?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="text-none"
                          color="primary"
                          plain
                          @click="deleteDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          type="submit"
                          class="text-none"
                          color="secondary"
                          plain
                          :loading="deleteLoading"
                          :disabled="deleteLoading"
                          @click="removeSuperUser(selectedUser.id)"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex align-center justify-center mt-4">
            <ActionButton color="primary" text="Save user" width="220" height="50" @click="saveEditSuperUser" :loading="saving" :disabled="saving || !validName"/>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Getter} from "vuex-class";
import {User} from "@/types";
import AdminService from "@/services/admin.service";

@Component({
  components: {ActionButton}
})
export default class SuperUserDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: any;
  @Prop() private selectedUser!: User;
  private showEditName = false;
  private deleteLoading = false;
  private deleteDialog = false;
  private loading = false;
  private saving = false;
  private validName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };

  private mounted() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.selectedUser.alerts_enabled
    }
  }

  private async saveEditSuperUser() {
    if (this.selectedUser.id) {
      const payload = {
        first_name: this.userDetails.firstName,
        last_name: this.userDetails.lastName,
      }

      await AdminService.updateSuperUser(this.selectedUser.id, payload);

      this.showEditName = false;
      this.$emit('refresh:user', this.selectedUser.id)
      this.saving = false;
    }
  }

  private async removeSuperUser(userId: number) {
    this.deleteLoading = true
    try {
      await AdminService.removeSuperUser(userId)
      this.deleteDialog = false;
    } finally {
      this.$emit('remove:user')
      this.deleteLoading = false;
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    }
  }
}
</script>

<style scoped>

</style>