<template>
  <v-alert
      dense
      class="text-left"
      v-if="error"
      type="error"
      color="error"
  >
    <span class="white--text">{{ error }}</span>
  </v-alert>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class LoginError extends Vue {
  @Prop({default: ''}) private error!: string;
}
</script>

<style scoped>

</style>