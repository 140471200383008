<template>
  <div>
    <div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mb-2">
          <v-col cols="12">
            <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card :loading="loading" color="tertiary" :class="{'mt-14': $vuetify.breakpoint.mdAndUp}">
        <v-row v-if="loading" justify="center">
          <v-col cols="auto">
            <div class="text-body-2 font-weight-light py-2">Loading account...</div>
          </v-col>
        </v-row>
        <v-row dense class="py-2 px-4" v-if="!loading">
          <v-col cols="12">
            <div class="text-h5 font-weight-bold">{{ selectedAccount.name }}</div>
          </v-col>
          <v-slide-y-transition>

            <v-col cols="12">
              <div class="text-body-1 font-weight-bold">{{ selectedAccount.account_code }}</div>
            </v-col>
          </v-slide-y-transition>
          <v-col cols="12" class="mt-10 mb-4">
            <v-row align="center" class="mx-0" dense>
              <v-col cols="12">
                <div class="text-h6 font-weight-bold">
                  Manage account
                </div>
                <p class="text-body-1 my-2">This allows you, as a super user, to assume the role of manager in this account.</p>
                <p class="text-body-1 secondary--text" v-if="accountId === selectedAccount.daizy_project_id">You are currently managing this account.</p>
              </v-col>
              <v-col cols="12" class="d-flex justify-center justify-md-start">
                <ActionButton icon="mdi-account-supervisor" width="220" height="50" color="primary" class="white--text" text="Manage this account" @click="switchAccount" :disabled="!!selectedAccount.suspended_at || accountId === selectedAccount.daizy_project_id"></ActionButton>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row align="center" class="mx-0 mt-6" dense>
              <v-col cols="12">
                <div class="text-h6 font-weight-bold">
                  <span v-if="!selectedAccount.suspended_at">Suspend account</span>
                  <span v-else>Re-enable account</span>
                </div>
                <p class="text-body-1 secondary--text my-2" v-if="selectedAccount.suspended_at">This account was suspended on {{ suspendedDatetime }}.</p>
              </v-col>
              <v-col cols="12" class="pb-6 d-flex justify-center justify-md-start">
                <ActionButton :disabled="accountId === selectedAccount.daizy_project_id" :icon="selectedAccount.suspended_at ? 'mdi-lock-off' : 'mdi-lock'" width="220" height="50" color="secondary" class="white--text" :text="selectedAccount.suspended_at ? 'Re-enable this account' : 'Suspend this account'" @click="suspendDialog = true"></ActionButton>
                <v-dialog
                    v-model="suspendDialog"
                    persistent
                    max-width="420"
                    class="rounded-lg"

                >
                  <v-card class="rounded-lg" color="tertiary">
                    <v-card-title class="text-h6 font-weight-bold text--primary">
                      <span v-if="!selectedAccount.suspended_at">Suspend account</span>
                      <span v-else>Re-enable account</span>
                    </v-card-title>
                    <v-card-text>
                      <p v-if="!selectedAccount.suspended_at">Are you sure you want to suspend this account?</p>
                      <p v-else>Are you sure you want to re-enable this account?</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="text-none"
                          color="primary"
                          plain
                          @click="suspendDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          type="submit"
                          class="text-none"
                          color="secondary"
                          plain
                          :loading="suspendLoading"
                          :disabled="suspendLoading"
                          @click="toggleSuspendAccount"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row align="center" class="mx-0 mt-6" dense>
              <v-col cols="12">
                <div class="text-h6 font-weight-bold">
                  Terminate account
                </div>
              </v-col>
              <v-col cols="12" class="pb-6 d-flex justify-center justify-md-start">
                <ActionButton :disabled="accountId === selectedAccount.daizy_project_id" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Terminate this account" @click="deleteDialog = true"></ActionButton>
                <v-dialog
                    v-model="deleteDialog"
                    persistent
                    max-width="420"
                    class="rounded-lg"

                >
                  <v-card class="rounded-lg" color="tertiary">
                    <v-card-title class="text-h6 font-weight-bold text--primary">
                      Terminate account
                    </v-card-title>
                    <v-card-text>
                      <p>Are you sure you want to terminate this account?</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="text-none"
                          color="primary"
                          plain
                          @click="deleteDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          type="submit"
                          class="text-none"
                          color="secondary"
                          plain
                          :loading="deleteLoading"
                          :disabled="deleteLoading"
                          @click="terminateAccount"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Action, Getter, Mutation} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import store from "@/store";
import AdminService from "@/services/admin.service";
@Component({
  components: {ActionButton}
})
export default class CustomerAccountDetails extends Vue {
  @Action private showToastError!: any;
  @Getter private accountId!: number;
  @Prop() private selectedAccount!: any;
  private suspendLoading = false;
  private deleteLoading = false;
  private suspendDialog = false;
  private deleteDialog = false;
  private loading = false;

  private async switchAccount() {
    store.commit('setAccountId', {accountId: this.selectedAccount.daizy_project_id, accountCode: this.selectedAccount.account_code});
    store.commit('setAccountManaged', true)
  }

  private async toggleSuspendAccount() {
    this.suspendLoading = true;
    try {
      const suspendedDate = this.selectedAccount.suspended_at ? null : new Date();
      await AdminService.toggleSuspendAccount(this.selectedAccount.daizy_project_id, suspendedDate);
      store.commit('setAccountId', {accountId: null, accountCode: null});
      store.commit('setAccountManaged', false);
      if (this.$route.name !== 'customer-accounts') {
        await this.$router.replace({name: 'customer-accounts'})
      }
      this.$emit('refresh:accounts', this.selectedAccount.daizy_project_id);
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.suspendLoading = false;
      this.suspendDialog = false;
    }
  }

  private async terminateAccount() {
    this.deleteLoading = true;
    try {
      await AdminService.terminateAccount(this.selectedAccount.daizy_project_id);
      store.commit('setAccountId', {accountId: null, accountCode: null});
      store.commit('setAccountManaged', false);
      if (this.$route.name !== 'customer-accounts') {
        await this.$router.replace({name: 'customer-accounts'})
      }
      this.$emit('remove:account', this.selectedAccount.daizy_project_id);
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message);
    } finally {
      this.deleteLoading = false;
      this.deleteDialog = false;
    }
  }

  private get suspendedDatetime() {
    return new Date(this.selectedAccount.suspended_at).toLocaleString('en-GB')
  }
}
</script>

<style scoped>

</style>