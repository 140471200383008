<template>
  <v-row align="center">
    <v-col>
      <v-select
          :height="height"
          :items="items"
          item-text="name"
          class="custom-field"
          :class="customClass"
          outlined
          :label="label"
          :hint="hint"
          persistent-hint
          persistent-placeholder
          v-model="input"
          @input="selectedValue = $event;"
          @click:clear="$emit('click:clear')"
          :disabled="isDisabled"
          :clearable="clearable"
          :return-object="returnObject"
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :menu-props="{ contentClass: 'menu-background' }"
      >
        <template v-slot:item="{item}">
          <span class="text-capitalize">{{ item }}</span>
        </template>
        <template v-slot:selection="{item}">
          <span class="text-capitalize">{{ item }}</span>
        </template>
      </v-select>
    </v-col>
    <v-col v-if="editable" cols="3" md="4">
      <v-row align="start">
        <v-col cols="auto" class="d-flex align-center mt-n8">
          <v-btn icon @click="isDisabled = !isDisabled" v-if="isDisabled">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon @click="isDisabled = !isDisabled" v-else>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn icon @click="input = value; isDisabled = true;" :disabled="isDisabled">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Asset} from "@/types";

@Component
export default class Select extends Vue {
  @Prop({default: ''}) private hint!: string;
  @Prop() private label!: string;
  @Prop() private customClass!: string;
  @Prop() private value!: string;
  @Prop() private placeholder!: string;
  @Prop() private editable!: boolean;
  @Prop() private clearable!: boolean;
  @Prop() private returnObject!: boolean;
  @Prop() private height!: string;
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: false}) private hideDetails!: boolean;
  @Prop() private items!: any[];
  private input = '';
  private isDisabled = false;

  private mounted() {
    this.isDisabled = this.disabled;
    this.input = this.value;
  }


  private set selectedValue(val: string) {
    Vue.set(this, 'input', val)
    this.$emit('update:value', val)
  }

  private get selectedValue() {
    return this.input;
  }
}
</script>

<style scoped>

.custom-field.v-text-field{
  border-radius: 8px;
}
</style>