import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{staticClass:"pa-4",attrs:{"cols":"12","md":"10","lg":"6"}},[_c(VCard,{staticClass:"px-4 py-6",attrs:{"color":"tertiary"}},[_c(VRow,{staticClass:"pb-40",attrs:{"justify":"space-around"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text text-h5 font-weight-bold"},[_vm._v("Log in")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('LoginError',{attrs:{"error":_vm.error}})],1)],1),_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('check:email', _vm.username)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"custom-field",attrs:{"autofocus":"","placeholder":"Email address","persistent-placeholder":"","outlined":"","rules":[v => !!v && v.length && v.includes('@') || false],"autocomplete":"username"},on:{"input":function($event){return _vm.$emit('clear:error')}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"relative d-flex justify-center"},[_c('ActionButton',{attrs:{"width":"95","type":"submit","text":"Confirm","color":"secondary","disabled":!_vm.username || !_vm.valid || _vm.loading,"loading":_vm.loading}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }