<template>
  <div class="overflow-x-hidden" id="main">
    <div class="pa-4">
      <v-row class="content-container" no-gutters align="center">
        <v-col cols="12" md="6" class="wrapper">
          <v-row>
            <v-col cols="12">
              <p class="font-weight-bold text-h5 text-center">Create a user</p>
              <p class="font-weight-regular text-body-1 text-center">Provide some basic details for the user you wish to create</p>
            </v-col>
          </v-row>
          <v-form v-model="valid">
            <v-row class="my-2" justify="center">
              <v-col cols="12" md="6">
                <TextField label="First name" @update:value="setValue('firstName', $event)" :rules="rules.first_name"></TextField>
              </v-col>
              <v-col cols="12" md="6">
                <TextField label="Last name" @update:value="setValue('lastName', $event)" :rules="rules.last_name"></TextField>
              </v-col>
              <v-col cols="12">
                <TextField label="Email" type="email" @update:value="setValue('email', $event)" :rules="rules.email"></TextField>
              </v-col>
            </v-row>
          </v-form>

          <v-row justify="center" no-gutters class="mb-6">
            <v-col cols="12" class="mt-6 text-h6 font-weight-bold text-center">Assign a role</v-col>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-row align="center" dense justify="center">
                <v-col cols="12" class="d-flex align-center justify-center">
                  <v-select placeholder="Role" :hide-details="true" class="mt-2 custom-field" outlined :items="roles" v-model="selectedRole" item-text="name" item-value="id" @change="cancelNewSiteRole();"/>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-4 text-h6 font-weight-bold" v-if="isConsumer">
              <p class="font-weight-bold text-h6 text-center">User site access</p>
            </v-col>
            <v-col cols="12" v-if="isConsumer">
              <v-card flat class="mt-2 pa-2" v-for="siteRole of siteRoles" :key="siteRole.site_id" >
                <v-row no-gutters align="center">
                  <v-col>
                    <v-row justify="center" align="center" dense>
                      <v-col cols="auto" class="px-2">
                        <v-icon size="24" color="primary">mdi-map-marker</v-icon>
                      </v-col>
                      <v-col class="text-truncate">
                        {{ sites.find((s) => s.id === siteRole.site_id).name || '' }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="!editingSiteRole[siteRole.site_id] ? 3 : 12" sm="3" class="d-flex align-center justify-center justify-sm-end">
                    <v-btn icon small v-if="!editingSiteRole[siteRole.site_id]" class="mx-md-3 mx-2"><v-icon size="20" color="secondary" @click="removeSiteRoles(siteRole)">mdi-delete</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="!addingSiteRole && isConsumer" class="mb-6">
              <v-card flat class="pa-2" @click="addSiteRole" :ripple="false" min-height="74" height="74">
                <v-row align="center" class="my-auto">
                  <v-col class="d-flex align-center">
                    <v-row justify="center" align="center" dense>
                      <v-col cols="auto" class="d-flex align-center">
                        <v-icon size="32" color="primary">mdi-key-plus</v-icon>
                      </v-col>
                      <v-col class="d-flex justify-start">
                        <span class="font-weight-bold text-body-1">Add site access</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1" class="d-flex align-center justify-center">
                    <v-btn icon small :disabled="!addingSiteRole" class="mx-md-3 mx-2"><v-icon size="20" color="primary" @click="cancelNewSiteRole">mdi-close</v-icon></v-btn>
                  </v-col>
                  <v-col cols="1" class="d-flex align-center justify-center">
                    <v-btn icon small class="mx-md-3 mx-2"><v-icon size="20" color="primary" @click="addSiteAccess">mdi-plus</v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="10" md="10" v-if="addingSiteRole && isConsumer">
              <v-card flat class="pa-2" min-height="74" height="74">
                <v-row justify="start" align="center" dense>
                  <v-col cols="12">
                    <v-select prepend-inner-icon="mdi-map-marker" placeholder="Site" :hide-details="true" class="custom-field" outlined :items="filteredSites" item-value="id" v-model="newSiteRole.site_id">
                      <template v-slot:item="{item}">
                        <div class="text-capitalize text-truncate">
                          <div>{{ item.name }}</div>
                        </div>
                      </template>
                      <template v-slot:selection="{item}">
                        <span class="text-capitalize truncate">{{ item.name }}</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center" v-if="addingSiteRole && isConsumer">
              <v-btn icon small :disabled="!addingSiteRole" class="mx-md-3 mx-2"><v-icon size="20" color="primary" @click="cancelNewSiteRole">mdi-close</v-icon></v-btn>
            </v-col>
            <v-col cols="1" class="d-flex align-center justify-center" v-if="addingSiteRole && isConsumer">
              <v-btn icon small :disabled="!addingSiteRole" class="mx-md-3 mx-2"><v-icon size="20" color="primary" @click="addSiteAccess">mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="6">
              <v-alert
                  dense
                  class="text-left"
                  type="error"
                  v-if="error"
                  color="error"
              >
                <span class="white--text">{{ error }}</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row class="mb-16" no-gutters justify="center">
            <v-col cols="auto">
              <ActionButton width="220" height="50" :disabled="!valid || roleMissing || saving" :loading="saving" text="Create user" @click="saveUser"></ActionButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Role, Site} from "@/types";
import UserService from "@/services/user.service";
import SiteService from "@/services/site.service";
import {Getter} from "vuex-class";

@Component({
  components: {TextField, ActionButton}
})
export default class UserWizard extends Vue {
  @Getter private accountId!: number;
  private addingSiteRole = false;
  private isConsumer = false;
  private newSiteRole: {site_id: number; roles: number[]} = { site_id: 0, roles: [] };
  private userDetails: { firstName: string; lastName: string; email: string; } = {
    firstName: '',
    lastName: '',
    email: '',
  };
  private valid = false;
  private saving = false;
  private error = '';
  private selectedRole = 0;
  private sites: Site[] = [];
  private editingSiteRole: {[key: number]: boolean} = {};
  private roles: Role[] = [];
  private siteRoles: Array<{site_id: number; roles: number[]}> = [];
  private rules = {
    first_name: [
      ((v: string) => !!v || 'First name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    last_name: [
      ((v: string) => !!v || 'Last name is required'),
      ((v: string) => !!v && !!v.match(/^[-\sa-zA-Z]+$/) || 'Invalid name')
    ],
    email: [
      ((v: string) => !!v || 'Email is required'),
      ((v: string) => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'),
    ]
  };

  @Watch('selectedRole')
  private onRoleChange(newVal: number) {
    if (newVal === 1) {
      this.isConsumer = false;
      Vue.set(this, 'siteRoles', [])
    } else {
      this.isConsumer = true;
    }
  }

  private async mounted() {
    this.roles = await UserService.getRoles();
    this.sites = await SiteService.getSites(this.accountId, 1);
  }

  private addSiteAccess() {
    if (this.newSiteRole.site_id) {
      Vue.set(this.newSiteRole, 'roles', [2]);

      this.siteRoles.push(this.newSiteRole)
      this.newSiteRole = { site_id: 0, roles: [] };
    }
  }

  private async saveUser() {
    this.saving = true;
    this.addSiteAccess();

    const payload = {
      user: {
        first_name: this.userDetails.firstName,
        last_name: this.userDetails.lastName,
        alerts_enabled: true,
        email: (this.userDetails.email).toLowerCase()
      },
      roles: this.isConsumer ? null : [1],
      sites: this.isConsumer ? this.siteRoles : [],
    }
    try {
      await UserService.addUser(this.accountId, payload);
      await this.$router.replace({ name: 'users' });
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.saving = false;
    }
  }


  private setValue(obj: string, value: string | number) {
    Vue.set(this.userDetails, obj, value ? value : null);
  }

  private async addSiteRole() {
    this.addingSiteRole = true;
  }

  private cancelNewSiteRole() {
    this.addingSiteRole = false;
    this.newSiteRole = { site_id: 0, roles: [] };
  }

  private removeSiteRoles(role: { site_id: number, roles: number[] }) {
    this.siteRoles = this.siteRoles.filter((sr) => sr.site_id !== role.site_id);
  }

  private get roleMissing() {
    return !this.selectedRole || (!(this.siteRoles.length || !!this.newSiteRole.site_id) && this.isConsumer);
  }

  private get filteredSites() {
    return this.sites.filter((s) => {
      return !this.siteRoles.map((sr) => sr.site_id).includes(s.id)
    });
  }

}
</script>

<style scoped>

</style>