<template>
  <v-snackbar v-model="snackbar" app color="secondary" class="white--text" :timeout="3000" top>
    <v-row align="center">
      <v-col cols="auto">
        <v-icon>{{ icon }}</v-icon>
      </v-col>
      <v-col cols="auto">
        <span class="text-body-1 white--text">{{ text }}</span>
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-icon
          color="white"
          v-bind="attrs"
          @click="closeSnackbar"
      >
        mdi-close
      </v-icon>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import EventBus from "@/services/eventbus.service";

@Component
export default class NotificationSnackbar extends Vue {
  @Prop({default: false}) private open!: boolean;
  @Prop({default: 'mdi-content-save'}) private icon!: string;
  @Prop() private text!: string;
  private snackbar = false;

  @Watch('open', {immediate: true})
  private onSnackbarOpen() {
    this.snackbar = this.open;
  }

  private closeSnackbar() {
    EventBus.$emit('closeSnackbar')
  }
}
</script>

<style scoped>

</style>