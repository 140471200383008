<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container fill-height">
      <v-row no-gutters class="content-container" v-if="sites.length && !appLoading">
        <v-col cols="12">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" lg="4" md="5" class="mt-7">
              <v-row class="mb-4" v-if="!showDeviceDetails && hasPermission('application:manage')">
                <v-col cols="12">
                  <v-card color="transparent">
                    <ActionButton height="40" block class="white--text" text="Launch setup wizard" @click="$router.push({name: 'setup'})"/>
                  </v-card>
                </v-col>
              </v-row>
              <div v-for="site of sites" :key="site.id">
                <SiteDeviceCard :site="site" @clicked:gateway="siteDetails = $event; showGatewayDetails = true; activeGatewayId = $event.gateway.id; activeDeviceId = 0" @clicked:device="activeGatewayId = 0; siteDetails = $event; activeDeviceId = $event.device.id; showGatewayDetails = false;" :active-device-id="activeDeviceId" :active-gateway-id="activeGatewayId" @refresh:sites="fetchData"></SiteDeviceCard>
              </div>
            </v-col>
            <v-col cols="12" lg="8" md="7">
              <div class="sticky-column">
                <GatewayDetails v-if="siteDetails.id && showGatewayDetails" :selected-site="siteDetails" @refresh:site="refreshSite($event)"/>
                <DeviceDetails v-if="siteDetails.site && siteDetails.site.id && activeDeviceId && !showGatewayDetails" :selected-site="siteDetails" :key="siteDetails.device.id" @refresh:site="refreshSite($event)" @refresh:sites="fetchData(); siteDetails = {}"/>
              </div>
            </v-col>
          </v-row>
          <v-row v-else class="mt-4">
            <v-col cols="12" v-if="!showDeviceDetails && !showGatewayDetails && !showSidePanel && hasPermission('application:manage')">
              <v-card color="primary">
                <ActionButton height="40" block class="white--text" text="Launch setup wizard" @click="$router.push({name: 'setup'})"/>
              </v-card>
            </v-col>
            <v-slide-x-reverse-transition hide-on-leave v-if="!showDeviceDetails && !showGatewayDetails && !showSidePanel">
              <v-col cols="12" md="12" lg="5">
                <div v-for="site of sites" :key="site.id">
                  <SiteDeviceCard :site="site" @clicked:gateway="siteDetails = $event; showGatewayDetails = true; activeGatewayId = $event.gateway.id; showSidePanel = true; activeDeviceId = 0" @clicked:device="siteDetails = $event; showSidePanel = true; activeGatewayId = 0; showDeviceDetails = true;" @refresh:sites="fetchData"></SiteDeviceCard>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="8" md="12">
                <GatewayDetails v-if="showSidePanel && siteDetails.id && showGatewayDetails && !showDeviceDetails" :selected-site="siteDetails" @refresh:site="refreshSite($event)" @click:back="showSidePanel = false; showGatewayDetails = false"/>
                <DeviceDetails v-if="showSidePanel && showDeviceDetails && siteDetails.site && siteDetails.site.id && !showGatewayDetails" @click:back="showSidePanel = false; showDeviceDetails = false" :selected-site="siteDetails" :key="siteDetails.device.id" @refresh:site="refreshSite($event)" @refresh:sites="fetchData"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>

      <div v-else-if="!appLoading" class="fill-height">
        <v-row justify="center" align="center" class="fill-height">
          <v-col cols="auto">
            <v-row class="mx-4 no-gutters" justify="center">
              <v-img :src="require('@/assets/goto_wizard.png')" contain max-height="300" />
              <v-col cols="12" class="mt-10">
                <p class="font-weight-bold text-h5 text-center">Head to the setup wizard to get started</p>
              </v-col>
              <v-col cols="auto" class="mt-10">
                <ActionButton text="Launch setup wizard" width="220" height="50" @click="$router.push({ name: 'setup' })"></ActionButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>


</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import SiteDeviceCard from "@/components/SiteDeviceCard.vue";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import DeviceDetails from "@/views/DeviceDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import SiteService from "@/services/site.service";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";
import GatewayDetails from "@/views/GatewayDetails.vue";
@Component({
  components: {GatewayDetails, Loader, ActionButton, DeviceDetails, SiteDeviceCard}
})
export default class DeviceManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private accountId!: number;
  @Getter private appLoading!: boolean;
  @Mutation private toggleLoading!: any;
  private sites: Site[] = [];
  private showSidePanel = false;
  private showDeviceDetails = false;
  private showGatewayDetails = false;
  private siteDetails: { [key: string]: any } = {};
  private activeDeviceId = 0;
  private activeGatewayId = 0;

  private async mounted() {
    await this.fetchData();
  }

  private async fetchData() {
    this.toggleLoading(true);
    try {
      this.activeDeviceId = 0;
      this.activeGatewayId = 0;
      this.showDeviceDetails = false;
      this.showGatewayDetails = false;
      this.showSidePanel = false;

      this.sites = await SiteService.getSites(this.accountId);
      this.siteDetails = {};
    } finally {
      this.toggleLoading(false);
    }
  }

  private async refreshSite(details: { [key: string]: Site | Room | Asset | MonitoringPoint }) {
    this.siteDetails = {};
    this.activeDeviceId = 0;
    this.activeGatewayId = 0;
    this.showDeviceDetails = false;
    this.showGatewayDetails = false;
    this.showSidePanel = false;

    if (!details.site) {
      return;
    }
    const index = this.sites.findIndex((s) => s.id === details.site.id);
    const site = await SiteService.getSites(this.accountId, undefined, details.site.id);
    this.sites.splice(index, 1, site[0]);

    if (details.device && details.device.id) {
      Vue.set(this.siteDetails, 'site', site[0]);
      Vue.set(this.siteDetails, 'device', details.device);
      Vue.set(this.siteDetails, 'room', this.siteDetails.site.rooms.find((r: Room) => r.id === details.room.id));
      Vue.set(this.siteDetails, 'asset', this.siteDetails.room.assets.find((a: Asset) => a.id === details.asset.id));
      Vue.set(this.siteDetails, 'monitoringPoint', this.siteDetails.room.assets.find((a: Asset) => a.id === details.asset.id)?.monitoringPoints.find((m: MonitoringPoint) => m.id === details.monitoringPoint.id))

      this.showSidePanel = true;
      this.activeDeviceId = details.device.id;
    }
  }
}
</script>

<style>
.sticky-column {
  position: sticky;
  top: 93px;
}
</style>