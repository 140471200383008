<template>
  <v-row align="center">
    <v-col>
      <v-text-field
          class="custom-field"
          outlined
          :label="label"
          :hint="hint"
          persistent-hint
          persistent-placeholder
          v-model="input"
          @input="$emit('update:value', input)"
          @change="$emit('change')"
          :disabled="isDisabled"
          :rules="rules"
          :type="type"
          :hide-details="hideDetails"
      >
        <template v-slot:append>
          <span class="text-body-1" v-if="append">{{ append }}</span>
          <v-icon v-else-if="appendIcon">{{ appendIcon }}</v-icon>
        </template>
      </v-text-field>
    </v-col>
    <v-col v-if="editable" cols="3" md="4">
      <v-row align="start">
        <v-col cols="auto" class="d-flex align-center mt-n8">
          <v-btn icon @click="isDisabled = !isDisabled" v-if="isDisabled">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon @click="isDisabled = !isDisabled" v-else>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn icon @click="input = value; isDisabled = true;" :disabled="isDisabled">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class TextField extends Vue {
  @Prop() private hint!: string;
  @Prop() private label!: string;
  @Prop() private value!: string | number;
  @Prop() private type!: string;
  @Prop() private append!: string;
  @Prop() private appendIcon!: string;
  @Prop() private editable!: boolean;
  @Prop({default: false}) private hideDetails!: boolean;
  @Prop() private rules!: any;
  @Prop({default: false}) private disabled!: boolean;
  private input = '';
  private isDisabled = false;

  private mounted() {
    this.isDisabled = this.disabled;
  }
  @Watch('value', { immediate: true })
  private onValueChange(newVal: string) {
    this.input = newVal;
  }


}
</script>

<style>
.custom-field.v-text-field{
  border-radius: 8px;
}
</style>