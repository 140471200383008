<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card :loading="loading" color="tertiary" :class="{'mt-7': $vuetify.breakpoint.mdAndUp}" width="100%">
      <v-row v-if="loading" justify="center" >
        <v-col cols="auto">
          <div class="text-body-2 font-weight-light py-2">Loading sensor...</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2 px-4" v-if="!loading">
        <v-col cols="12">
          <v-row no-gutters>
            <v-col cols="auto">
              <v-row no-gutters align="center">
                <v-col align-self="center" cols="12">
                  <span class="text-h5 font-weight-regular">{{ asset.name }}</span>
                  <v-dialog
                      v-if="hasPermission('application:manage')"
                      v-model="assetDialog"
                      persistent
                      max-width="420"
                      class="rounded-lg"

                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-on="on" v-bind="attrs" class="ml-2 mb-2"><v-icon color="primary">mdi-pencil</v-icon></v-btn>
                    </template>
                    <v-card class="rounded-lg" color="tertiary">
                      <v-form @submit.prevent="saveNames" v-model="valid">
                        <v-card-title class="text-h6 font-weight-bold text--primary">
                          Edit unit name
                        </v-card-title>
                        <v-card-text class="mt-4">
                          <TextField class="mb-n8" v-if="asset.name" label="Unit name" :rules="[v => !!v && v.length > 1 || false]" :value="asset.name" @update:value="names.asset = $event" @change="setValue(Number(asset.id), names.asset, false)" :append-icon="assetTypes[asset.assetType].icon"/>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="text-none"
                              color="secondary"
                              text
                              @click="assetDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              type="submit"
                              class="text-none"
                              color="primary"
                              text
                              :loading="saveLoading"
                              :disabled="saveLoading || !valid"
                          >
                            Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" align-self="center">
                  <span class="text-body-1 font-weight-bold">{{ site.name }}</span>
                  <span v-if="room"><v-icon color="primary" size="20">mdi-slash-forward</v-icon><span class="text-body-1 font-weight-bold">{{ room.name }}</span>
                    <v-dialog
                        v-if="hasPermission('application:manage')"
                        v-model="roomDialog"
                        persistent
                        max-width="420"
                        class="rounded-lg"

                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" small icon color="primary" class="ml-2"><v-icon color="primary" small>mdi-pencil</v-icon></v-btn>
                    </template>
                    <v-card class="rounded-lg" color="tertiary">
                      <v-form @submit.prevent="saveNames" v-model="valid">
                        <v-card-title class="text-h6 font-weight-bold text--primary">
                          Edit room name
                        </v-card-title>
                        <v-card-text class="mt-4">
                          <TextField class="mb-n8" v-if="asset.name" label="Room name" :rules="[v => !!v && v.length > 1 || false]" :value="room.name" @update:value="names.room = $event" @change="setValue(Number(room.id), names.room, false)"/>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="text-none"
                              color="secondary"
                              text
                              @click="roomDialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              type="submit"
                              class="text-none"
                              color="primary"
                              text
                              :loading="saveLoading"
                              :disabled="saveLoading || !valid"
                          >
                            Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="5" class="d-flex justify-center">
          <v-img :src="device.device_version ? $imageUrl(device.device_version.image) : ''" contain max-width="200" class="my-4"/>
        </v-col>
        <v-col cols="12" lg="7">
          <v-row :dense="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" class="text-center font-weight-bold">
              {{ device && device.device_version ? device.device_version.name : '' }}
            </v-col>
          </v-row>
          <v-row :dense="$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
              <v-row no-gutters justify="space-between" align="center">
                <v-col cols="3">DevEUI</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light">{{ device.network_id }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters justify="space-between" align="center">
                <v-col cols="3">Device ID</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light align-center text-right">
                  {{ device.device_id }}

                  <v-dialog
                      v-if="hasPermission('application:manage')"
                      v-model="deviceIdDialog"
                      persistent
                      max-width="420"
                      class="rounded-lg"

                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs"  class="ml-2" icon small right color="primary"><v-icon small>mdi-pencil</v-icon></v-btn>
                    </template>
                    <v-card class="rounded-lg" color="tertiary">
                      <v-form @submit.prevent="saveNames" v-model="valid">
                        <v-card-title class="text-h6 font-weight-bold text--primary">
                          Edit Device ID
                        </v-card-title>
                        <v-card-text class="mt-4">
                          <TextField class="mb-n8" v-if="device.device_id && deviceIdDialog" label="Device ID" :rules="[v => !!v && v.length > 1 || false]" :value="device.device_id" @update:value="names.device_id = $event" @change="setValue(Number(device.id), names.device_id, false, true)"/>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                              class="text-none"
                              color="secondary"
                              text
                              @click="deviceIdDialog = false; "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              type="submit"
                              class="text-none"
                              color="primary"
                              text
                              :loading="saveLoading"
                              :disabled="saveLoading || !valid"
                          >
                            Confirm
                          </v-btn>
                        </v-card-actions>
                      </v-form>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row :dense="$vuetify.breakpoint.smAndDown" v-if="device && device.lastEvent && device.lastEvent.timestamp">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Last seen</v-col>
                <v-col cols="6" class="d-flex justify-end text-right font-weight-light">{{ new Date(device.lastEvent.timestamp).toLocaleString('en-GB', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row :dense="$vuetify.breakpoint.smAndDown" v-if="device && device.lastEvent && device.lastEvent.temperature">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Current temperature</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light">{{ device && device.lastEvent.temperature }}°C <v-icon right size="20">mdi-thermometer</v-icon></v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Current humidity</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light">{{ device && device.lastEvent.humidity }}% <v-icon right size="20">mdi-water</v-icon></v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Status</v-col>
                <v-col cols="6" class="d-flex justify-end text-capitalize font-weight-light"> {{ device.status.name !== 'inactive' ? device.status.name : 'OK' }}
                  <v-icon right size="20" :color="getStatusIndicator(device ? device : null).status">
                    {{ getStatusIndicator(device ? device : null).icon }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Battery</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light">{{ device && device.lastEvent.battery }} <v-icon right size="20">mdi-battery</v-icon></v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else justify="center">
            <v-alert type="info" color="primary" outlined class="border-alert text-body-1 font-weight-bold">
              This device has no current data
            </v-alert>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row class="mt-4">
            <v-col cols="12">
              <v-form v-model="validTemps" ref="tempForm">
                <v-row>
                  <v-col cols="12">
                    <div class="text-h6 font-weight-bold">
                      Sensor configuration
                    </div>
                  </v-col>
                  <v-col cols="12" md="6" class="px-4">
                    <v-text-field
                        class="custom-field"
                        outlined
                        persistent-hint
                        persistent-placeholder
                        :disabled="!hasPermission('application:manage')"
                        :rules="[(v => !!v || 'This value is required'), (v => v < Number(configuration.maxTemp) || `Must be lower than maximum threshold`)]"
                        label="Minimum temperature threshold"
                        type="number"
                        hint="Temperatures below this threshold will generate an alert"
                        v-model="configuration.minTemp">
                      <template v-slot:append>
                        <v-row no-gutters align="center">
                          <v-col class="mt-1">
                            °C
                          </v-col>
                        </v-row>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-4">
                    <v-text-field
                        class="custom-field"
                        append="°C"
                        outlined
                        persistent-hint
                        persistent-placeholder
                        :disabled="!hasPermission('application:manage')"
                        :rules="[(v => !!v || 'This value is required'), (v => ((v > Number(configuration.minTemp))) || 'Must be greater than minimum threshold')]"
                        label="Maximum temperature threshold"
                        type="number"
                        hint="Temperatures above this threshold will generate an alert"
                        v-model="configuration.maxTemp">
                      <template v-slot:append>
                        <v-row no-gutters align="center">
                          <v-col class="mt-1">
                            °C
                          </v-col>
                        </v-row>
                      </template>
                    </v-text-field>

                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12">
              <v-alert flat color="primary" outlined type="info" class="mb-0 border-alert">
                <p v-if="asset.assetType === 'refrigerator'" class="mb-0  font-italic text-body-2 font-weight-light">
                  The Food Standards Agency (UK)  advises to keep chilled foods between 1- 4°C (or below 5°C) to ensure it remains outside the “danger zone” 8°C to 63°C. The lower the temperature you set your unit, the more energy it will use. Meat and fish fridges operate between -1°C to +1°C
                </p>
                <p v-else-if="asset.assetType === 'freezer'" class="mb-0  font-italic text-body-2 font-weight-light">
                  The Food Standard Agency (UK) advises that freezers should be held at -18°C. The lower you set the temperature of the freezer, the more energy the unit will consume. Some products are held at higher temperatures, such as ice cream, which can be kept at around -12°C.                      </p>
                <p v-else class="mb-0 font-italic text-body-2 font-weight-light">
                  Commercial refrigeration will work to maximum ambient temperatures, (usually either 32°C, 38°C or 43°C) this is dependent on the make and model of the unit. Likewise, they usually have a minimum working ambient temperature of around 18°C however some will operate as low as 10°C. If you operate a unit outside these temperatures, it is likely to stop working. If you can return the ambient room temperature back to within the parameters, the unit is likely to start functioning again without the need for an engineer call outs. Call outs for engineers that attend a site where the room temperature is outside its working ambient temperature would usually not be covered by the manufacturer and would therefore be deemed chargeable.
                </p>
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters class="mt-2">
            <v-col cols="12" md="6">
              <v-row class="mx-0 mb-4">
                <v-col cols="12">
                  <v-row justify="center">
                    <v-col cols="12">
                      <span class="font-weight-bold">Alert threshold</span>
                    </v-col>
                    <v-col cols="12">
                      <v-chip-group mandatory active-class="secondary" column v-model="selectedAlertThreshold">
                        <v-chip
                            :disabled="!hasPermission('application:manage')"
                            v-for="duration of alertThresholds"
                            :key="duration.value"
                            :value="duration.value"
                        >
                          {{ duration.text }}
                        </v-chip>
                      </v-chip-group>
                    </v-col>
                    <v-col cols="12">
                      <span class="text-caption">This is the delay before an alert is sent</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pl-4">
                  <ActionButton icon="mdi-content-save" width="220" height="50" color="secondary" @click="saveConfiguration" :loading="saveLoading" :disabled="!validTemps || saveLoading || !hasPermission('application:manage')" class="white--text" text="Save configuration"></ActionButton>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="pl-4 pr-2">
              <v-row no-gutters justify="center" class="fill-height">
                <v-col cols="12">
                  <v-card height="30" flat color="transparent">
                    <span class="font-weight-bold">Notes</span>
                  </v-card>
                </v-col>
                <v-col cols="12" style="height: calc(100% - 30px)" class="d-flex flex-column justify-space-between">
                  <v-row>
                    <v-col v-if="!editingNotes" cols="12">
                      <v-card color="tertiary" flat outlined class="overflow-y-auto text-wrap px-2 py-1" min-height="150" style="border: thin solid #828282 !important" max-width="690">
                        <pre class="text-body-2" v-if="device.position && device.position.notes && device.position.notes.length">{{ device.position.notes }}</pre>
                        <div v-else class="text--disabled">There are no notes for this sensor.</div>
                      </v-card>
                    </v-col>
                    <v-col v-else>
                      <v-textarea no-resize flat outlined hide-details v-model="device.position.notes" class="text-body-2 notes-input" height="150" style="line-height: 12px; border-radius: 8px !important;"></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row align="end" class="mt-0">
                    <v-col>
                      <ActionButton v-if="!editingNotes" @click="backupNotes(); editingNotes = true;" :disabled="!hasPermission('application:manage')" icon="mdi-pencil" width="120" height="30" color="secondary" class="white--text" text="Edit"></ActionButton>
                      <div v-else class=" d-flex justify-space-between">
                        <ActionButton @click="editingNotes = false; device.position.notes = notes" :disabled="!hasPermission('application:manage')" icon="mdi-pencil" width="120" height="30" color="secondary" class="white--text" text="Cancel"></ActionButton>
                        <ActionButton @click="saveNotes(); editingNotes = false;" :disabled="!hasPermission('application:manage')" icon="mdi-content-save" width="120" height="30" color="secondary" class="white--text" text="Save"></ActionButton>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="my-4">
          <v-row align="center" class="mx-0">
            <v-col cols="12">
              <div class="text-h6 font-weight-bold mx-n2">
                Remove sensor
              </div>
            </v-col>
            <v-col cols="6" class="pl-4">
              <ActionButton :disabled="!hasPermission('application:manage')" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Remove this sensor" @click="deleteDialog = true"></ActionButton>
              <v-dialog
                  v-model="deleteDialog"
                  persistent
                  max-width="420"
                  class="rounded-lg"

              >
                <v-card class="rounded-lg" color="tertiary">
                  <v-card-title class="text-h6 font-weight-bold text--primary">
                    Remove sensor
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to remove this sensor?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-none"
                        color="primary"
                        plain
                        @click="deleteDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        type="submit"
                        class="text-none"
                        color="secondary"
                        plain
                        :loading="deleteLoading"
                        :disabled="deleteLoading"
                        @click="removeDevice(device.id)"
                    >
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <template slot="progress">
        <v-progress-linear
            color="secondary"
            height="5"
            indeterminate
        ></v-progress-linear>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DeviceService from "@/services/device.service";
import AppDatasetService from "@/services/app.dataset.service";
import {Asset, Device, DeviceEvent, MonitoringPoint, Room, Site} from "@/types";
import {Action, Getter} from "vuex-class";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import ActionButton from "@/components/ActionButton.vue";
import SiteService from "@/services/site.service";
@Component({
  components: {ActionButton, Select, TextField}
})
export default class DeviceDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Action private showToastError: any;
  @Prop() private selectedSite!: { [key: string]: any };
  @Prop() private selectedRoom!: Room;
  private site: Site = {} as Site;
  private device: { [key: string]: any } = { id: 0, name: '', status: 'ok', deviceId: '', assetId: 0, gatewayId: 0, siteId: 0, lastEvent: {} as DeviceEvent };
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0 }, monitoringPoints: [] };
  private monitoringPoint: MonitoringPoint = {} as MonitoringPoint;
  private room: Room | null = null;
  private validTemps = false;
  private loading = false;
  private valid = false;
  private editingNotes = false;
  private assetDialog = false;
  private deviceIdDialog = false;
  private roomDialog = false;
  private deleteLoading = false;
  private deleteDialog = false;
  private saveLoading = false;
  private selectedAlertThreshold = 15;
  private notes = '';
  private configuration: any = {};
  private isDirty: {[key: string]: {
      is_device: boolean;
      name: string,
      is_monitoring_point: boolean }} = {}
  private names: {[key: string]: string} = {}
  private assetTypes: { [key: string]: { icon: string } } = {
    refrigerator: { icon: 'mdi-fridge-outline' },
    freezer: { icon: 'mdi-snowflake' },
    room: { icon: 'mdi-floor-plan' },
  };
  private alertThresholds: Array<{ text: string, value: number }> = [
    { text: '0 min', value: 0 },
    { text: '15 min', value: 15 },
    { text: '30 min', value: 30 },
    { text: '1 hr', value: 60 },
    { text: '2 hr', value: 120 }
  ]

  @Watch('configuration.minTemp')
  @Watch('configuration.maxTemp')
  private async onConfigChange() {
    await this.$nextTick();
    if (this.$refs && this.$refs.tempForm) {
      (this.$refs.tempForm as any).validate();
    }
  }

  @Watch('selectedSite')
  private async onSiteSelect() {
    await this.fetchData();
  }

  private async mounted() {
    await this.fetchData();
  }

  private async fetchData() {
    this.loading = true;
    try {
      this.site = this.selectedSite.site;
      const device = await DeviceService.getDevice(Number(this.selectedSite.device.id));
      if (device?.id) {
        Vue.set(this, 'device', device);
        Vue.set(this, 'asset', this.selectedSite.asset);
        Vue.set(this, 'monitoringPoint', this.selectedSite.monitoringPoint)
        Vue.set(this, 'room', this.selectedSite.room);

        this.configuration = {
          minTemp: this.monitoringPoint?.attributes.find((a) => a.key === 'minTemp')?.value,
          maxTemp: this.monitoringPoint?.attributes.find((a) => a.key === 'maxTemp')?.value,
        };
        this.selectedAlertThreshold = Number(this.monitoringPoint?.attributes.find((a) => a.key === 'alertThreshold')?.value);
      }
    } finally {
      this.loading = false;
    }
  }

  private setValue(obj: string | number, value: string | number, isMonitoringPoint?: boolean, isDevice?: boolean) {
    if (typeof obj !== 'string') {
      Vue.set(this.isDirty, obj, {name: value, is_site: false, is_monitoring_point: isMonitoringPoint, is_device: isDevice});
    } else {
      Vue.set(this.configuration, obj, value);
    }
  }

  private async saveConfiguration() {
    this.saveLoading = true;

    try {
      await SiteService.updateSite(this.monitoringPoint.id, {
        attributes: [
          {key: 'minTemp', value: this.configuration.minTemp},
          {key: 'maxTemp', value: this.configuration.maxTemp},
          {key: 'alertThreshold', value: this.selectedAlertThreshold},
        ]
      })
      this.$emit('refresh:site', {site: this.site, asset: this.asset, monitoringPoint: this.monitoringPoint, device: this.device, room: this.room})
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }

  private async removeDevice(deviceId: string | number | DeviceEvent) {
    this.deleteLoading = true;

    try {
      await DeviceService.removeDevice(Number(deviceId));
      this.room?.assets.forEach((a) => {
        const foundIndex = a.monitoringPoints.findIndex((mp) => mp.id === this.monitoringPoint.id);
        if (foundIndex > -1) {
          a.monitoringPoints.splice(foundIndex, 1);
        }
      })
    } finally {
      this.$emit('refresh:site', {})
      this.deleteDialog = false;
      this.deleteLoading = false;
    }
  }


  private backupNotes() {
    this.notes = typeof this.device.position.notes === 'string' ? this.device.position.notes : null;
  }

  private getStatusIndicator(device: Device) {
    if (device && device.status_id) {
      const colors: { [key: number]: { [key: string]: string } } = {
        2: {status: 'success', icon: 'mdi-check-circle'},
        3: {status: 'success', icon: 'mdi-check-circle'},
        4: {status: 'error', icon: 'mdi-alert'}
      };
      return colors[device.status_id];
    } else {
      return {status: 'warning', icon: 'mdi-alert'};
    }
  }

  private async saveNotes() {
    await DeviceService.setPositionNotes(this.device.position_id, this.device.position.notes);
  }

  private async saveNames() {
    this.saveLoading = true;
    try {
      for (const id in this.isDirty) {
        if (this.isDirty[id]) {
          if (this.isDirty[id].is_device) {
            await DeviceService.updateDevice(Number(id), {
              device_id: this.isDirty[id].name
            })
          } else {
            await SiteService.updateSite(Number(id), {
              name: this.isDirty[id].name,
              is_site: false,
              is_monitoring_point: this.isDirty[id].is_monitoring_point,
            })
          }
        }
      }
      this.assetDialog = false;
      this.roomDialog = false;
      this.deviceIdDialog = false;
      this.isDirty = {};

      this.$emit('refresh:site', {site: this.site, asset: this.asset, monitoringPoint: this.monitoringPoint, device: this.device, room: this.room})
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.saveLoading = false;
    }

  }
}
</script>

<style>
.notes-input * {
  margin-top: 0 !important;
  font-size: 15px;
}

</style>