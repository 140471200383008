<template>
<div>
  <v-row justify="center">
    <v-col cols="12" class="px-4">
      <v-row justify="center">
        <v-col cols="10" md="6"  class="pa-2">
          <vuetify-google-autocomplete
              class="custom-field"
              id="map"
              types=""
              placeholder="Start typing"
              outlined
              @placechanged="searchLocation"
              persistent-hint
              hint="Name of business or address"
              v-model="address"
              @input="address.length || $emit('select:site', {address, coords})"
          >
          </vuetify-google-autocomplete>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="10" md="6" class="pa-2">
          <v-card outlined :height="$vuetify.breakpoint.mdAndDown ? 350 : 450">
            <GmapMap
                v-if="loaded"
                class="gmap"
                ref="mapRef"
                :center="center"
                :zoom="12"
                :style="`width: ${width}; height: ${height}`"
                @click="setPlace"
            >
              <GmapMarker
                  :position="marker.position"
                  :clickable="true"
                  :draggable="true"
              />
            </GmapMap>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

  </v-row>



</div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {gmapApi} from  'vue2-google-maps';

@Component
export default class GoogleMap extends Vue {
  @Prop() private site!: any;
  private loaded = false;
  private place = {
    geometry: {
      location: {}
    }
  };
  private address = '';
  private coords: number[] = [];
  private marker: any = {};
  private width = '100%';
  private height = '100%';
  private center = {lat: 51.5072, lng: 0.1276};
  private geocoder: any = {};

  private async mounted() {
    const google: any = await this.$gmapApiPromiseLazy();
    await this.$gmapApiPromiseLazy();

    this.geolocate();
    this.geocoder = new google.maps.Geocoder();
    this.loaded = true;
  }

  $gmapApiPromiseLazy() {
    throw new Error("Method not implemented.");
  }

  private geolocate() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.marker.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      this.setCenter();
    });
  }

  private async searchLocation(place: any /*google.maps.Place*/) {
    this.geocoder.geocode({ placeId: place.place_id }, (results: any, status: string) => {
      this.address = results[0].formatted_address;
      this.coords = [place.latitude, place.longitude]
      this.marker = {
        position: {
          lat: place.latitude,
          lng: place.longitude,
        }
      }

      this.center = this.marker.position;
      this.$emit('select:site', {address: this.address, coords: this.coords})
    });
  }


  private setCenter() {
    Vue.set(this, 'center', this.marker.position)
  }

  private setPlace(place: any) {
    if (place) {
      this.marker = {
        position: {
          lat: place.latLng ? place.latLng.lat() : place.latitude,
          lng: place.latLng ? place.latLng.lng() : place.longitude,
        }
      }
      this.geocoder.geocode({ location: this.marker.position }, (results: any, status: string) => {
        this.address = results[0].formatted_address;
        this.coords = [this.marker.position.lat, this.marker.position.lng];
        this.$emit('select:site', {address: this.address, coords: this.coords})
      });
    }
    this.setCenter()
  }
}
</script>

<style>
.vue-map-container {
  position: absolute !important;
  bottom: 0;
}
.gmap {
  ;
}
</style>