<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card :loading="loading" color="tertiary" :class="{'mt-7': $vuetify.breakpoint.mdAndUp}" width="100%">
      <v-row v-if="loading" justify="center" >
        <v-col cols="auto">
          <div class="text-body-2 font-weight-light py-2">Loading gateway...</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2 px-4" v-if="!loading">
        <v-col cols="12">
          <span class="text-h5">{{ selectedSite.name }}</span>
        </v-col>
        <v-col cols="12">
          <div class="text-body-1 font-weight-bold">{{ selectedSite.gateway.eui }}</div>
        </v-col>
        <v-col cols="12" lg="5" class="d-flex justify-center">
          <v-img :src="gatewayImg" contain max-width="200" class="my-4"/>
        </v-col>
        <v-col cols="12" lg="7" align-self="center">
          <v-row :dense="$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">DevEUI</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light">{{ selectedSite.gateway.eui }}</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row :dense="$vuetify.breakpoint.smAndDown">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="6">Status</v-col>
                <v-col cols="6" class="d-flex justify-end font-weight-light text-capitalize">{{ selectedSite.gateway.status.name !== 'fault' ? selectedSite.gateway.status.name : 'offline'}}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row align="center" class="mx-0 mt-6">
            <v-col cols="12">
              <div class="text-h6 font-weight-bold mx-n2">
                Remove gateway
              </div>
            </v-col>
            <v-col cols="6" class="pb-6 pl-4">
              <ActionButton :disabled="!hasPermission('application:manage')" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Remove this gateway" @click="deleteDialog = true"></ActionButton>
              <v-dialog
                  v-model="deleteDialog"
                  persistent
                  max-width="420"
                  class="rounded-lg"

              >
                <v-card class="rounded-lg" color="tertiary">
                  <v-card-title class="text-h6 font-weight-bold text--primary">
                    Remove gateway
                  </v-card-title>
                  <v-card-text>
                    Are you sure you want to remove this gateway?
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-none"
                        color="primary"
                        plain
                        @click="deleteDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        type="submit"
                        class="text-none"
                        color="secondary"
                        plain
                        :loading="deleteLoading"
                        :disabled="deleteLoading"
                        @click="removeGateway(selectedSite.gateway.id)"
                    >
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <template slot="progress">
        <v-progress-linear
            color="secondary"
            height="5"
            indeterminate
        ></v-progress-linear>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Getter} from "vuex-class";
import TextField from "@/components/TextField.vue";
import Select from "@/components/Select.vue";
import ActionButton from "@/components/ActionButton.vue";
import GatewayService from "@/services/gateway.service";
@Component({
  components: {ActionButton, Select, TextField}
})
export default class GatewayDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Prop() private selectedSite!: { [key: string]: any };
  private loading = false;
  private deleteLoading = false;
  private deleteDialog = false;

  private async removeGateway(gatewayId: number) {
    this.deleteLoading = true;
    try {
      await GatewayService.unmountGateway(Number(gatewayId), Number(this.selectedSite.id));
    } finally {
      this.$emit('refresh:site', { site: this.selectedSite });
      this.deleteDialog = false;
      this.deleteLoading = false;
    }
  }

  private get gatewayImg() {
    return require('@/assets/laird_gateway.png')
  }


}
</script>

<style>

</style>