<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}" class="overflow-y-hidden">
    <Loader />
    <iframe frameBorder="0" v-show="!appLoading" @load="iframeLoaded" width="100%" height="100%" style="border: 0" :src="getKBURL()"></iframe>
<!--    <v-row v-else justify="center" no-gutters align="center">-->
<!--      <v-col cols="auto">-->
<!--        <p class="font-weight-bold text-h5 text-center">Knowledge base</p>-->
<!--        <p class="font-weight-regular text-body-1 text-center">Content for this feature will be added soon.</p>-->
<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";
import { getStage } from "../util";
@Component({
  components: {Loader}
})
export default class KnowledgeBase extends Vue {
  @Getter private appLoading!: boolean;
  @Mutation private toggleLoading!: any;

  private async mounted() {
    if (!Vue.prototype.$embedApplication) {
      this.toggleLoading(true);
    }
  }

  private iframeLoaded() {
    this.toggleLoading(false);
  }

  private getKBURL() {
    const stage = getStage();
    switch (stage) {
      case 'prod':
        return 'https://bellnewprod.service-now.com/uke_kb';
      default:
        return 'https://bellnewdev.service-now.com/uke_kb';
    }
  }

}
</script>

<style scoped>

</style>