<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card :loading="loading" color="tertiary">
      <v-row v-if="loading" justify="center">
        <v-col cols="auto">
          <div class="text-body-2 font-weight-light py-2">Loading location...</div>
        </v-col>
      </v-row>
      <v-row no-gutters class="pa-2" v-if="!loading">
        <v-col cols="12">
          <v-row justify="space-between" class="px-2">
            <v-col cols="auto">
              <span class="text-h5 font-weight-bold">{{ selectedAsset.asset.name }}</span>
            </v-col>
            <v-col cols="auto">
              <v-icon large>{{ assetType }}</v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-between">
            <v-col cols="auto" class="pl-2 pb-2">
              <span class="text-body-1 font-weight-bold">{{ selectedAsset.site.name }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="px-6 pb-6" justify="center" dense>
        <v-col cols="12" class="mb-2">
          <span class="text-body-2 font-weight-bold">Temperature thresholds</span>
        </v-col>
        <v-col cols="12" md="6" class="">
          <TextField label="Minimum temperature °C" :value="selectedAsset.asset.configuration.minTemp" :editable="true" :disabled="true"
                     :hint="`The default for a ${selectedAsset.asset.type} is ${selectedAsset.asset.configuration.minTemp}.0 °C`"/>
        </v-col>
        <v-col cols="12" md="6">
          <TextField label="Maximum temperature °C" :value="selectedAsset.asset.configuration.maxTemp" :editable="true" :disabled="true"
                     :hint="`The default for a ${selectedAsset.asset.type} is ${selectedAsset.asset.configuration.maxTemp}.0 °C`"/>
        </v-col>
      </v-row>


      <template slot="progress">
        <v-progress-linear
            color="secondary"
            height="5"
            indeterminate
        ></v-progress-linear>
      </template>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {Asset, DeviceEvent, MonitoringPoint, Site} from "@/types";
import TextField from "@/components/TextField.vue";
@Component({
  components: {TextField}
})
export default class AssetDetails extends Vue {
  @Getter private accountId!: number;
  @Prop() private selectedAsset!: { [key: string]: any };
  private site: Site = {} as Site;
  private asset: Asset = { id: 0, name: '', siteId: 0, manufacturer: '', assetType: '', configuration: { minTemp: 0, maxTemp: 0, alertThreshold: 0 }, monitoringPoints: [] };
  private assetData: DeviceEvent[] = [];
  private loading = false;

  private mounted() {
    this.loading = true;
    try {
      // TODO: Get asset configuration details
    } finally {
      this.loading = false;
    }
  }

  private get assetType() {
    const types: { [key: string]: string; } = {
      refrigerator: 'mdi-fridge-outline',
      freezer: 'mdi-snowflake',
      room: 'mdi-floor-plan',
    };
    return types[this.selectedAsset.asset.type];
  }
}
</script>

<style scoped>

</style>