<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back', { selected: selectedLocations, site })"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card color="tertiary" :class="{'mt-7': $vuetify.breakpoint.mdAndUp}" width="100%" class="pa-4">
      <v-row no-gutters>

        <v-col cols="12">
          <span class="text-h5 font-weight-bold">{{ site.name }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <p class="font-weight-bold text-h6 mb-0">Record type</p>
            </v-col>
          </v-row>
          <v-radio-group v-model="selectedRecordType" class="my-0" mandatory hide-details @change="switchRecordType">
            <v-row dense justify="center" align="stretch">
              <v-col cols="6" xl="4">
                <v-card outlined class="py-2 px-2" height="100%" color="background">
                  <v-row dense justify-sm="center" justify="space-between" align="center" class="fill-height">
                    <v-col cols="auto" lg="12">
                      <v-row no-gutters>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                          <v-icon color="primary">mdi-file-pdf-box</v-icon>
                        </v-col>
                        <v-col cols="12" lg="8" class="text-center">
                          <span class="font-weight-bold">Refrigeration records</span>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                          <v-sheet width="24" color="background"></v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" align-self="end">
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-radio
                              color="white"
                              class="pa-2 mr-n2"
                              :value="'pdf'"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="6" xl="4">
                <v-card outlined class="pt-lg-2" height="100%" color="background">
                  <v-row dense justify-sm="center" justify="space-between" align="center" class="fill-height">
                    <v-col cols="auto" lg="12">
                      <v-row no-gutters>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                          <v-icon color="primary">mdi-file-table-box</v-icon>
                        </v-col>
                        <v-col cols="12" lg="8" class="text-center">
                          <span class="font-weight-bold">Data download</span>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex justify-center">
                          <v-sheet width="24" color="background"></v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" align-self="end">
                      <v-row no-gutters>
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-radio
                              color="white"
                              class="pa-2 mr-n2"
                              :value="'events'"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

            </v-row>
          </v-radio-group>
          <v-row class="mt-6" justify="center" no-gutters>
            <v-col cols="12">
              <p class="font-weight-bold text-h6">Timeframe</p>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters align="center" class="mb-2">
                <v-col cols="auto">
                  <v-icon left color="primary">mdi-information</v-icon>
                </v-col>
                <v-col>
                  <span class="text-body-1" v-if="selectedRecordType !== 'pdf'">Select a range of dates to retrieve data for.</span>
                  <span v-else class="text-body-1">Select a date range and three time slots in a day to generate a PDF report.</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-if="selectedRecordType !== 'pdf'">
              <v-card outlined color="background">
                <v-row justify="center" align="center">
                  <v-col class="hover-col">
                      <v-date-picker
                          style="border-radius: 8px"
                          v-model="dates"
                          range
                          no-title
                          scrollable
                          :max="max"
                          width="100%"
                          color="secondary"
                      >
                      </v-date-picker>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" v-else>
              <v-radio-group v-model="selectedRange" class="my-0" mandatory>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-card outlined class="py-2 px-2" color="background">
                      <v-row dense justify="center" align="center">
                        <v-col cols="12" class="text-center">
                          <span class="font-weight-bold pr-2">Current calendar month</span>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-radio
                              color="white"
                              class="pa-2 mr-n2"
                              :value="'current'"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card outlined class="py-2 px-2" color="background">
                      <v-row dense justify="center" align="center">
                        <v-col cols="12" class="text-center">
                          <span class="font-weight-bold pr-2">Previous calendar month</span>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-radio
                              color="white"
                              class="pa-2 mr-n2"
                              :value="'previous'"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card outlined class="py-2 px-2" color="background">
                      <v-row dense justify="center" align="center">
                        <v-col cols="12" class="text-center">
                          <span class="font-weight-bold pr-2">Previous 6 calendar months</span>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <v-radio
                              color="white"
                              class="pa-2 mr-n2"
                              :value="'six_months'"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-radio-group>
              <v-row dense justify="center" class="mb-4">
                <v-col cols="12" md="3">
                  <v-card outlined class="py-2 px-2" color="background">
                    <v-row dense justify="center" align="center">
                      <v-col cols="12" class="text-center">
                        <span class="font-weight-bold pr-2">Timeslot #1</span>
                      </v-col>
                      <v-col cols="10" class="d-flex align-center justify-center">
                        <v-select hide-details :items="timeslotOptions" item-value="key" item-text="value" outlined class="mb-2" :menu-props="{ contentClass: 'text-center menu-background' }" full-width v-model="timeslots[0]" @change="resetTimeslot([1, 2])">
                          <template v-slot:selection="{item}">
                            <span class="d-flex justify-center" style="width: 100%;">
                              {{ item.value }}
                            </span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card outlined class="py-2 px-2" color="background">
                    <v-row dense justify="center" align="center">
                      <v-col cols="12" class="text-center">
                        <span class="font-weight-bold pr-2">Timeslot #2</span>
                      </v-col>
                      <v-col cols="10" class="d-flex align-center justify-center">
                        <v-select hide-details :items="timeslotOptions" item-value="key" item-text="value" outlined class="mb-2" :menu-props="{ contentClass: 'text-center menu-background' }" full-width v-model="timeslots[1]" item-disabled="disabled_slot2" :disabled="!timeslots[0]" @change="resetTimeslot([2])">
                          <template v-slot:selection="{item}">
                            <span class="d-flex justify-center" style="width: 100%;">
                              {{ item.value }}
                            </span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" md="3">
                  <v-card outlined class="py-2 px-2" color="background">
                    <v-row dense justify="center" align="center">
                      <v-col cols="12" class="text-center">
                        <span class="font-weight-bold pr-2">Timeslot #3</span>
                      </v-col>
                      <v-col cols="10" class="d-flex align-center justify-center">
                        <v-select hide-details :items="timeslotOptions" item-value="key" item-text="value" outlined class="mb-2" :menu-props="{ contentClass: 'text-center menu-background' }" full-width v-model="timeslots[2]" item-disabled="disabled_slot3" :disabled="!timeslots[0] || !timeslots[1]">
                          <template v-slot:selection="{item}">
                            <span class="d-flex justify-center" style="width: 100%;">
                              {{ item.value }}
                            </span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-btn plain color="secondary" class="text-none" :disabled="!timeslots.length" @click="clearTimeslots"><v-icon left>mdi-close</v-icon> Clear timeslots</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="selectedRecordType !== 'pdf'" class="pt-6">
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <p class="font-weight-bold text-h6 mb-0">Output file type</p>
                </v-col>
              </v-row>
              <v-radio-group v-model="selectedOutputType" class="my-0" mandatory>
                <v-row dense justify="center" align="stretch">
                  <v-col cols="6" lg="3">
                    <v-card outlined class="pa-2" height="100%" color="background">
                      <v-row dense justify="center" align="center" class="fill-height">
                        <v-col cols="auto" lg="12">
                          <v-row no-gutters>
                            <v-col cols="12" lg="2" class="d-flex justify-center">
                              <v-icon color="primary">mdi-microsoft-excel</v-icon>
                            </v-col>
                            <v-col cols="12" lg="8" class="text-center">
                              <span class="font-weight-bold">Excel spreadsheet</span>
                            </v-col>
                            <v-col cols="12" lg="2" class="d-flex justify-center">
                              <v-sheet width="24" color="background"></v-sheet>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" align-self="end">
                          <v-row no-gutters>
                            <v-col cols="12" class="d-flex align-center justify-center">
                              <v-radio
                                  color="white"
                                  class="pa-2 mr-n2"
                                  :value="'xls'"
                              ></v-radio>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col cols="6" lg="3">
                    <v-card outlined class="pa-2" height="100%" color="background">
                      <v-row dense justify="center" align="center" class="fill-height">
                        <v-col cols="auto" lg="12">
                          <v-row no-gutters>
                            <v-col cols="12" lg="2" class="d-flex justify-center">
                              <v-icon color="primary">mdi-file-delimited</v-icon>
                            </v-col>
                            <v-col cols="12" lg="8" class="text-center">
                              <span class="font-weight-bold">CSV file</span>
                            </v-col>
                            <v-col cols="12" lg="2" class="d-flex justify-center">
                              <v-sheet width="24" color="background"></v-sheet>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" align-self="end">
                          <v-row no-gutters>
                            <v-col cols="12" class="d-flex align-center justify-center">
                              <v-radio
                                  color="white"
                                  class="pa-2 mr-n2"
                                  :value="'csv'"
                              ></v-radio>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center text-center">
          <span class="text-h6" v-if="selectedRecordType !== 'pdf'">Exporting {{`${selectedRecordType === 'alerts' ? 'alerts' : 'data'}`}} for {{ selectedLocations.length }} sensor{{ `${selectedLocations.length > 1 ? `s` : ''}` }}.</span>
          <span class="text-h6" v-else>Exporting PDF report for {{ selectedLocations.length }} sensor{{ `${selectedLocations.length > 1 ? `s` : ''}` }}.</span>
        </v-col>
        <v-col cols="auto" class="text-center">
          <p class="text-h6 mb-0">Start date: {{ new Date(timestamps[0]).toLocaleDateString('en-GB') }}</p>
          <p class="text-h6">End date: {{ !timestamps[1].startsWith('Select') ? new Date(timestamps[1]).toLocaleDateString('en-GB') : timestamps[1] }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center my-4">
          <ActionButton :loading="downloading" height="50" width="200" :text="selectedRecordType === 'pdf' ? 'Generate PDF' : 'Download data'" @click="exportData(selectedRecordType)" :disabled="downloading || timestamps.length !== 2 || timestamps.some((t) => t === 'Select a date') || (selectedRecordType === 'pdf' && !(timeslots.length === 3 && timeslots.every((t) => t)))"/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { DownloadOptions, MonitoringPoint, Site } from '@/types';
import TextField from "@/components/TextField.vue";
import ActionButton from "@/components/ActionButton.vue";
import EventsService from "@/services/events.service";
import {Getter} from "vuex-class";
import Select from "@/components/Select.vue";
import {appAxios} from "@/app.axios";
import { downloadFile } from "../util";
@Component({
  components: {Select, ActionButton, TextField}
})
export default class DownloadConfiguration extends Vue {
  @Getter private accountId!: number;
  @Prop() private site!: Site;
  @Prop() private selectedLocations!: MonitoringPoint[];
  private downloading = false;
  private dates = [new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]];
  private selectedRange = '';
  private selectedRecordType = '';
  private selectedOutputType = '';
  private times: Array<{ [key: string]: string | boolean }> = [
    { key: '01', value: '01:00'}, { key: '02', value: '02:00'}, { key: '03', value: '03:00'}, { key: '04', value: '04:00'}, { key: '05', value: '05:00'}, { key: '06', value: '06:00'}, { key: '07', value: '07:00'},
    { key: '08', value: '08:00'}, { key: '09', value: '09:00'}, { key: '10', value: '10:00'}, { key: '11', value: '11:00'}, { key: '12', value: '12:00'}, { key: '13', value: '13:00'}, { key: '14', value: '14:00'}, { key: '15', value: '15:00'},
    { key: '16', value: '16:00'}, { key: '17', value: '17:00'}, { key: '18', value: '18:00'}, { key: '19', value: '19:00'}, { key: '20', value: '20:00'}, { key: '21', value: '21:00'}, { key: '22', value: '22:00'}, { key: '23', value: '23:00'}
  ]
  private timeslots: string[] = [];

  @Watch('timeslots', {deep: true})
  private onTimeslotsChanged() {
    localStorage.setItem('recordManagement:pdfTimeSlots', JSON.stringify(this.timeslots));
  }

  private mounted() {
    this.initTimeSlots();
  }

  private initTimeSlots(): void {
    const tss = localStorage.getItem('recordManagement:pdfTimeSlots');
    const ts = tss ? JSON.parse(tss) : [];
    if (ts[0]) {
      this.timeslots.push(ts[0]);
      if (ts[1]) {
        this.timeslots.push(ts[1]);
        if (ts[2]) {
          this.timeslots.push(ts[2]);
        }
      }
    }
  }

  private switchRecordType() {
    if (this.selectedRecordType === 'pdf') {
      this.selectedOutputType = 'pdf'
      this.selectedRange =  'current';
    } else {
      this.selectedOutputType = 'xls';
      this.dates = [new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]]
    }
  }

  private resetTimeslot(slots: number[]) {
    for (const slot of slots) {
      this.timeslots[slot] = '';
      if (slot === 1) {
        this.times.forEach((t) => {
          t.disabled_slot2 = false
          t.disabled_slot3 = false
        })
      } else if (slot === 2) {
        this.times.forEach((t) => {
          t.disabled_slot3 = false
        })
      }
    }
  }

  private clearTimeslots() {
    this.timeslots = [];
    this.times.forEach((t) => {
      t.disabled_slot2 = false;
      t.disabled_slot3 = false;
    })
  }

  private get max() {
    return new Date().toISOString().split('T')[0];
  }

  private get timeslotOptions() {
    const slot2 = this.times.findIndex((t) => t.key === this.timeslots[0]);
    for (let i = 0; i <= slot2; i++) {
      this.times[i].disabled_slot2 = true;
      this.times[i].disabled_slot3 = true;
    }

    const slot3 = this.times.findIndex((t) => t.key === this.timeslots[1]);
    for (let i = 0; i <= slot3; i++) {
      this.times[i].disabled_slot3 = true;
    }

    return this.times;
  }

  private get timestamps() {
    if (this.selectedRecordType === 'pdf') {
      const date = new Date();
      switch (this.selectedRange) {
        case 'current':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth(), 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth() + 1, 0).toDateString();
          break;
        case 'previous':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth() - 1, 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth(), 0).toDateString();
          break;
        case 'six_months':
          this.dates[0] = new Date(date.getFullYear(), date.getMonth() - 6, 1).toDateString();
          this.dates[1] = new Date(date.getFullYear(), date.getMonth(), 0).toDateString();
          break;
      }
    }
    let dates = []
    if (this.dates[0]) {
      dates.push(this.dates[0])
    } else {
      dates.push('Select a date')
    }
    if (this.dates[1]) {
      dates.push(this.dates[1])
    } else {
      dates.push('Select a date')
    }
    if (dates.every((d) => d !== 'Select a date')) {
      dates.sort((x: string, y: string) => {
        return new Date(x).getTime() - new Date(y).getTime();
      })
      return dates;
    }
    return dates;
  }

  private async exportData() {
    this.downloading = true;
    
    try {
      const since = new Date(this.timestamps[0]);
      since.setHours(0, 0, 0, 0);
      const before = new Date(this.timestamps[1]);
      before.setHours(23, 59, 59, 999);
      const opts : DownloadOptions = {
        before,
        monitoringPointIds: this.selectedLocations.map((m)=>m.id),
        since,
        siteId: this.site.id,
        type: this.selectedRecordType,
        format: this.selectedOutputType,
        ...(this.timeslots[0] && { hour1: Number(this.timeslots[0]) }),
        ...(this.timeslots[1] && { hour2: Number(this.timeslots[1]) }),
        ...(this.timeslots[2] && { hour3: Number(this.timeslots[2]) }),
        timezoneOffsetMS: (since.getTimezoneOffset() * 60000)
      }
      const url = await EventsService.getRecordsDownloadURL(this.accountId,opts);

      const fileName = (new Date()).toISOString().split('T')[0];
      
      if (this.selectedRecordType === 'pdf') {
        await downloadFile(url, `${fileName}_report.pdf`);
      } else {
        if (this.selectedOutputType === 'csv') {
          await downloadFile(url, `${fileName}_records.csv`);
        } else {
          await downloadFile(url, `${fileName}_records.xlsx`);
        }
      }
    } catch (error) {
      const err = error as Error;

      throw err;
    } finally {
      this.downloading = false;
    }

  }
}
</script>

<style >
.hover-col {
  text-align: center;
}
.hover-col:hover {
  color: #f2243f;
  cursor: pointer;
}
.v-application .tertiary .dark-border {
  border-color: #ffffff !important;
}

.menu-background .theme--dark.v-list {
  background-color: #555555;
}
</style>
