<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container" v-if="!appLoading">
      <v-row no-gutters class="content-container">
        <v-col cols="12">
          <v-row>
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="5" md="5">
                <v-card :loading="loading" color="tertiary" class="mt-14">
                  <v-row v-if="loading" justify="center">
                    <v-col cols="auto">
                      <div class="text-body-2 font-weight-light py-2">Loading user...</div>
                    </v-col>
                  </v-row>
                  <v-row dense class="py-2 px-4" v-if="!loading">
                    <v-col cols="12">
                      <v-row>
                        <v-col>
                          <div class="text-h5 font-weight-bold" v-if="!showEditName">{{ user && user.first_name ? user.first_name : 'Firstname' }} {{ user && user.last_name ? user.last_name : 'Lastname' }}</div>
                          <div v-else>
                            <v-form v-model="validName" class="d-flex align-center">
                              <v-text-field hide-details outlined class="input-height custom-field mr-2" v-model="userDetails.firstName" placeholder="First name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                              <v-text-field hide-details outlined class="input-height custom-field ml-2" v-model="userDetails.lastName" placeholder="Last name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                            </v-form>
                          </div>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn icon color="primary" @click="showEditName = true;" v-if="!showEditName"><v-icon>mdi-pencil</v-icon></v-btn>
                          <v-btn icon color="primary" @click="showEditName = false; resetName()" v-else><v-icon>mdi-close</v-icon></v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-slide-y-transition>

                      <v-col cols="12">
                        <div class="text-body-1 font-weight-bold">{{ user && user.email ? user.email : 'user@email.com' }}</div>
                      </v-col>
                    </v-slide-y-transition>
                    <v-col cols="12" class="mt-6 text-h6 font-weight-bold">User email alert preference</v-col>
                    <v-col cols="12" class="d-flex justify-center align-center">
                      <v-row no-gutters align="center">
                        <v-col>
                          <span v-if="userDetails.alertsEnabled">You <strong>will</strong> receive email alerts.</span>
                          <span v-else>You will <strong>not</strong> receive email alerts.</span>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center justify-end">
                          <v-switch inset dense color="primary" v-model="userDetails.alertsEnabled"></v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center justify-center mt-4">
                      <ActionButton color="primary" text="Save user" width="220" height="50" @click="saveEditUser" :loading="saving" :disabled="saving || !validName"/>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import UserDetails from "@/views/UserDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
import {Action, Getter} from "vuex-class";
import {User} from "@/types";
import Loader from "@/components/Loader.vue";
@Component({
  components: {Loader, ActionButton, UserDetails}
})
export default class UserProfile extends Vue {
  @Getter private user!: any;
  @Getter private accountId!: any;
  @Getter private appLoading!: boolean;
  @Action private refreshUser!: () => Promise<void>;
  private loading = false;
  private validName = true;
  private saving = false;
  private showEditName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };

  @Watch('user', {immediate: true})
  private onUserUpdate() {
    if (this.user) {
      this.userDetails = {
        firstName: this.user.first_name,
        lastName: this.user.last_name,
        alertsEnabled: this.user.alerts_enabled
      };
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.user.first_name,
      lastName: this.user.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    };
  }
  private async saveEditUser() {
    this.saving = true;

    try {
      const payload = {
        user: {
          first_name: this.userDetails.firstName,
          last_name: this.userDetails.lastName,
          alerts_enabled: this.userDetails.alertsEnabled
        }
      };
      await UserService.updateUser(this.accountId, this.user.id, payload);
    }
    finally {
      this.showEditName = false;
      this.saving = false;
    }
    await this.refreshUser();
  }
}
</script>

<style scoped>

</style>