import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOtpInput } from 'vuetify/lib/components/VOtpInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{staticClass:"pa-4",attrs:{"cols":"12","md":"10","lg":"6"}},[_c(VCard,{staticClass:"px-4 py-6",attrs:{"color":"tertiary"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('LoginError',{attrs:{"error":_vm.error}}),(_vm.info)?_c(VAlert,{staticClass:"text-left",attrs:{"dense":"","type":"info","color":"error"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.info))])]):_vm._e()],1)],1),_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{staticClass:"text-center"},[_c('span',{staticClass:"white--text text-body-1 font-weight-bold"},[_vm._v("Enter your reset code")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VOtpInput,{staticClass:"otp",attrs:{"length":"6"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text text-body-1 font-weight-bold"},[_vm._v("Enter a new password")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VForm,{ref:"passwordForm",model:{value:(_vm.passwordValid),callback:function ($$v) {_vm.passwordValid=$$v},expression:"passwordValid"}},[_c(VTextField,{staticClass:"custom-field",attrs:{"placeholder":"New password","persistent-placeholder":"","outlined":"","append-icon":_vm.value['new'] ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value['new'] ? 'password' : 'text',"rules":[
                    (v) => (v && v.length >= 6) || 'Password must be at least 6 characters long.',
                    (v) => (v && v.toUpperCase() !== v) || 'Password must contain a lowercase character.',
                    (v) => (v && v.toLowerCase() !== v) || 'Password must contain an uppercase character.',
                    (v) => (v && /\d/.test(v)) || 'Password must contain a number.',
                    (v) => (v && _vm.symbols.test(v)) || 'Password must contain a special character.',
                ],"autocomplete":"off"},on:{"click:append":() => (_vm.value['new'] = !_vm.value['new']),"input":function($event){return _vm.$refs.repeatPasswordForm.validate()}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c(VForm,{ref:"repeatPasswordForm",model:{value:(_vm.repeatValid),callback:function ($$v) {_vm.repeatValid=$$v},expression:"repeatValid"}},[_c(VTextField,{staticClass:"custom-field",attrs:{"placeholder":"Repeat new password","persistent-placeholder":"","outlined":"","append-icon":_vm.value['repeat'] ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value['repeat'] ? 'password' : 'text',"rules":[
                    (v) => !_vm.passwordValid || v === _vm.newPassword || 'Passwords do not match.'
                ],"autocomplete":"off"},on:{"click:append":() => (_vm.value['repeat'] = !_vm.value['repeat']),"input":function($event){return _vm.$refs.repeatPasswordForm.validate()}},model:{value:(_vm.repeatedPassword),callback:function ($$v) {_vm.repeatedPassword=$$v},expression:"repeatedPassword"}})],1)],1)],1),_c(VRow,{staticClass:"mt-n4",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('a',{staticStyle:{"color":"#f2243f"},on:{"click":_vm.resend}},[_vm._v("Resend reset code?")])]),_c(VCol,{staticClass:"d-flex justify-center"},[_c('ActionButton',{attrs:{"width":"95","text":"Confirm","color":"secondary","loading":_vm.loading,"disabled":_vm.loading || !_vm.passwordValid || !_vm.repeatValid || !_vm.newPassword || !_vm.repeatedPassword},on:{"click":_vm.confirmActivationCode}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }