<template>
  <div class="fill-height">
    <v-card class="mb-6 user-card" :color="color" @click="$emit('clicked:account', account)">
      <v-row align="center" class="px-4 pt-2" dense justify="space-between">
        <v-col cols="12" sm="8">
          <v-row no-gutters align="center">
            <v-col cols="12">
              <div class="d-flex align-center">
                <div class="text-h5 font-weight-bold" :class="{'secondary--text': accountId === account.daizy_project_id}">{{ account.name }}</div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center">
                <div class="text-body-1 font-weight-bold mb-2" :class="{'secondary--text': accountId === account.daizy_project_id}">{{ account.account_code }}</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4">
          <v-row no-gutters align="center" justify="end">
            <v-col cols="6" md="12">
              <div class="d-flex align-center justify-md-end">
                <span class="text--secondary text-body-2">Created on</span>
              </div>
            </v-col>
            <v-col cols="6" md="12">
              <div class="d-flex align-center justify-end">
                <div class="text-body-1 font-weight-bold">{{ displayDate }}</div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Asset, MonitoringPoint, Site} from "@/types";
import {Getter} from "vuex-class";
@Component({
  components: {ActionButton}
})
export default class AccountCard extends Vue {
  @Getter private accountId!: number;
  @Prop() private account!: any;
  @Prop({default: 'tertiary'}) private color!: string;

  private get displayDate() {
    return new Date(this.account.created_at).toISOString().split('T')[0]
  }
}
</script>

<style scoped>
  .selected-border {
    border-left: 2px solid rgba(242, 36, 64, 0.5);
    border-right: 2px solid rgba(242, 36, 64, 0.5);
  }
</style>