import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCard,{attrs:{"color":"transparent","flat":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTabs,{attrs:{"background-color":"transparent","color":"primary","grow":""},on:{"change":function($event){_vm.$emit('set:range', { range: _vm.range, span: _vm.timespan[_vm.tab]}); _vm.rangePosition = 0;}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((['day', 'week', 'month']),function(item){return _c(VTab,{key:item},[_c('span',{staticClass:"text-capitalize",class:_vm.$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-1'},[_vm._v(_vm._s(item))])])}),1)],1)],1),_c(VRow,{staticClass:"mt-4",attrs:{"justify":"center","align":"center"}},[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.rangePosition = _vm.rangePosition - 1;}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1),_c(VCol,{staticClass:"text-center",attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.xs ? 'text-caption' : 'text-body-1'},[_vm._v(" "+_vm._s(_vm.range.from.toLocaleDateString('en-GB'))+" - "+_vm._s(_vm.range.to.toLocaleDateString('en-GB'))+" ")])]),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":"","disabled":_vm.disableForward,"small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.rangePosition = _vm.rangePosition + 1}}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }