<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container" v-if="!appLoading">
      <v-row no-gutters class="content-container">
        <v-col cols="12">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="4" md="5" class="mt-14">
                <v-row v-if="!showAccountDetails">
                  <v-col cols="12">
                    <v-autocomplete dense height="44" outlined class="custom-field" placeholder="Search customer accounts" persistent-placeholder item-value="name" item-text="name" :filter="customSearch" :search-input="accountSearch" prepend-inner-icon="mdi-magnify" v-model="searchedAccount" :items="customerAccounts" return-object @input="selectedAccount = $event; updateSelected++">
                      <template v-slot:selection="{ item }">
                        {{ item.name }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.name }} <v-spacer></v-spacer> ({{ item.account_code }})
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="customerAccounts.length">
                  <v-col cols="12">
                    <div v-for="account of customerAccounts" :key="account.id">
                      <AccountCard :account="account" @clicked:account="selectedAccount = $event" :color="selectedAccount.daizy_project_id === account.daizy_project_id ? 'rgba(50,55,75,0.12)' : 'tertiary'" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave v-if="customerAccounts.length">
              <v-col cols="12" lg="8" md="7">
                <CustomerAccountDetails :selected-account="selectedAccount" @remove:account="getCustomerAccounts" @refresh:accounts="getCustomerAccounts"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
          <v-row v-else-if="customerAccounts.length && $vuetify.breakpoint.smAndDown" class="mt-4">
            <v-col cols="12" class="px-4" v-if="!showAccountDetails">
              <v-autocomplete @change="selectedAccount = $event; showAccountDetails = true;" hide-details dense height="44" outlined class="custom-field mb-4" placeholder="Search customer accounts" persistent-placeholder item-value="name" item-text="name" :filter="customSearch" :search-input="accountSearch" prepend-inner-icon="mdi-magnify" v-model="searchedAccount" :items="customerAccounts" return-object @input="selectedAccount = $event; updateSelected++">
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.name }} <v-spacer></v-spacer> ({{ item.account_code }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-slide-x-reverse-transition hide-on-leave v-if="!showAccountDetails">
              <v-col cols="12" md="12" lg="5">
                <div v-for="account of customerAccounts" :key="account.id">
                  <AccountCard :account="account"  @clicked:account="selectedAccount = $event; showAccountDetails = true;" />
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="8" md="12">
                <CustomerAccountDetails v-if="showAccountDetails" :selected-account="selectedAccount" @click:back="showAccountDetails = false; accountSearch = ''; searchedAccount = null"  @remove:account="showAccountDetails = false; getCustomerAccounts()" @refresh:accounts="getCustomerAccounts($event);"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import {Action, Getter, Mutation} from "vuex-class";
import AccountCard from "@/components/AccountCard.vue";
import CustomerAccountDetails from "@/views/admin/CustomerAccountDetails.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
import AdminService from "@/services/admin.service";
import {Account, User} from "@/types";
import store from "@/store";
import {RouteScope} from "@/router";
import {NavigationGuardNext, Route} from "vue-router";
@Component({
  components: {ActionButton, CustomerAccountDetails, AccountCard, Loader}
})
export default class CustomerAccounts extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: User;
  @Getter private appLoading!: boolean;
  @Action private showToastError!: any;
  @Mutation private toggleLoading!: any;
  private selectedAccount: Account = {} as Account;
  private customerAccounts: Account[] = [];
  private showAccountDetails = false;
  private updateSelected = 0;
  private searchedAccount: Account | null = null;
  private accountSearch = '';

  @Watch('searchedAccount')
  private onSearch() {
    if (!this.searchedAccount) {
      this.selectedAccount = this.customerAccounts[0];
    }
  }

  private async mounted() {
    await this.getCustomerAccounts();
  }

  private async getCustomerAccounts(daizyProjectId?: number) {
    this.toggleLoading(true);
    try {
      this.customerAccounts = await AdminService.getAccounts();
      if (this.accountId) {
        const account = this.customerAccounts.find((u) => u.daizy_project_id === this.accountId);
        if (account) {
          this.selectedAccount = account;
        }
      } else {
        let accountIndex = 0;
        if (daizyProjectId) {
          accountIndex = this.customerAccounts.findIndex((acc) => acc.daizy_project_id === daizyProjectId);
          if (accountIndex < 0) {
            this.showAccountDetails = false;
            accountIndex = 0;
          }
        }
        this.selectedAccount = this.customerAccounts[accountIndex];
      }
    } catch (e) {
      const err = e as Error;
      this.showToastError(err.message)
    } finally {
      this.toggleLoading(false);
    }
  }

  private get customSearch() {
    return (item: any, search: string) => {
      if (item) {
        return (item.name.toLowerCase() && item.name.toLowerCase().indexOf(search) > -1) || (item.account_code.toLowerCase() && item.account_code.toLowerCase().indexOf(search) > -1);
      } else {
        return false;
      }
    };
  }
}
</script>

<style scoped>

</style>