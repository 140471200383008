import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fill-height"},[_c(VCard,{staticClass:"mb-6 user-card",attrs:{"color":_vm.color},on:{"click":function($event){return _vm.$emit('clicked:user', _vm.user)}}},[_c(VRow,{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c(VCol,[_c(VRow,{staticClass:"px-4 py-2",attrs:{"no-gutters":"","align":"center"}},[_c(VCol,{staticClass:"mr-4 d-flex justify-center",attrs:{"cols":"2"}},[_c(VAvatar,{staticClass:"white--text",attrs:{"size":_vm.$vuetify.breakpoint.lgAndDown ? 42 : 52,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.user.first_name.charAt(0))+" "+_vm._s(_vm.user.last_name.charAt(0))+" ")])],1),_c(VCol,{staticClass:"text-truncate"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"text-truncate",style:(_vm.width)},[_c('span',{staticClass:"font-weight-bold",class:_vm.$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'},[_vm._v(_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name))])])])]),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'},[_vm._v(_vm._s(_vm.user.email))])])],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }