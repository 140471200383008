<template>
  <div class="overflow-x-hidden fill-height">
    <Loader />
    <v-stepper v-model="activeStep" flat :key="reset" class="no-transition" v-if="hasPermission('application:manage')">
      <v-stepper-items>
        <v-stepper-content :step="SetupSteps.SiteWizard" class="pa-0">
          <SiteWizard
              v-if="!appLoading"
              :sites="sites"
              @complete:site-wizard="site = $event; siteHasGateway ? activeStep = SetupSteps.DeviceWizard : activeStep = SetupSteps.GatewayWizard;"
              :site-step="siteStep"
          />
        </v-stepper-content>
        <v-stepper-content :step="SetupSteps.GatewayWizard" class="pa-0">
          <GatewayWizard
              v-if="!appLoading"
              :sites="sites"
              :site="site"
              @go-to:site-wizard="loadSites(); activeStep = SetupSteps.SiteWizard; siteStep = 2"
              @complete:gateway-wizard="activeStep = SetupSteps.DeviceWizard; site.gateway = $event"
              :gateway-step="gatewayStep"
          />
        </v-stepper-content>
        <v-stepper-content :step="SetupSteps.DeviceWizard" class="pa-0">
          <DeviceWizard
              v-if="!appLoading && activeStep === SetupSteps.DeviceWizard"
              :sites="sites"
              :site="site"
              :saved-devices="devices"
              @save:devices="devices = $event"
              @reload:site="siteOnly = $event; loadSites()"
              @go-to:site-wizard="loadSites(); activeStep = SetupSteps.SiteWizard; siteStep = 2"
              @go-to:gateway-wizard="!!site.gateway ? activeStep = SetupSteps.SiteWizard : activeStep = SetupSteps.GatewayWizard; !!site.gateway ? siteStep = 2 : gatewayStep = 3;"
              @complete:device-wizard="$router.push('home')"
              @add:device="loadSites(); reset++; activeStep = SetupSteps.SiteWizard; siteStep = 2"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card flat v-else height="70vh" color="background">
      <v-row justify="center" align="end" class="fill-height">
        <v-col cols="auto">
          <v-icon size="120" color="primary">mdi-lock</v-icon>
        </v-col>
        <v-col cols="12" class="text-center" align-self="start">
          <span class="text-h6 font-weight-bold">This page can only be accessed by an account manager.</span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from "vue-property-decorator";
import SiteWizard from "@/views/wizards/SiteWizard.vue";
import DeviceWizard from "@/views/wizards/DeviceWizard.vue";
import GatewayWizard from "@/views/wizards/GatewayWizard.vue";
import {Device, Site} from "../types";
import SiteService from "@/services/site.service";
import Select from "@/components/Select.vue";
import {Route} from "vue-router";
import {Getter, Mutation} from "vuex-class";
import Loader from "@/components/Loader.vue";


enum SetupSteps {
  SiteWizard,
  GatewayWizard,
  DeviceWizard,
}

@Component({
  components: {DeviceWizard, GatewayWizard, SiteWizard, Loader, Select}
})
export default class Setup extends Vue {
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Getter private hasPermission!: any;
  @Mutation private toggleLoading!: any;
  private SetupSteps = SetupSteps;
  private activeStep: SetupSteps = SetupSteps.SiteWizard;
  private sites: Site[] = [];
  private devices: Device[] = [];
  private site: Partial<Site> = {};
  private gatewayStep = 0;
  private siteStep = 0;
  private siteOnly = false;
  private reset = 0;

  private async mounted() {
    await this.loadSites();
  }

  private async loadSites() {
    this.toggleLoading(true);
    try {
      if (this.siteOnly) {
        const site = (await SiteService.getSites(this.accountId, undefined, this.site.id)).find((s) => s.id === this.site.id);
        Vue.set(this, 'site', site)
      } else {
        this.sites = await SiteService.getSites(this.accountId)

        if ('siteId' in this.$route.params) {
          const site = this.sites.find((s) => s.id === Number(this.$route.params.siteId))
          this.site = site || {};
          this.siteHasGateway ? this.activeStep = SetupSteps.DeviceWizard : this.activeStep = SetupSteps.GatewayWizard;
        }
      }
    } finally {
      this.toggleLoading(false);
    }
  }


  private get siteHasGateway() {
    return this.site && this.site.gateway && this.site.gateway.id;
  }
}
</script>

<style>
.content-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px - 48px - 24px - 16px);
  justify-content: space-between;
}
.wrapper {
  flex: 1;
}
.no-transition .v-stepper__content {
  transition: none !important;
}
</style>