<template>
  <transition name="toast-show">
    <div v-if="mutableShowToastBoolean">
      <v-alert
          class="mr-4"
          type="error"
          elevation="4"
          :dismissible="true"
          style="border-radius: 8px"
      >
        <v-row no-gutters>
          <v-col cols="12" md="auto">
            <strong class="mr-3">{{heading}}</strong>
          </v-col>
          <v-col cols="12" md="auto">
            <span>{{body}}</span>
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </transition>

</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ErrorToast extends Vue {
  @Prop({default: ''}) private heading!: string;
  @Prop({default: ''}) private body!: string;
  @Prop({default: true}) private showToastBoolean!: boolean;
  private mutableShowToastBoolean = true;

  private mounted() {
    setTimeout(() => {
      this.mutableShowToastBoolean = false;
    }, 10000);
  }
}
</script>

<style scoped lang="scss">
.toast-show-enter,
.toast-show-leave-to {
  opacity: 0;
  bottom: -100%;
}
.toast-show-enter-to,
.toast-show-leave {
  opacity: 1;
  bottom: 0;
}
.toast-show-enter-active,
.toast-show-leave-active {
  transition: opacity 1s ease-out;
}
</style>
