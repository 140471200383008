<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card color="tertiary">
      <v-row justify="space-between" class="px-4 py-2" no-gutters>
        <v-col cols="auto">
          <div class="d-flex align-center">
            <div class="text-h5 font-weight-bold">{{ site.name }}</div>
          </div>
        </v-col>
      </v-row>
      <v-row class="px-4">
        <v-col cols="12" v-if="gatewayAlerts.length">
          <v-card outlined class="pa-4 my-2" v-for="alert of gatewayAlerts" :key="alert.timestamp" color="background">
            <v-row no-gutters>
              <v-col cols="auto" class="d-flex align-center">
                <v-icon color="error" large>mdi-alert</v-icon>
              </v-col>
              <v-col>
                <v-row justify="center" dense>
                  <v-col cols="12">
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="d-flex justify-center align-center text-center">
                        <span class="font-weight-bold text-h6">Gateway</span>
                      </v-col>
                      <v-col cols="auto">
                        <v-sheet color="transparent" width="36" height="36"></v-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex align-center justify-center text-center">
                        <span class="font-weight-regular text-h6">{{ alertType(alert.alert_type) }}</span>
                      </v-col>
                      <v-col class="d-flex align-center justify-center">
                        <span class="text-body-2">
                          {{ new Date(alert.timestamp).toLocaleString('en-GB') }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto">
                    <v-sheet color="transparent" width="36" height="36"></v-sheet>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="deviceAlerts.length">
          <v-card outlined class="pa-4 my-2" v-for="alert of deviceAlerts" :key="alert.timestamp" color="background">
            <v-row no-gutters>
              <v-col cols="auto" class="d-flex align-center">
                <v-icon color="error" large v-if="alert.alert_type !== 'battery_alert'">mdi-alert</v-icon>
                <v-icon color="warning" large v-else>mdi-battery-alert</v-icon>
              </v-col>
              <v-col>
                <v-row justify="center" dense>
                  <v-col cols="12">
                    <v-row align="center" justify="center">
                      <v-col cols="auto" class="d-flex justify-center align-center">
                        <span class="font-weight-bold text-h6">{{ getName(alert.location_id) }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="12" class="d-flex align-center justify-center text-center">
                        <span class="font-weight-regular text-h6">{{ alertType(alert.alert_type) }}</span>
                      </v-col>
                      <v-col class="d-flex align-center justify-center">
                        <span class="text-body-2">
                          {{ new Date(alert.timestamp).toLocaleString('en-GB') }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn :loading="loadingMetrics" :disabled="loadingMetrics" icon :large="$vuetify.breakpoint.smAndUp" @click="$emit('show:metrics', { monitoringPointId: alert.location_id, siteId: site.id, roomId: alert.room_id })"><v-icon :large="$vuetify.breakpoint.smAndUp">mdi-chevron-right</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Asset, Site} from "@/types";
import {Component, Prop, Vue} from "vue-property-decorator";
import {Getter} from "vuex-class";


@Component
export default class AlertDetails extends Vue {
  @Prop() private alerts!: any;
  @Prop() private site!: Site;
  @Prop() private loadingMetrics!: boolean;

  private alertType(type: string) {
    switch (type) {
      case 'nodata_alert': return 'No data received';
      case 'temperature_alert': return 'Temperature outside thresholds';
      case 'battery_alert': return 'Battery low';
      case 'gateway_offline_alert': return 'Gateway is offline';
    }
  }

  private getName(id: number) {
    if (this.site.id === id) {
      return 'Gateway';
    }
    const room = this.site.rooms?.find((r) => r.assets?.some((a) => a.monitoringPoints.some((mp) => mp.id === id)));
    const asset = this.site.rooms?.find((r) => r.assets?.find((a) => a.monitoringPoints.find((mp) => mp.id === id)))?.assets.find((a) => a.monitoringPoints.find((mp) => mp.id === id));
    return `${room?.name}⠀›⠀${asset?.name}`;
  }

  private get deviceAlerts() {
    return this.alerts.filter((a: { alert_type: string; timestamp: string; location_id: number, room_id: number }) => {
      const room = this.site.rooms?.find((r) => r.id === a.room_id);
      const monitoringPointIds = room?.assets?.flatMap((asset) => asset.monitoringPoints.map((mp) => mp.id));
      if (a.alert_type !== 'gateway_offline_alert' && monitoringPointIds?.includes(a.location_id)) {
        return a;
      }
    });
  }

  private get gatewayAlerts() {
    return this.alerts.filter((a: { alert_type: string; timestamp: string; location_id: number }) => a.alert_type === 'gateway_offline_alert');
  }

}
</script>

<style scoped>

</style>