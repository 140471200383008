<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container" v-if="filteredSites.length && !appLoading">
      <v-row no-gutters class="content-container">
        <v-col cols="12">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" md="5" lg="4" class="mt-7">
                <v-row>
                  <v-col cols="12">
                    <v-card color="primary" height="40">
                      <v-menu offset-y v-model="filterMenu" v-if="!selectedFromMenuSite" content-class="menu-background text-left">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary" v-on="on" v-bind="attrs" height="40" block class="text-none white--text"><v-icon left>mdi-filter-variant</v-icon>Filter sites</v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item-group v-model="selectedFromMenuSite" color="primary">
                            <v-list-item
                                v-for="(site, index) of filteredSites"
                                :key="index"
                                :value="site"
                            >
                              <v-list-item-title>{{ site.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                      <v-btn v-else @click.stop="filterMenu = false; selectedFromMenuSite = null; selectLocation([], null)" color="secondary" height="40" block class="text-none white--text"><v-icon left>mdi-filter-variant-remove</v-icon>Clear filter</v-btn>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row align="center" no-gutters class="my-4">
                  <v-col cols="auto">
                    <v-icon left color="primary">mdi-information</v-icon>
                  </v-col>
                  <v-col>
                    <span class="font-weight-regular text-body-1">Select a site, or its individual rooms and units, to download the data from them or generate a PDF report.</span>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" v-for="site of filteredSites" :key="site.id" class="mb-8">
                    <RecordCard :select-all-disabled="selectAllDisabled" :site="site" :sites="sites" :selected-locations="selectedSite ? selectedLocations[selectedSite.id] : []" @clicked:configure="selectedSite = $event.site;" @select:location="selectLocation($event.selected, $event.site)"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition v-if="selectedSite && selectedSite.id">
              <v-col cols="12" lg="8" md="7">
                <div  class="sticky-column">
                  <DownloadConfiguration :site="selectedSite" :selected-locations="selectedLocations[selectedSite.id]" v-if="selectedSite"/>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
          <!--    Mobile layout starts here      -->
          <v-row v-else class="mt-4">
            <v-slide-x-reverse-transition hide-on-leave v-if="!showSidePanel">
              <v-col cols="12" md="5" lg="4">
                <v-row>
                  <v-col cols="12">
                    <v-card color="primary" height="40">
                      <v-menu offset-y v-model="filterMenu" v-if="!selectedFromMenuSite" content-class="menu-background">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary" v-on="on" v-bind="attrs" height="40" block class="text-none white--text"><v-icon left>mdi-filter-variant</v-icon>Filter sites</v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item-group v-model="selectedFromMenuSite" color="primary">
                            <v-list-item
                                v-for="(site, index) of filteredSites"
                                :key="index"
                                :value="site"
                            >
                              <v-list-item-title>{{ site.name }}</v-list-item-title>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                      <v-btn v-else @click.stop="filterMenu = false; selectedFromMenuSite = null; selectLocation([], null);" color="primary" height="40" block class="text-none white--text"><v-icon left>mdi-filter-variant-remove</v-icon>Clear filter</v-btn>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row align="center" no-gutters class="my-4">
                  <v-col cols="auto">
                    <v-icon left color="primary">mdi-information</v-icon>
                  </v-col>
                  <v-col>
                    <span class="font-weight-regular text-body-1">Select a site, or its individual rooms and units, to download the data from them or generate a PDF report.</span>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" v-for="site of filteredSites" :key="site.id" class="mb-8">
                    <RecordCard :select-all-disabled="selectAllDisabled" :site="site" :sites="sites" :selected-locations="selectedSite ? selectedLocations[selectedSite.id] : []" @clicked:configure="showSidePanel = true; selectedSite = $event.site;" @select:location="selectLocation($event.selected, $event.site)" />
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave v-else>
              <v-col cols="12" lg="8" md="12">
                <DownloadConfiguration :site="selectedSite" :selected-locations="selectedLocations[selectedSite.id]" @click:back="showSidePanel = false;"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!appLoading" class="fill-height">
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="auto">
          <v-row class="mx-4 no-gutters" justify="center">
            <v-img :src="require('@/assets/record_mgmt.png')" contain max-height="300" />
            <v-col cols="12" class="mt-10">
              <p class="font-weight-bold text-h5 text-center">You don't have any data to download yet</p>
            </v-col>
            <v-col cols="auto" class="mt-10">
              <ActionButton text="Launch setup wizard" width="220" height="50" @click="$router.push({ name: 'setup' })"></ActionButton>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import Loader from "@/components/Loader.vue";
import RecordCard from "@/components/RecordCard.vue";
import SiteService from "@/services/site.service";
import {Getter, Mutation} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import DownloadConfiguration from "@/views/DownloadConfiguration.vue";
@Component({
  components: {DownloadConfiguration, ActionButton, RecordCard, Loader}
})
export default class RecordManagement extends Vue {
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private selectedLocations: {[key: number]: (Asset | MonitoringPoint)[]} = {};
  private sites: Site[] = [];
  private selectedFromMenuSite: Site | null = null;
  private selectedSite: Site | null = null;
  private filterMenu = false;
  private showSidePanel = false;
  private selectAllDisabled: {[key: number]: boolean} = {};


  private async mounted() {
    this.toggleLoading(true);
    try {
      this.sites = await SiteService.getSites(this.accountId);
    } finally {
      this.toggleLoading(false);
    }
  }

  private selectLocation(locs: (Room | Asset | MonitoringPoint)[], site: Site) {
    if (site) {
      Vue.set(this.selectedLocations, site.id, locs);
      this.selectedSite = site;
    }
    if (!locs.length) {
      this.selectedSite = null;
      this.sites.forEach((s: Site) => {
        Vue.set(this.selectAllDisabled, s.id, false);
        s.rooms?.forEach((r: Room) => {
          Vue.set(r, 'disabled', false);
          r.assets.forEach((a: Asset) => {
            Vue.set(a, 'disabled', false);
            a.monitoringPoints.forEach((mp: MonitoringPoint) => {
              Vue.set(mp, 'disabled', false);
            })
          })
        })
      })
      return;
    }
    this.sites.forEach((s: Site) => {
      if (this.selectedSite?.id !== s.id) {
        Vue.set(this.selectAllDisabled, s.id, true)
      }
      s.rooms?.forEach((r: Room) => {
        if (r.siteId !== site.id) {
          Vue.set(r, 'disabled', true);
          r.assets.forEach((a: Asset) => {
            Vue.set(a, 'disabled', true);
            a.monitoringPoints.forEach((mp: MonitoringPoint) => {
              Vue.set(mp, 'disabled', true);
            })
          })
        }
      })
    })
  }

  private get filteredSites(): Site[] {
    if (this.selectedFromMenuSite) {
      return [this.selectedFromMenuSite];
    }

    const sites = [];
    for (const site of this.sites) {
      let sitesToHide: number[] = [];
      if (site && site.rooms && site.rooms.length) {
        for (const room of site.rooms) {
          if (room.assets.length) {
            room.assets.forEach((a) => {
              a.siteId = site.id;
              a.monitoringPoints.forEach((mp) => mp.siteId = site.id);
            });
          }
          for (const asset of room.assets) {
            if (!asset.monitoringPoints.length) {
              sitesToHide.push(asset.siteId);
            } else {
              sitesToHide = sitesToHide.filter((s) => s !== asset.siteId);
            }
          }
        }
        if (!sitesToHide.includes(site.id)) {
          sites.push(site);
        }
      }
    }
    return sites;
  }
}
</script>

<style >
.treeview .v-treeview-node__toggle {
  width: 2px;
}
.treeview .v-treeview-node__level {
  width: 12px;
}
</style>