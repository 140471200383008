<template>
  <div class="fill-height">
    <v-card class="mb-6 user-card" :color="color" @click="$emit('clicked:user', user)" >
      <v-row justify="space-between" align="center" no-gutters>
        <v-col>
          <v-row class="px-4 py-2" no-gutters align="center">
            <v-col cols="2" class="mr-4 d-flex justify-center">
              <v-avatar :size="$vuetify.breakpoint.lgAndDown ? 42 : 52" color="primary" class="white--text"> {{ user.first_name.charAt(0) }} {{ user.last_name.charAt(0) }} </v-avatar>
            </v-col>
            <v-col class="text-truncate">
              <v-row no-gutters>
                <v-col cols="12">
                  <div>
                    <div :style="width" class=" text-truncate">
                      <span class="font-weight-bold" :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'">{{ user.first_name }} {{ user.last_name }}</span>
                    </div>
                  </div>

                </v-col>
                <v-col cols="12">
                  <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'text-body-2'">{{ user.email }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-icon color="primary" class="mr-2">mdi-chevron-right</v-icon>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import {User} from "@/types";

@Component
export default class UserCard extends Vue {

  @Prop() private user!: User;
  @Prop({default: 'tertiary'}) private color!: string;

  private get width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'max-width: 48vw';
      case 'sm': return 'max-width: 80vw';
      case 'md': return 'max-width: 22vw';
      case 'lg': return 'max-width: 13vw';
      default: return 'max-width: 100vw';
    }
  }
}
</script>

<style scoped>
  .user-card:hover {
    background-color: #f0f0f0 !important;
  }
</style>