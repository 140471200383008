<template>
  <div>
    <div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row class="mb-2">
          <v-col cols="12">
            <v-btn plain :ripple="false" class="text-none" @click="$emit('click:back')"><v-icon left>mdi-chevron-left</v-icon> Go back</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-card :loading="loading" color="tertiary" :class="{'mt-14': $vuetify.breakpoint.mdAndUp}">
        <v-row v-if="loading" justify="center">
          <v-col cols="auto">
            <div class="text-body-2 font-weight-light py-2">Loading user...</div>
          </v-col>
        </v-row>
        <v-row dense class="py-2 px-4" v-if="!loading">
          <v-col cols="12">
            <v-row>
              <v-col>
                <div class="text-h5 font-weight-bold" v-if="!showEditName">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</div>
                <div v-else>
                  <v-form v-model="validName" class="d-flex align-center">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <v-text-field hide-details outlined class="input-height custom-field" v-model="userDetails.firstName" placeholder="First name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field hide-details outlined class="input-height custom-field" v-model="userDetails.lastName" placeholder="Last name" persistent-placeholder :rules="[v => !!v]"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </div>
              </v-col>
              <v-col cols="auto">
                <v-btn icon color="primary" @click="showEditName = true;" v-if="!showEditName"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon color="primary" @click="showEditName = false; resetName()" v-else><v-icon>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-slide-y-transition>

            <v-col cols="12">
              <div class="text-body-1 font-weight-bold">{{ selectedUser.email }}</div>
            </v-col>
          </v-slide-y-transition>
          <v-col cols="12" class="mt-6 text-h6 font-weight-bold">User email alert preference</v-col>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-row no-gutters align="center">
              <v-col>
                <span v-if="userDetails.alertsEnabled">This user <strong>will</strong> receive email alerts.</span>
                <span v-else>This user will <strong>not</strong> receive email alerts.</span>
              </v-col>
              <v-col cols="auto" class="d-flex align-center justify-end">
                <v-switch inset dense color="primary" v-model="userDetails.alertsEnabled"></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-6 text-h6 font-weight-bold">User role</v-col>
          <v-col cols="12" class="d-flex justify-center align-center">
            <v-row align="center" dense>
              <v-col cols="auto" class="d-flex align-center justify-end">
                <v-select :disabled="user && user.id === selectedUser.id" placeholder="Role" :hide-details="true" class="pr-4 input-height custom-field" outlined :items="roles" v-model="selectedRole" item-text="name" item-value="id" @change="cancelNewSiteRole();"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-6 text-h6 font-weight-bold" v-if="isConsumer">User site access</v-col>
          <v-col cols="12" v-if="isConsumer">
            <v-card outlined class="mt-2 pa-2" v-for="siteRole of siteRoles" :key="siteRole.site_id" min-height="50" height="50">
              <v-row no-gutters align="center">
                <v-col>
                  <v-row justify="center" align="center" dense>
                    <v-col cols="auto" class="px-2">
                      <v-icon size="24" color="primary">mdi-map-marker</v-icon>
                    </v-col>
                    <v-col class="text-truncate">
                      {{ sites.find((s) => s.id === siteRole.site_id).name || '' }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col :cols="!editingSiteRole[siteRole.site_id] ? 3 : 12" sm="3" class="d-flex align-center justify-center justify-sm-end">
                  <v-btn icon small v-if="!editingSiteRole[siteRole.site_id]" class="mx-md-3 mx-2"><v-icon size="20" color="secondary" @click="removeSiteRoles(siteRole)">mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="!addingSiteRole && isConsumer && filteredSites.length">
            <v-card outlined class="pa-2" @click="addSiteRole" :ripple="false" min-height="50" height="50">
              <v-row align="center" no-gutters>
                <v-col>
                  <v-row justify="center" align="center" dense>
                    <v-col cols="auto">
                      <v-icon size="32" color="primary">mdi-key-plus</v-icon>
                    </v-col>
                    <v-col class="d-flex justify-start">
                      <span class="font-weight-bold text-body-1">Add site access</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="d-flex align-center fill-height justify-end">
                  <v-icon size="24" color="primary">mdi-chevron-right</v-icon>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col v-if="addingSiteRole && isConsumer">
            <v-card outlined class="pa-2" min-height="50" height="50">
              <v-row no-gutters>
                <v-col cols="10" class="mb-1">
                  <v-row justify="start" align="center" dense>
                    <v-col cols="2" class="px-2">
                      <v-icon size="24" color="primary">mdi-map-marker</v-icon>
                    </v-col>
                    <v-col cols="10" sm="7">
                      <v-select placeholder="Site" :hide-details="true" class="input-height custom-field" outlined :items="filteredSites" item-value="id" item-text="name" v-model="newSiteRole.site_id" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="2" class="d-flex align-center justify-center justify-sm-end">
                  <v-btn icon small :disabled="!addingSiteRole" class="mx-md-3 mx-2"><v-icon size="20" color="primary" @click="cancelNewSiteRole">mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>

            </v-card>
          </v-col>
          <v-col cols="12" class="mt-10">
            <v-row align="center" class="mx-0" dense>
              <v-col cols="12">
                <div class="text-h6 font-weight-bold">
                  Remove user
                </div>
              </v-col>
              <v-col cols="12" class="pb-6 d-flex justify-center justify-md-start">
                <ActionButton :disabled="user && user.id === selectedUser.id" icon="mdi-delete" width="220" height="50" color="secondary" class="white--text" text="Remove this user" @click="deleteDialog = true"></ActionButton>
                <v-dialog
                    v-model="deleteDialog"
                    persistent
                    max-width="420"
                    class="rounded-lg"

                >
                  <v-card class="rounded-lg" color="tertiary">
                    <v-card-title class="text-h6 font-weight-bold text--primary">
                      Remove user
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to remove this user?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          class="text-none"
                          color="primary"
                          plain
                          @click="deleteDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          type="submit"
                          class="text-none"
                          color="secondary"
                          plain
                          :loading="deleteLoading"
                          :disabled="deleteLoading"
                          @click="removeUser(selectedUser.id)"
                      >
                        Confirm
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex align-center justify-center mt-4">
            <ActionButton color="primary" text="Save user" width="220" height="50" @click="saveEditUser" :loading="saving" :disabled="saving || !validName || disableSave"/>
          </v-col>
        </v-row>


        <template slot="progress">
          <v-progress-linear
              color="secondary"
              height="5"
              indeterminate
          ></v-progress-linear>
        </template>
      </v-card>
    </div>
  </div>
</template>

<script lang="ts">
import {Role, Site, User} from "@/types";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Select from "@/components/Select.vue";
import SiteService from "@/services/site.service";
import {Getter} from "vuex-class";
import ActionButton from "@/components/ActionButton.vue";
import UserService from "@/services/user.service";
@Component({
  components: {ActionButton, Select}
})
export default class UserDetails extends Vue {
  @Getter private accountId!: number;
  @Getter private user!: any;
  @Prop() private roles!: Role[];
  @Prop() private sites!: Site[];
  @Prop() private selectedUser!: User;
  private deleteLoading = false;
  private deleteDialog = false;
  private loading = false;
  private isConsumer = false;
  private validName = true;
  private showEditName = false;
  private userDetails: { firstName: string; lastName: string; alertsEnabled: boolean } = {
    firstName: '',
    lastName: '',
    alertsEnabled: true
  };
  private saving = false;
  private selectedRole = 0;
  private addingSiteRole = false;
  private editingSiteRole: {[key: number]: boolean} = {};
  private newSiteRole: {site_id: number; roles: number[]} = { site_id: 0, roles: [] };
  private siteRoles: Array<{site_id: number; roles: number[]}> = [];

  @Watch('selectedRole')
  private onRoleChange(newVal: number) {
    if (newVal === 1) {
      this.isConsumer = false;
      Vue.set(this, 'siteRoles', [])
    } else {
      this.isConsumer = true;
    }
  }

  private async mounted() {
    if (this.selectedUser.sites && this.selectedUser.sites.length) {
      this.siteRoles = this.selectedUser.sites;
      this.selectedRole = 2;
      this.isConsumer = this.selectedRole === 2;
    } else if (this.selectedUser.roles?.includes(1)) {
      this.selectedRole = 1;
    }
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.selectedUser.alerts_enabled
    }
    this.isConsumer = !!this.selectedUser.sites?.length;

  }

  private async addSiteRole() {
    this.addingSiteRole = true;
  }

  private cancelNewSiteRole() {
    this.addingSiteRole = false;
    this.newSiteRole = { site_id: 0, roles: [] };
  }

  private async saveEditUser() {
    this.saving = true;
    if (this.newSiteRole.site_id) {
      Vue.set(this.newSiteRole, 'roles', [2]);

      this.siteRoles.push(this.newSiteRole)
    }
    if (this.selectedUser.id) {
      const payload = {
        user: {
          first_name: this.userDetails.firstName,
          last_name: this.userDetails.lastName,
          alerts_enabled: this.userDetails.alertsEnabled
        },
        roles: this.isConsumer ? null : [1],
        sites: this.isConsumer ? this.siteRoles : [],
      }

      await UserService.updateUser(this.accountId, this.selectedUser.id, payload);

      this.addingSiteRole = false;
      this.showEditName = false;
      this.newSiteRole = { site_id: 0, roles: [] };

      this.$emit('refresh:user', this.selectedUser.id)
      this.saving = false;
    }
  }

  private resetName() {
    this.userDetails = {
      firstName: this.selectedUser.first_name,
      lastName: this.selectedUser.last_name,
      alertsEnabled: this.userDetails.alertsEnabled
    }
  }

  private removeSiteRoles(role: { site_id: number, roles: number[] }) {
    this.siteRoles = this.siteRoles.filter((sr) => sr.site_id !== role.site_id);
  }

  private async removeUser(userId: number) {
    this.deleteLoading = true
    try {
      await UserService.removeUser(this.accountId, userId)
      this.deleteDialog = false;
    } finally {
      this.$emit('remove:user')
      this.deleteLoading = false;
    }
  }

  private get disableSave(): boolean {
    return !(this.siteRoles.length || !!this.newSiteRole.site_id) && !!this.isConsumer;
  }

  private get filteredSites() {
    return this.sites.filter((s) => !this.siteRoles.map((sr) => sr.site_id).includes(s.id))
  }
}
</script>

<style>

.input-height .v-input__control > .v-input__slot {
  height: 32px;
  min-height: 32px !important;
}
.input-height.v-text-field .v-input__append-inner {
  margin-top: 4px !important;
}
</style>