<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" lg="6" class="pa-4">
      <v-card color="tertiary" class="px-4 py-6">
        <v-row no-gutters align="center">
          <v-col cols="auto">
            <v-btn @click="$emit('go-to:email'); $emit('clear:error'); password = ''" icon><v-icon color="white">mdi-arrow-left</v-icon></v-btn>
          </v-col>
          <v-col class="text-center">
            <span class="white--text text-body-1 font-weight-bold" v-if="activated">Enter your password</span>
            <span class="white--text text-body-1 font-weight-bold" v-else-if="!activated && !isNew">Enter your registration code</span>
            <span class="white--text text-body-1 font-weight-bold" v-if="!activated && isNew">Enter your new password</span>
          </v-col>
          <v-col cols="auto">
            <v-sheet width="36" height="36" color="transparent"></v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LoginError :error="error" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="pb-0">
            <v-form v-if="!isNew" @submit.prevent="$emit('check:password', password)">
              <v-text-field
                  autofocus
                  v-if="activated"
                  class="custom-field"
                  :placeholder="'Password'"
                  persistent-placeholder
                  outlined
                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value = !value)"
                  :type="value ? 'password' : 'text'"
                  v-model="password"
                  :rules="[v => !!v && !!v.length]"
                  @input="$emit('clear:error')"
                  autocomplete="current-password">
              </v-text-field>
              <v-text-field
                  v-else
                  class="custom-field"
                  placeholder="Registration code"
                  persistent-placeholder
                  outlined
                  background-color="#ffffff"
                  v-model="code"
                  @input="$emit('clear:error')"
                  autocomplete="new-password">
              </v-text-field>
            </v-form>

            <v-form v-if="isNew" v-model="passwordValid" ref="passwordForm" @submit.prevent="$emit('check:password', password)">
              <v-text-field
                  autofocus
                  class="custom-field"
                  placeholder="New password"
                  persistent-placeholder
                  outlined
                  background-color="#ffffff"
                  :append-icon="value['new'] ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value['new'] = !value['new'])"
                  :type="value['new'] ? 'password' : 'text'"
                  @input="$refs.repeatPasswordForm.validate()"
                  v-model="password"
                  :rules="[
                      (v) => (v && v.length >= 6) || 'Password must be at least 6 characters long.',
                      (v) => (v && v.toUpperCase() !== v) || 'Password must contain a lowercase character.',
                      (v) => (v && v.toLowerCase() !== v) || 'Password must contain an uppercase character.',
                      (v) => (v && /\d/.test(v)) || 'Password must contain a number.',
                      (v) => (v && symbols.test(v)) || 'Password must contain a special character.',
                  ]"
                  autocomplete="new-password"></v-text-field>
            </v-form>
            <v-form v-if="isNew" v-model="repeatValid" ref="repeatPasswordForm" @submit.prevent="$emit('check:password', password)">
              <v-text-field
                  class="custom-field"
                  placeholder="Repeat new password"
                  persistent-placeholder
                  outlined
                  background-color="#ffffff"
                  :append-icon="value['repeat'] ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value['repeat'] = !value['repeat'])"
                  :type="value['repeat'] ? 'password' : 'text'"
                  v-model="repeatedPassword"
                  @input="$refs.repeatPasswordForm.validate()"
                  :rules="[
                      (v) => !passwordValid || v === password || 'Passwords do not match.'
                  ]"
                  autocomplete="new-password"></v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="text-center pt-0">
            <a @click="$emit('go-to:forgot-password')" style="color: #f2243f" v-if="activated">Forgot your password?</a>
            <div v-else-if="!isNew" class="white--text">Don't have your registration code? <br>  <a @click="$emit('go-to:resend-registration')" style="color: #f2243f">Resend it.</a> </div>
          </v-col>
          <v-col class="d-flex justify-center">
            <ActionButton width="95" @click="$emit('check:password', password)" text="Confirm" color="secondary" :loading="loading" :disabled="confirmedDisabled || loading"></ActionButton>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class PasswordPrompt extends Vue {
  @Prop() private error!: string;
  @Prop() private activated!: boolean;
  @Prop({default: false}) private isNew!: boolean;
  @Prop() private queryCode!: string;
  @Prop() private loading!: boolean;
  private symbols = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
  private code = '';
  private password = '';
  private repeatedPassword = '';
  private value = {
    repeat: true,
    new: true,
  };
  private passwordValid = true;
  private repeatValid = true;

  private get confirmedDisabled() {
    if (this.isNew) {
      if (this.activated) {
        return !this.code;
      } else {
        return !this.passwordValid || !this.repeatValid || !this.password || !this.repeatedPassword;
      }
    } else {
      return !this.password;
    }
  }

  @Watch('queryCode', {immediate: true})
  private onQueryCodeUpdate() {
    this.code = this.queryCode
  }

  @Watch('code', {immediate: true})
  private onCodeUpdate() {
    this.password = this.code
  }

}
</script>

<style scoped>

</style>