<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" lg="6" class="pa-4">
      <v-card color="tertiary" class="px-4 py-6">
        <v-row class="pb-40" justify="space-around">
          <v-col cols="12" class="text-center">
            <span class="white--text text-h5 font-weight-bold">Log in</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <LoginError :error="error" />
          </v-col>
        </v-row>
        <v-form @submit.prevent="$emit('check:email', username)" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-text-field
                  autofocus
                  class="custom-field"
                  placeholder="Email address"
                  persistent-placeholder
                  outlined
                  v-model="username"
                  @input="$emit('clear:error')"
                  :rules="[v => !!v && v.length && v.includes('@') || false]"
                  autocomplete="username"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="relative d-flex justify-center">
              <ActionButton width="95" type="submit" text="Confirm" color="secondary" :disabled="!username || !valid || loading" :loading="loading"></ActionButton>
            </v-col>
          </v-row>
        </v-form>

      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginError from "@/components/LoginError.vue";
@Component({
  components: {LoginError, ActionButton}
})
export default class EmailPrompt extends Vue {
  @Prop() private queryUsername!: string;
  @Prop() private error!: string;
  @Prop() private loading!: boolean;
  private username = '';
  private valid = false;

  @Watch('queryUsername', {immediate: true})
  private onQueryParamUpdate()  {
    this.username = this.queryUsername;
  }
}
</script>

<style scoped>
</style>