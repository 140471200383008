import Vue from 'vue';
import Vuex from "vuex";
import router from "@/router";
import { appAxios } from '../app.axios';
import { Route } from 'vue-router';
import UserService from '../services/user.service';
import { v4 as uuidv4 } from 'uuid';
import ApplicationService from "@/services/application.service";

Vue.use(Vuex);
interface IState {
  appLoading?: boolean;
  accountId?: number | null;
  user?: any;
  refrigerationToken: string | null;
  preLoginRoute: string | null;
  permissions: string[];
  toastErrors: any;
  accountCode: string | undefined;
  accountManaged: boolean;
  showKB: boolean;
  debugMode: boolean;
  themeLoaded: boolean;
  applicationAttributes?: any;
}

const state: IState = {
    appLoading: false,
    accountId: null,
    user: null,
    refrigerationToken: null,
    preLoginRoute: null,
    permissions: [''],
    toastErrors: {},
    accountCode: '',
    accountManaged: false,
    showKB: false,
    debugMode: false,
    themeLoaded: false,
    applicationAttributes: null
  }

const store = new Vuex.Store({ state,
  getters: {
    appLoading: (state => state.appLoading),
    accountId: (state => state.accountId),
    accountCode: (state => state.accountCode),
    isLoggedIn: state => {
      return !!state.refrigerationToken;
    },
    refrigerationToken: (state => state.refrigerationToken),
    preLoginRoute: (state => state.preLoginRoute),
    user: (state => state.user),
    permissions: (state) => state.permissions,
    hasPermission: (state) => (permission: string) => {
      return state.permissions.includes(permission);
    },
    toastErrors: (state) => state.toastErrors,
    accountManaged: (state) => state.accountManaged,
    showKB: (state) => state.showKB,
    debugMode: (state => state.debugMode),
    applicationAttributes: (state => state.applicationAttributes),
    themeLoaded: (state => state.themeLoaded),
  },
  mutations: {
    setAccountManaged(state, accountManaged) {
      state.accountManaged = accountManaged;
      setLocalStorage('accountManaged', accountManaged);
    },
    toggleLoading(state, loading) {
      state.appLoading = loading;
    },
    setThemeLoaded(state, themeLoaded) {
      state.themeLoaded = themeLoaded;
    },
    setToken(state, token){
      state.refrigerationToken = token;
      appAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
      setLocalStorage('token', token);
    },
    removeToken(state) {
      state.refrigerationToken = null;
      localStorage.removeItem('token')
    },
    setPreLoginRoute(state, route) {
      state.preLoginRoute = route;
    },
    setUser(state, user) {
      state.user = user;
      if (!state.accountManaged && !state.accountId) {
        state.accountId = user.account_id;
        state.accountCode = user.account_code;
        setLocalStorage('accountId', user.account_id);
        setLocalStorage('accountCode', user.account_code);
      }
      const permissions = ['application:read'];
      if (user.roles?.length) {
        permissions.push('application:manage');
      }
      if (user.roles.includes(3)) {
        permissions.push('administration:read');
        permissions.push('administration:manage');
      }

      state.permissions = permissions;
    },
    setAccountId(state, {accountId, accountCode}) {
      accountId = accountId ? Number(accountId) : null;
      accountCode = accountCode ? accountCode: '';
      state.accountId = accountId;
      state.accountCode = accountCode;
      setLocalStorage('accountId', accountId);
      setLocalStorage('accountCode', accountCode);
    },
    removeUser(state) {
      state.user = null;
      state.accountId = null;  
    },
    addToastError(state, msg) {
      const messages: {[id: string]: string} = {};
      Object.assign(messages, state.toastErrors);
      messages[msg.key] = msg.message;
      state.toastErrors = messages;
    },
    removeToastError(state: any, key: any) {
      delete state.toastErrors[key];
    },
    toggleKB(state, show) {
      state.showKB = show;
    },
    setDebugMode(state: any, { mode, value }) {
      if (mode === 'new_hierarchy') {
        state.debugMode = value;
        return;
      }
      state.debugMode = false;
    },
    setApplicationAttributes(state, attributes) {
      state.applicationAttributes = attributes;
    }
  },
  actions: {
    async goHome() {
      await router.push({ name: 'home' });
    },
    async userLogout(context) {
      context.commit('removeToken');
      context.commit('removeUser');
      context.commit('setAccountManaged', false);
      context.commit('setAccountId', {accountId: null, accountCode: null});
      await router.replace({ name: 'login' });
    },
    async userLogin(context, jwt) {
      context.commit('setToken', jwt);
      let routeName = 'home';
      if (context.state.preLoginRoute) {
        const route: Route = (context.state.preLoginRoute as unknown) as Route;
        if (route.name) {
          routeName = route.name;
        }
        context.commit('setPreLoginRoute', null);
      }
      await context.dispatch('refreshUser');

      /*
      if (context.state.user?.roles.includes(3) && !process.env.VUE_APP_EMBED_APPLICATION) { // TODO: Replace this with hasPermission once permissions are fetched from API
        routeName = 'customer-accounts';
      }
      */

      await router.replace({ name: routeName });
    },
    async refreshUser(context: any) {
      const user = await UserService.getCurrentUser();

      context.commit('setUser', user);
    },
    showToastError(context, message) {
      const key: string = uuidv4();
      context.commit('addToastError', {key, message});
      return key;
    },
    async fetchApplicationAttributes(context) {
      const { application_attributes } = await ApplicationService.getAttributes();
      context.commit('setApplicationAttributes', application_attributes);
    }
  }
})

function setLocalStorage(key: string, value: any) {
  if (value === null || value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}

export default store;
