<template>
  <v-row no-gutters class="fill-height" justify="center">
    <v-col cols="12" class="d-flex justify-center fill-height">
      <v-row justify="center" align="center" no-gutters>
        <v-col class="mx-auto">
          <p class="text-center font-weight-bold text-404">404</p>
          <div class="font-weight-bold text-h5 text-center">We couldn't find the page you were looking for.</div>
        </v-col>
        <v-col cols="12">
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class NotFound extends Vue {
  private get landscapeLogoSrc() {
    return require('@/assets/UK-Engineers-Logo_landscape.png')
  }
}
</script>

<style scoped>
.text-404 {
  font-size: 12rem;
  line-height: 1;
  color: #d2d2d2 !important;
}
</style>