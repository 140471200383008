import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[(_vm.$route.path.startsWith('/') && !_vm.$route.path.endsWith('login') && !_vm.$route.path.endsWith('reset'))?_c('Layout'):_vm._e(),(_vm.$route.path.endsWith('login'))?_c('LoginFlow'):_vm._e(),(_vm.$route.path.endsWith('reset'))?_c('ActivationFlow'):_vm._e(),_c('NotificationSnackbar',{attrs:{"open":_vm.snackbarOpened,"icon":_vm.snackbarIcon,"text":_vm.snackbarText}}),_c(VMain,{attrs:{"app":""}},[_c('div',{staticClass:"kb-dialog"},[_c(VDialog,{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showKB),callback:function ($$v) {_vm.showKB=$$v},expression:"showKB"}},[_c(VCard,{attrs:{"color":"background"}},[_c(VToolbar,{attrs:{"light":"","color":"background","elevation":"0","tile":""}},[_c(VTabs,[_c(VTab,{on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Knowledge base ")])],1),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.toggleKB(false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c('KnowledgeBase')],1)],1)],1),_c('div',{staticClass:"fab"},[_c(VBtn,{attrs:{"fab":"","elevation":"2","color":"primary","small":""},on:{"click":function($event){return _vm.toggleKB(true)}}},[_c(VIcon,[_vm._v("mdi-help")])],1)],1),_c('div',{staticClass:"error-toast"},_vm._l((_vm.toastErrors),function(message,key){return _c('ErrorToast',{key:key,ref:key,refInFor:true,attrs:{"heading":"Sorry, an error occurred:","body":message},on:{"update:showToastBoolean":function($event){return _vm.checkRemoveNotification($event, key)}}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }