<template>
  <div :class="{ 'full-height': !$embedApplication && $vuetify.breakpoint.mdAndUp, 'without-app-bar': $vuetify.breakpoint.smAndDown, 'with-app-bar': $embedApplication && $vuetify.breakpoint.mdAndUp}">
    <Loader />
    <div class="px-6 content-container" v-if="!appLoading">
      <v-row no-gutters class="content-container">
        <v-col cols="12">
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="4" md="5" class="mt-14">
                <v-row v-if="!showUserDetails">
                  <v-col cols="12">
                    <v-card color="primary">
                      <ActionButton height="40" block class="white--text" text="Add a new super user" @click="$router.push({name: 'create-super-user'})"/>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-if="!showUserDetails">
                  <v-col cols="12">
                    <v-autocomplete dense height="44" outlined class="custom-field" placeholder="Search super users" persistent-placeholder prepend-inner-icon="mdi-magnify" item-value="id" :filter="customSearch" v-model="userSearch" :items="superUsers" return-object @input="userDetails = $event; updateDetails++">
                      <template v-slot:selection="{ item }">
                        {{ item.first_name }} {{ item.last_name }}
                      </template>
                      <template v-slot:item="{ item }">
                        {{ item.first_name }} {{ item.last_name }} <v-spacer></v-spacer> ({{ item.email }})
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="superUsers.length">
                  <v-col cols="12">
                    <div v-for="user of superUsers" :key="user.id">
                      <UserCard :user="user" @clicked:user="userDetails = $event; updateDetails++" :color="user.email === userDetails.email ? 'rgba(50,55,75,0.12)' : 'tertiary'"></UserCard>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave v-if="superUsers.length">
              <v-col cols="12" lg="8" md="7">
                <SuperUserDetails :selected-user="userDetails" @refresh:user="getSuperUsers" :key="updateDetails" @remove:user="getSuperUsers"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
          <v-row v-else-if="superUsers.length && $vuetify.breakpoint.smAndDown" class="mt-4">
            <v-col cols="12" v-if="!showUserDetails">
              <v-card color="primary">
                <ActionButton height="40" block class="white--text" text="Add a new super user" @click="$router.push({name: 'create-super-user'})"/>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="!showUserDetails">
              <v-autocomplete @change="userDetails = $event; showUserDetails = true;" hide-details dense height="44" outlined class="custom-field" placeholder="Search super users" persistent-placeholder prepend-inner-icon="mdi-magnify" item-value="id" :filter="customSearch" v-model="userSearch" :items="superUsers" return-object @input="userDetails = $event; updateDetails++">
                <template v-slot:selection="{ item }">
                  {{ item.first_name }} {{ item.last_name }}
                </template>
                <template v-slot:item="{ item }">
                  {{ item.first_name }} {{ item.last_name }} <v-spacer></v-spacer> ({{ item.email }})
                </template>
              </v-autocomplete>
            </v-col>
            <v-slide-x-reverse-transition hide-on-leave v-if="!showUserDetails">
              <v-col cols="12" md="12" lg="5">
                <div v-for="user of superUsers" :key="user.id">
                  <UserCard :user="user" @clicked:user="userDetails = $event; showUserDetails = true;"></UserCard>
                </div>
              </v-col>
            </v-slide-x-reverse-transition>
            <v-slide-x-reverse-transition hide-on-leave>
              <v-col cols="12" lg="8" md="12">
                <SuperUserDetails v-if="showUserDetails" :selected-user="userDetails" @refresh:user="getSuperUsers" @click:back="showUserDetails = false; userDetails = null; userSearch = '';" @remove:user="showUserDetails = false; getSuperUsers()"/>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import ActionButton from "@/components/ActionButton.vue";
import UserCard from "@/components/UserCard.vue";
import SuperUserDetails from "@/views/admin/SuperUserDetails.vue";
import {Getter, Mutation} from "vuex-class";
import {User} from "@/types";
import AdminService from "@/services/admin.service";

@Component({
  components: {SuperUserDetails, UserCard, ActionButton, Loader}
})
export default class SuperUserManagement extends Vue {
  @Getter private hasPermission!: any;
  @Getter private appLoading!: boolean;
  @Getter private accountId!: number;
  @Mutation private toggleLoading!: any;
  private showUserDetails = false;
  private superUsers: User[] = [];
  private updateDetails = 0;
  private userDetails: User | null = null;
  private userSearch = '';

  @Watch('userSearch')
  private onSearch() {
    if (!this.userSearch) {
      this.userDetails = this.superUsers[0];
    }
  }

  private async mounted() {
    this.toggleLoading(true);

    try {
      await this.getSuperUsers();
    } finally {
      this.toggleLoading(false);
    }
  }

  private async getSuperUsers(userId: number | null = null) {
    this.superUsers = await AdminService.getSuperUsers();
    if (userId) {
      const user = this.superUsers.find((u) => u.id === userId);
      if (user) {
        this.userDetails = user;
      }
    } else {
      this.userDetails = this.superUsers[0];
      ++this.updateDetails;
    }
  }

  private get customSearch() {
    return (item: any, search: string) => {
      if (item) {
        return (item.first_name.toLowerCase() && item.first_name.toLowerCase().indexOf(search) > -1) ||
            (item.last_name.toLowerCase() && item.last_name.toLowerCase().indexOf(search) > -1) ||
            (item.email.toLowerCase() && item.email.toLowerCase().indexOf(search) > -1);
      } else {
        return false;
      }
    };
  }
}
</script>

<style scoped>

</style>