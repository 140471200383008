<template>
  <div class="overflow-x-hidden" id="main">
    <div>
      <v-stepper v-model="activeStep" flat class="no-transition background">
        <v-stepper-items>
          <v-stepper-content :step="GatewayWizardSteps.FindGateway">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row>
                  <v-col cols="12">
                    <p class="font-weight-bold text-h5 text-center">Identify your gateway</p>
                  </v-col>
                </v-row>
                <v-row justify="center" v-if="!!gateways && gateways.length">
                  <v-col cols="12" class="py-0 mt-6">
                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0">Select the gateway from the list below</p>
                  </v-col>
                  <v-col cols="12" md="8" sm="10">
                    <v-radio-group v-model="selectedGateway" class="my-0">
                      <v-card v-for="(g, i) of gateways" :key="`${g.eui}_${i}`" outlined class="py-2 px-2 my-2" @click="selectGateway(g)" color="tertiary">
                        <v-row dense justify="center" align="center">
                          <v-col cols="4" md="4" sm="4" class="d-flex align-center justify-center">
                            <v-radio
                                class="pa-2"
                                :value="g.eui"
                            ></v-radio>
                          </v-col>
                          <v-col cols="8" sm="8">
                            <span class="font-weight-bold pr-2">DevEUI:</span> <span>{{ g.eui }}</span>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-col cols="12" md="8" sm="10" class="text-center mb-4 mt-n4">
                      <v-dialog max-width="600" v-model="euiDialog" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-row no-gutters justify="center" align="center">
                            <v-col cols="auto">
                              <v-icon color="secondary">mdi-help-circle</v-icon>
                            </v-col>
                            <v-col cols="auto">
                              <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 text-center mx-4 secondary--text d-flex align-center" @click="zoomImg['eui'] = true">What is your DevEUI? </a>
                            </v-col>
                          </v-row>
                        </template>
                        <v-card color="tertiary">
                          <v-row no-gutters justify="end">
                            <v-col cols="12" class="d-flex align-center justify-center">
                              <v-card flat width="100%" class="overflow-hidden" height="365" :class="{'pa-6': !zoomImg['eui']}" color="white">
                                <div style="height: calc(365px - 32px)">
                                  <v-img :src="gatewayDevEui" alt="Gateway DevEUI" :height="zoomImg['eui'] ? 1300 : 350" :position="zoomImg['eui'] ? '50% -500px' : 'center center'"/>
                                </div>
                                <v-btn class="mt-n6 mr-4 float-right" :class="{'mt-n12 mr-n2': !zoomImg['eui']}" icon @click="zoomImg['eui'] = !zoomImg['eui']" :color="zoomImg['eui'] ? 'white' : 'black'"><v-icon>{{zoomImg['eui'] ? 'mdi-magnify-minus' : 'mdi-magnify-plus'}}</v-icon></v-btn>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-col>
                </v-row>
<!--                <v-row justify="center" class="mt-10">-->
<!--                  <v-col cols="12">-->
<!--                    <p class="font-weight-regular text-body-1 text-center mx-4 mb-0">Or scan the QR code on your gateway</p>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--                <v-row justify="center">-->
<!--                  <v-col cols="12" class="d-flex justify-center py-0">-->
<!--                    <ActionButton class="mb-4" text="Scan QR code" icon="mdi-qrcode"></ActionButton>-->
<!--                  </v-col>-->
<!--                  <v-col cols="12" md="8" sm="10">-->
<!--                    <v-dialog max-width="600" v-model="qrDialog" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'">-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-row no-gutters justify="center" align="center">-->
<!--                          <v-col cols="auto">-->
<!--                            <v-icon color="secondary">mdi-help-circle</v-icon>-->
<!--                          </v-col>-->
<!--                          <v-col cols="auto">-->
<!--                            <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 text-center mx-4 secondary&#45;&#45;text d-flex align-center" @click="zoomImg['qr'] = true">Need help finding your QR code? </a>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </template>-->
<!--                      <v-card>-->
<!--                        <v-row no-gutters justify="end">-->
<!--                          <v-col cols="12" class="d-flex align-center justify-center">-->
<!--                            <v-card flat width="100%" class="overflow-hidden" height="365" :class="{'pa-6': !zoomImg['qr']}">-->
<!--                              <div style="height: calc(365px - 32px)">-->
<!--                                <v-img :src="gatewayQrCode" alt="Gateway DevEUI" :height="zoomImg['qr'] ? 1300 : 350" :position="zoomImg['qr'] ? '50% -500px' : 'center center'"/>-->
<!--                              </div>-->
<!--                              <v-btn class="mt-n6 mr-4 float-right" :class="{'mt-n12 mr-n2': !zoomImg['qr']}" icon @click="zoomImg['qr'] = !zoomImg['qr']" :color="zoomImg['qr'] ? 'white' : 'primary'"><v-icon>{{zoomImg['qr'] ? 'mdi-magnify-minus' : 'mdi-magnify-plus'}}</v-icon></v-btn>-->
<!--                            </v-card>-->
<!--                          </v-col>-->
<!--                        </v-row>-->
<!--                      </v-card>-->
<!--                    </v-dialog>-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row justify="center">
                  <v-col cols="12" md="8" sm="10">

                    <v-alert flat color="transparent" class="mb-6 border-alert text-center primary--text">
                      <span v-if="!!gateways && gateways.length">Don't see your gateway DevEUI in the list? <br/> Visit our knowledge base for troubleshooting help, so we can get you up and running.</span>
                      <span v-else-if="!!gateways && !gateways.length">No gateways available. <br/> Visit our knowledge base for troubleshooting help, so we can get you up and running.</span>

                      <v-row justify="center" class="mt-6">
                        <v-col cols="auto">
                          <ActionButton @click="toggleKB(true)" width="220" height="50" color="secondary" text="Go to knowledge base"/>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="$route.params.siteId ? $router.push({name : 'devices'}) : $emit('go-to:site-wizard')" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <v-row no-gutters justify="center" align="center" class="flex-column">
                      <v-col cols="auto" class="mb-4">
                        <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepForward" :disabled="!selectedGateway" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                      </v-col>
                      <v-col cols="auto" class="text-center">
                        <v-dialog v-model="skipGatewayDialog" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'"
                                  max-width="450"
                                  class="rounded-lg">
                          <template v-slot:activator="{ on, attrs }">
                            <v-row no-gutters justify="center" align="center">
                              <v-col cols="auto">
                                <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 secondary--text">Skip adding a gateway</a>
                              </v-col>
                            </v-row>
                          </template>
                          <v-card class="rounded-lg" color="tertiary">
                            <v-card-title class="text-h6 font-weight-bold text--primary">
                              Confirm skip gateway
                            </v-card-title>
                            <v-card-text class="mt-4">
                              <p class="font-weight-regular text-body-1 mt-4">You are skipping the installation of a gateway at this site. Please make sure there is a gateway within 50 metres to ensure reliable network coverage.</p>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  class="text-none"
                                  color="secondary"
                                  text
                                  @click="skipGatewayDialog = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  @click="$emit('complete:gateway-wizard'); skipGatewayDialog = false"
                                  type="submit"
                                  class="text-none"
                                  color="primary"
                                  text
                              >
                                Confirm
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="GatewayWizardSteps.GatewaySetup">
            <v-row no-gutters class="content-container" align="center">
              <v-col cols="12" md="6">
                <v-row justify="center">
                  <v-col cols="10">
                    <p class="font-weight-bold text-h5 text-center">Set up your gateway</p>
                  </v-col>
                  <v-col cols="12" md="10">
                    <v-stepper flat v-model="gatewayConnectionSteps" class="background">
                      <v-stepper-items>
                        <v-stepper-content class="pa-0 ma-0" v-for="step in instructions" :step="step.step" :key="step.img">
                          <v-row justify="center">
                            <v-col cols="auto">
                              <v-card flat outlined max-width="400" max-height="400" min-height="300" min-width="300" color="tertiary">
                                <v-img :src="step.img" max-width="400" max-height="400">
                                  <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                      <v-progress-circular
                                          indeterminate
                                          color="primary"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row no-gutters justify="center" align="center" class="mb-4">
                            <v-col cols="2" class="d-flex justify-center">
                              <v-btn icon @click="gatewayConnectionSteps--" v-if="gatewayConnectionSteps !== 0">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="auto" class="text-center">
                              {{ `Step ${step.step + 1}` }}
                            </v-col>
                            <v-col cols="2" class="d-flex justify-center">
                              <v-btn icon @click="gatewayConnectionSteps++" v-if="instructions.length - 1 !== gatewayConnectionSteps">
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <p class="px-4 text-center">{{ step.text }}</p>
                              <p class="px-4 text-center">{{ step.auxText }}</p>
                            </v-col>
                          </v-row>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepBack" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="stepForward" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content :step="GatewayWizardSteps.GatewayFound">
            <v-row class="content-container" no-gutters align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="font-weight-bold text-h5 text-center">We found {{ gateways.length > 1 ? 'this' : 'one' }} gateway</p>
                    <p class="font-weight-regular text-body-1 text-center">Confirm that this is the correct gateway</p>
                  </v-col>
                  <v-col cols="10" class="d-flex justify-center">
                    <div>
                      <v-img class="mb-10" :src="gatewayImg" contain width="175"></v-img>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <p class="font-weight-bold text-body-1 text-center">{{ gateway.name }}</p>
                    <p class="font-weight-bold text-body-1 text-center">DevEUI: <span class="text-body-1 font-weight-regular"> {{ gateway.eui }}</span></p>
                  </v-col>
                  <v-col cols="12" md="8" sm="10" class="text-center mb-4 mt-n4">
                    <v-dialog max-width="600" v-model="euiDialog" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-row no-gutters justify="center" align="center">
                          <v-col cols="auto">
                            <v-icon color="secondary">mdi-help-circle</v-icon>
                          </v-col>
                          <v-col cols="auto">
                            <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 text-center mx-4 secondary--text d-flex align-center" @click="zoomImg['eui'] = true">What is your DevEUI? </a>
                          </v-col>
                        </v-row>
                      </template>
                      <v-card>
                        <v-row no-gutters justify="end">
                          <v-col cols="12" class="d-flex align-center justify-center">
                            <v-card flat width="100%" class="overflow-hidden" height="365" :class="{'pa-6': !zoomImg['eui']}">
                              <div style="height: calc(365px - 32px)">
                                <v-img :src="gatewayDevEui" alt="Gateway DevEUI" :height="zoomImg['eui'] ? 1300 : 350" :position="zoomImg['eui'] ? '50% -500px' : 'center center'"/>
                              </div>
                              <v-btn class="mt-n6 mr-4 float-right" :class="{'mt-n12 mr-n2': !zoomImg['eui']}" icon @click="zoomImg['eui'] = !zoomImg['eui']" :color="zoomImg['eui'] ? 'white' : 'primary'"><v-icon>{{zoomImg['eui'] ? 'mdi-magnify-minus' : 'mdi-magnify-plus'}}</v-icon></v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" v-if="gateways.length > 1">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="$route.params.siteId ? $router.push({name : 'devices'}) : (gateways.length > 1 ? stepBack() : $emit('go-to:site-wizard'))" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="mountGatewayAndStepForward" :loading="loadingMountGateway" :disabled="loadingMountGateway" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" v-else>
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="auto">
                    <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="$route.params.siteId ? $router.push({name : 'devices'}) : (gateways.length > 1 ? stepBack() : $emit('go-to:site-wizard'))" icon="mdi-arrow-left" text="Go back"></ActionButton>
                  </v-col>
                  <v-col cols="auto">
                    <v-row no-gutters justify="center" align="center" class="flex-column">
                      <v-col cols="auto" class="mb-4">
                        <ActionButton :width="$vuetify.breakpoint.smAndDown ? 130 : 220" height="50" @click="mountGatewayAndStepForward" :loading="loadingMountGateway" :disabled="loadingMountGateway" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                      </v-col>
                      <v-col cols="auto" class="text-center">
                        <v-dialog v-model="skipGatewayDialog" :content-class="$vuetify.breakpoint.lgAndUp && !$embedApplication ? 'center-dialog' : 'revert-center-dialog'"
                                  max-width="450"
                                  class="rounded-lg">
                          <template v-slot:activator="{ on, attrs }">
                            <v-row no-gutters justify="center" align="center">
                              <v-col cols="auto">
                                <a v-on="on" v-bind="attrs" class="font-weight-regular text-body-1 secondary--text">Skip adding a gateway</a>
                              </v-col>
                            </v-row>
                          </template>
                          <v-card class="rounded-lg" color="tertiary">
                            <v-card-title class="text-h6 font-weight-bold text--primary">
                              Confirm skip gateway
                            </v-card-title>
                            <v-card-text class="mt-4">
                              <p class="font-weight-regular text-body-1 mt-4">You are skipping the installation of a gateway at this site. Please make sure there is a gateway within 50 metres to ensure reliable network coverage.</p>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                  class="text-none"
                                  color="secondary"
                                  text
                                  @click="skipGatewayDialog = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  @click="$emit('complete:gateway-wizard'); skipGatewayDialog = false"
                                  type="submit"
                                  class="text-none"
                                  color="primary"
                                  text
                              >
                                Confirm
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content :step="GatewayWizardSteps.TestConnection">
            <v-row no-gutters class="content-container" align="center">
              <v-col cols="12" md="6" class="wrapper">
                <v-row class="mx-4 no-gutters" justify="center" align="center">
                  <v-col cols="12" class="centerpiece align-self-end"  v-if="testingConnection">
                    <p class="font-weight-bold text-h5 text-center">Testing the connection</p>
                    <v-row justify="center">
                      <v-col cols="6">
                        <v-progress-linear indeterminate></v-progress-linear>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!testingConnection && gatewayConnected">
                    <p class="font-weight-bold text-h5 text-center">Success!</p>
                    <p class="font-weight-bold text-h5 text-center">The gateway is connected</p>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon size="200" color="success">mdi-check-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!testingConnection && !gatewayConnected">
                    <p class="font-weight-bold text-h5 text-center">Failed to determine gateway connection status</p>
                    <p class="font-weight-regular text-h6 text-center">Visit our knowledge base for troubleshooting help</p>
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-icon size="200" color="secondary">mdi-help-circle-outline</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-if="!testingConnection && !gatewayConnected" class="d-flex justify-center mt-6">
                    <ActionButton width="220" height="50" @click="toggleKB(true)" color="secondary" text="Go to knowledge base"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="mb-16" no-gutters justify-md="space-around" justify="space-between">
                  <v-col cols="12" v-if="!testingConnection && gatewayConnected" class="d-flex justify-center">
                    <ActionButton width="220" height="50" @click="stepForward" text="Next step" icon-right="mdi-arrow-right"></ActionButton>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center" v-if="!testingConnection && !gatewayConnected">
                    <ActionButton width="220" height="50" @click="testConnection" text="Re-test connection" icon="mdi-reload"></ActionButton>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script lang="ts">
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import TextField from "@/components/TextField.vue";
import {Action, Getter, Mutation} from "vuex-class";
import {Gateway, Site} from "@/types";
import SiteService from "@/services/site.service";
import GatewayService from "@/services/gateway.service";
import EventBus from "@/services/eventbus.service";

enum GatewayWizardSteps {
  FindGateway = 1,
  GatewayFound = 2,
  GatewaySetup = 3,
  TestConnection = 4,
}

@Component({
  components: {TextField, ActionButton}
})
export default class GatewayWizard extends Vue {
  @Mutation private toggleKB: any;
  @Getter private accountId!: number;
  @Action private goHome!: any;
  @Prop() private sites!: Site[];
  @Prop() private site!: Site;
  @Prop() private gatewayStep!: number;
  private GatewayWizardSteps = GatewayWizardSteps;
  private activeStep: GatewayWizardSteps = GatewayWizardSteps.FindGateway;
  private testingConnection = false;
  private gatewayConnected = false;
  private loadingMountGateway = false;
  private gateway: Gateway = {} as Gateway;
  private selectedGateway: string | null = null;
  private localSites: Site[] = [];
  private gateways: Gateway[] = [];
  private gatewayConnectionSteps = 0;
  private instructions: Array<{[key: string]: string | number}> = [
    { step: 0, text:  'Make sure the antennas are attached to the gateway as shown in the in-box setup instructions.', img: require('@/assets/setup/gateway/antennas.png') },
    { step: 1, text:  'Plug the ethernet cable into the port on the front of the gateway.  Connect the other end of the ethernet cable to your Internet Router.', auxText: ' If your Internet Router is managed by an IT department, please check that the gateway is able to connect to the Internet.', img: require('@/assets/setup/gateway/ethernet.png')  },
    { step: 2, text:  'Attach the power supply to the power port on the front of the gateway.', img: require('@/assets/setup/gateway/power.png')  },
    { step: 3, text:  'Check that the LEDs light up.', img: require('@/assets/setup/gateway/lights.png')   },
  ];
  private imageLoaded: {[key:string]: boolean} = {};
  private zoomImg: {qr: boolean, eui: boolean} = {
    qr: true,
    eui: true,
  };
  private euiDialog = false;
  private qrDialog = false;
  private skipGatewayDialog = false;

  @Watch('gatewayStep', {immediate: true})
  private onStepChange() {
    if (this.gatewayStep > 0) {
      this.activeStep = this.gatewayStep;
      this.gatewayConnected = true;
    }
  }

  private async mounted() {
    this.gateways = await GatewayService.getGatewaysForProject();

    if (this.activeStep === GatewayWizardSteps.FindGateway) {
      if (this.gateways.length === 1) {
        this.gateway = this.gateways[0];
        this.selectedGateway = this.gateways[0].eui;
        this.activeStep = GatewayWizardSteps.GatewayFound;
        return;
      }
    }
  }

  private async mountGatewayAndStepForward() {
    this.loadingMountGateway = true;
    try {
      const mountedGateway = await GatewayService.mountGateway(this.site.id, this.gateway.id)
      EventBus.$emit('showSnackbar', {text: 'Gateway mounted', icon: 'mdi-content-save'});
      this.stepForward();
    } finally {
      this.loadingMountGateway = false;
    }

  }

  private async testConnection() {
    this.testingConnection = true;
    try {
      const response = await GatewayService.getGatewayStatus(this.gateway.id);
      if (response.status === 'connected') {
        this.gatewayConnected = true;
      }
    } catch (err) {
      this.gatewayConnected = false;
    } finally {
      this.testingConnection = false;
    }
  }

  private stepForward() {
    if (this.activeStep === GatewayWizardSteps.TestConnection && this.gatewayConnected) {
      this.$emit('complete:gateway-wizard', this.gateway)
    }
    this.activeStep++;

    if (this.activeStep === GatewayWizardSteps.TestConnection) {
      this.testConnection();
    }
  }

  private stepBack() {
    if (this.activeStep === GatewayWizardSteps.FindGateway) {
      this.$emit('return-to:site')
      return;
    }

    this.activeStep--;
  }

  private selectGateway(gateway: Gateway) {
    this.gateway = gateway;
    this.selectedGateway = gateway.eui;
  }


  private get gatewayImg() {
    return require('@/assets/laird_gateway.png')
  }


  private get gatewayDevEui(): string {
    return require('@/assets/setup/gateway/dev_eui.png')
  }

  private get gatewayQrCode(): string {
    return require('@/assets/setup/gateway/qr_code.png')
  }

  private get pageHeight(): string {
    return this.$vuetify.breakpoint.mdAndUp ? 'outer-md' : 'outer-sm';
  }
}
</script>

<style>
.border-alert.v-alert--outlined {
  border: none !important;
}
.centerpiece {
  height: 220px;
  width: 220px;
}
.add {
  margin-top: 110px;
  margin-left: 110px;
}
.center-dialog { position: relative; left: 128px; }
</style>