<template>
  <v-card color="tertiary">
    <v-row align="center" class="px-4 pt-2" no-gutters>
      <v-col cols="auto">
        <v-checkbox hide-details class="mt-0" v-model="selectAll" @change="selectAllLocations" :disabled="selectAllDisabled[site.id]"/>
      </v-col>
        <v-col cols="auto">
        <div class="d-flex align-center">
          <div class="text-h5 font-weight-bold" :class="{'text--disabled': selectAllDisabled[site.id]}">{{ site.name }}</div>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-0 ml-5 mb-2 pb-2" no-gutters>
      <v-col cols="12">
        <v-treeview
            class="treeview"
            item-key="id"
            selected-color="primary"
            :items="roomsWithAssetsWithMonitoringPoints"
            item-children="assets"
            open-all
            expand-icon=""
            selectable
            item-disabled="disabled"
            return-object
            dense
            :value="selected"
            @input="selected = $event;"
        >
          <template v-slot:label="{item}">
            <span v-if="(item.monitoringPoints && item.monitoringPoints.length) && item.monitoringPoints.some((mp) => !mp.device.id)" :class="{'text--disabled': !(item.monitoringPoints && item.monitoringPoints.length && item.monitoringPoints.some((mp) => !!mp.device.id))}">{{ item.name }}</span>
            <span v-else>{{ item.name }}</span>
            <span class="text--disabled" v-if="!!item.roomId && !(item.monitoringPoints && item.monitoringPoints.some((mp) => !!mp.device.id))"> (inactive)</span>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="$vuetify.breakpoint.mdAndDown">
      <v-col class="d-flex justify-center mb-2" cols="12">
        <ActionButton :disabled="(selectedLocations && !selectedLocations.length || (selectedLocations && selectedLocations.length && selectedLocations[0] && selectedLocations[0].siteId !== site.id)) && (selected && !selected.length || selected[0].siteId !== site.id)" color="secondary" text="Configure download" @click="$emit('clicked:configure', { selected: selected, site})"/>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import ActionButton from "@/components/ActionButton.vue";
import {Asset, MonitoringPoint, Room, Site} from "@/types";
import {Getter} from "vuex-class";
@Component({
  components: {ActionButton}
})
export default class RecordCard extends Vue {
  @Prop() private sites!: Site[];
  @Prop() private selectedLocations!: MonitoringPoint[];
  @Prop() private site!: Site;
  @Prop() private selectLocation!: any;
  @Prop({ default: false }) private selectAllDisabled!: boolean;
  private selected: any[] = [];
  private selectAll = false;

  @Watch('selected')
  private onSelect() {
    const selected = [];
    if (this.selected.length) {
      const monitoringPoints = this.selected.flatMap((asset: Asset) => asset.monitoringPoints);
      selected.push(...monitoringPoints)
    }
    this.$emit('select:location', {selected: selected, site: this.site})

  }

  private mounted() {
    if (this.selectedLocations && this.selectedLocations.length) {
      this.selected = this.selectedLocations;
    }
  }

  private selectAllLocations() {
    if (this.selectAll) {
      const selectedRooms = this.roomsWithAssetsWithMonitoringPoints?.flatMap((r: Room) => r.assets.flatMap((a: Asset) => a.monitoringPoints));
      if (selectedRooms) {
        const assets = this.roomsWithAssetsWithMonitoringPoints?.flatMap((r: Room) => r.assets);
        if (assets) {
          this.selected.push(...selectedRooms, ...assets);
        }
      }
    } else {
      this.selected = [];
    }
  }

  private get assetsWithMonitoringPoints() {
    return this.site.assets?.filter((a: Asset) => !!a.monitoringPoints && a.monitoringPoints.length)
  }

  private get roomsWithAssetsWithMonitoringPoints() {
    let rooms = [];
    if (this.site.rooms) {
      for (const room of this.site.rooms) {
        let assets = [];
        if (room.assets && room.assets.length) {
          for (const asset of room.assets) {
            const monitoringPoints = [];
            if (asset.monitoringPoints && asset.monitoringPoints.length) {
              for (const mp of asset.monitoringPoints) {
                if (mp.device.id || (!mp.device.id && mp.device.deviceType)) {
                  monitoringPoints.push(mp);
                }
              }
              Vue.set(asset, 'monitoringPoints', monitoringPoints);
              assets.push(asset);
            }
          }
          assets = assets.filter((a) => a.monitoringPoints.length);
          Vue.set(room, 'assets', assets)
          if (room.assets.length) {
            rooms.push(room);
          }
        }
      }
    }
    if (rooms.length) {
      return rooms;
    }
    return [];
  }
}
</script>

<style scoped>

</style>